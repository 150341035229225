import { Checkbox, ExpandableSection, Grid } from '@amzn/awsui-components-react';
import React from 'react';
import { EditEventActions, useEditEvent } from '../../../../store/edit-event.context';
import { useUser } from '../../../../store/user.context';
import { Event } from '../../../../types/Event';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { useTranslation } from 'react-i18next';

interface ParticipantRegistrationProps {
  event?: Event;
}

const ParticipantRegistration: React.FC<ParticipantRegistrationProps> = ({ event }) => {
  const { editMode, editedEvent, handleUpdateEditEvent } = useEditEvent();
  const { user } = useUser();
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {(event?.isAdmin || event?.approved) && (
        <ExpandableSection
          headerText={t(i18nKeys.events.eventDetails.headers.participantRegistration)}
          variant="container">
          {editMode && user && (
            <Checkbox
              checked={editedEvent?.closedForNewRegistrations !== undefined ? editedEvent?.closedForNewRegistrations : false}
              disabled={!editedEvent?.canEditAttribute('closedForNewRegistrations', user)}
              onChange={({ detail }) =>
                handleUpdateEditEvent(EditEventActions.CLOSED_FOR_NEW_REGISTRATIONS, detail.checked)
              }>
              <div>
                {t(i18nKeys.events.eventDetails.labels.participantRegistration.disableRegistration)}
                <div style={{ color: awsui.colorTextFormSecondary }}>
                  {t(i18nKeys.events.eventDetails.labels.participantRegistration.disableDescription)}
                </div>
              </div>
            </Checkbox>
          )}
          {!editMode && (
            <div className="section-first-row">
              <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
                <div className="secondary-text">
                  {t(i18nKeys.events.eventDetails.labels.participantRegistration.newParticipantRegistrationDisabled)}
                </div>
                <div>{event?.closedForNewRegistrations ? t(i18nKeys.general.yes) : t(i18nKeys.general.no)}</div>
              </Grid>
            </div>
          )}
        </ExpandableSection>
      )}
    </React.Fragment>
  );
};
export default ParticipantRegistration;
