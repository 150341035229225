import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams, useHistory, Link, Route, Switch, useLocation } from 'react-router-dom';
import { PageHeader } from '../common/PageHeader';
import {
  Button,
  ButtonDropdown,
  Container,
  Icon,
  Input,
  SpaceBetween,
  Textarea,
  ContentLayout,
  Header,
  Alert,
  FormField,
} from '@amzn/awsui-components-react';
import { IEventTemplate } from '@/src/types/EventTemplate';
import { useApi } from '@/src/store/api.context';
import { useFlashbars } from '@/src/store/flashbar.context';
import { Columns } from '../common/Columns';
import { Column } from '../common/Column';
import { KeyValue } from '../common/KeyValue';
import { LoadingBar } from '../common/LoadingBar';
import { eventRoute, EVENT_DETAILS_ROUTES, resolve404Path, eventsRoute } from '../../routes';
import { Event, EventStatus, getEventListStatus } from '../../types/Event';
import { i18nKeys } from '../../utils/i18n.utils';
import { useEvents } from '../../store/events.context';
import { StatusBadge } from '../common/StatusBadge';
import { DateTime } from '../common/DateTime';
import EventDetailsSummary from './eventDetailsSections/EventDetailsSummary';
import { ConfirmModal } from '../common/ConfirmModal';
import { EditEventActions, useEditEvent } from '../../store/edit-event.context';
import { ChangeRequestPendingStatus, RequestUnapprovedStatus } from '../../types/common';
import { useChallenges } from '../../store/challenge.context';
import { useUser } from '../../store/user.context';
import ChallengeTabs from '../common/Challenges/ChallengeTabs';
import { ChallengeDescriptor, ChallengeWrapper } from '../../types/Challenge';
import { useToolPanel } from '../../store/tool-panel.context';
import Revisions from '../common/Revisions/Revisions';
import { Participants } from '../common/Participants/Participants';
import OwnerAndPermissions from '@/src/components/events/OwnerAndPermission';
import Report from './eventDetailsSections/Report/Report';
import Feedback from './eventDetailsSections/Feedback/Feedback';
import { CloneEvent, generateTemplateUrl } from '../../utils/url.utils';
import { getEmptyEventPermissions } from '../../utils/event-campaign-common.utils';
import _, { debounce } from 'lodash';
import * as common from '../../types/common';
import { localLogger, preProdLogger } from '../../utils/log.utils';
import { Badge } from '@amzn/awsui-components-react';
import '../../styles/tags.scss';
import TargetTimeline from '../common/CampaignEventComponents/TargetTimeline';
import SwitchToLegacyComponent from '@/src/components/switch-to-legacy-component/SwitchToLegacyComponent';
import { TRANSACTION_HISTORY_URL } from '@/src/constants/common';
import { customEventTrigger } from '../analytics/createEventTrigger';
import { EventPrivacyType } from '@/src/types/EventPrivacyType';
import { ChallengeTabIds, DateTimeKeys, TimezoneFormat } from '../common/CommonModel';
import { ActionButtonNames, SAVE_ACTIONS } from './EventModel';
import { EventFields, eventCancelReasonValidator } from '@/src/utils/event.validation.utils';
import { Validator } from '@/src/utils/validation.utils';
import EventComments from './eventDetailsSections/EventComments/EventComments';
import EventDetailsAdvancedSettings from './eventDetailsSections/EventDetailsAdvancedSettings';
import { config } from '@/src/config/app-config';
import { useEventTemplateOffers } from '@/src/store/event-template-offers.context';
import EventLabs from './eventLabsSections/EventLabs';
import { ParticipantsCode } from '../common/Participants/ParticipantsCode';
import ChangeRequestSummary from '../common/ChangeRequestSummary';
import { switchToLegacyConsole } from "@/src/utils/hybrid-console";

const EventDetails: React.FC = () => {
  const { t } = useTranslation();
  const { eventName }: { eventName: string } = useParams();
  const history = useHistory();
  const location = useLocation();
  const {
    event,
    getEventByName,
    unlockChallengesForEvent,
    lockChallengesForEvent,
    cancelEvent,
    deleteEvent,
    loadConfig,
    eventConfig,
    approveJamEventRequest,
    denyJamEventRequest,
    cancelJamEventRequest,
    approveJamEventChangeRequest,
    denyJamEventChangeRequest,
    cancelJamEventChangeRequest,
    resetEvent,
    resetEventTeams,
    cleanUpEvent,
    updateEventComment,
    addEventComment,
  } = useEvents();
  const { getChallengeConfiguration } = useChallenges();
  const { addErrorFlashbar } = useFlashbars();
  const [confirmCancelVisible, setConfirmCancelVisible] = useState(false);
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [confirmApproveEventRequestVisible, setConfirmApproveEventRequestVisible] = useState(false);
  const [confirmDenyEventRequestVisible, setConfirmDenyEventRequestVisible] = useState(false);
  const [confirmCancelEventRequestVisible, setConfirmCancelEventRequestVisible] = useState(false);
  const [confirmCancelChangeRequestVisible, setConfirmCancelChangeRequestVisible] = useState(false);
  const [confirmApproveChangeRequestVisible, setConfirmApproveChangeRequestVisible] = useState(false);
  const [confirmDenyChangeRequestVisible, setConfirmDenyChangeRequestVisible] = useState(false);
  const [confirmResetEventVisible, setConfirmResetEventVisible] = useState(false);
  const [confirmResetTeamsVisible, setConfirmResetTeamsVisible] = useState(false);
  const [userEmptyPermissionsVisible, setUserEmptyPermissionsVisible] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [cancelReasonError, setCancelReasonError] = useState('');
  const [typeOfSave, setTypeOfSave] = useState<common.Nullable<SAVE_ACTIONS>>(null);
  const {
    editMode,
    toggleEditMode,
    initializeEditEvent,
    editedEvent,
    bulkHandleUpdateEditEvent,
    hasChanges,
    saveUpdatedEvent,
    setEditMode,
  } = useEditEvent();
  const { wrapperMapInitalized, challengeWrapperMap } = useChallenges();
  // const { usagePlans, loadPlans } = usePlans();
  const { user } = useUser();
  const { toggleChallengeInfo } = useToolPanel();
  const { eventTemplateApi } = useApi();
  const [actionDropDownItems, setActionDropDownItems] = useState<
    { text: string; id: string; disabled: boolean; href?: string; external?: boolean }[]
  >([]);
  const [approveEventRequestID, setApproveEventRequestId] = useState('');
  const [eventRequestComment, setEventRequestComment] = useState('');
  const [eventRequestCommentError, setEventRequestCommentError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [eventTemplateDetails, setEventTemplateDetails] = useState<IEventTemplate>();
  const [initialOpenedTab, setInitialOpenedTab] = useState<ChallengeTabIds>();
  const [participantRoute, setParticipantRoute] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [ownerAndPermissionRoute, setOwnerAndPermissionRoute] = useState(false);
  const [redoChallenges, setRedoChallenges] = useState<ChallengeDescriptor[]>([]);
  const { challengeWrappers, getChallenges } = useChallenges();
  const { fetchEventTemplateOffers } = useEventTemplateOffers();
  const { pathname } = location;

  // events will be undefined if not yet initialized from an api response, forces reload of event if new event in context
  const loading = !event || event?.name !== eventName || isCancelling;

  const showEditButton = useMemo(() => {
    if (!event) return false;
    if (event.isCampaignEvent) return false;

    const isParticipant = EVENT_DETAILS_ROUTES.Participants.replaceTokens([event.name]) === pathname;
    const isFeedback = EVENT_DETAILS_ROUTES.Feedback.replaceTokens([event.name]) === pathname;
    const isReport = EVENT_DETAILS_ROUTES.Report.replaceTokens([event.name]) === pathname;
    const isRevision = EVENT_DETAILS_ROUTES.Revisions.replaceTokens([event.name]) === pathname;
    const isLabs = EVENT_DETAILS_ROUTES.Labs.replaceTokens([event.name]) === pathname;

    if (
      event?.status === EventStatus.CANCELLED ||
      event.isAfterEnd ||
      editMode ||
      !user ||
      !(user.isEventAdmin || event?.isEventOwner(user)) ||
      isFeedback ||
      isReport ||
      isRevision ||
      isParticipant ||
      isLabs
    ) {
      return false;
    }
    return true;
  }, [pathname, event, editMode, user]);

  const showTimeline = useMemo(() => {
    if (!event) return true;
    const isAdvancedSettings = EVENT_DETAILS_ROUTES.Settings.replaceTokens([event.name]) === pathname;
    if (isAdvancedSettings) {
      return false;
    }
    return true;
  }, [event, pathname]);

  /**
   * TODO: once we get the eventTemplateId param under event object from API
   * we can pass it to the below function
   * https://sim.amazon.com/issues/JAM-8885
   */
  const fetchEventTemplateDetails = async () => {
    let eventTemplate: IEventTemplate | undefined;
    if (event?.catalogId) {
      eventTemplate = await eventTemplateApi.getPublicEventTemplateById(event.catalogId);
      if (eventTemplate) {
        void fetchEventTemplateOffers();
        setEventTemplateDetails(eventTemplate);
      }
    }
  };

  useEffect(() => {
    if (loading) {
      getEventByName(eventName);
      loadConfig();
      getChallengeConfiguration();
    }
  }, []);

  useEffect(() => {
    if (event?.challengeDescriptors) {
      handleGenerateWarnings(event, event?.challengeDescriptors);
    }
  }, [wrapperMapInitalized]);

  useEffect(() => {
    if (!challengeWrappers) {
      void getChallenges(false, false, true);
    }
  }, [challengeWrappers]);

  /**
   * Cleans up event context on component dismount
   */
  useEffect(() => {
    return () => {
      /**
       * if you are in edit mode and switch
       * to another page/route edit mode will
       * stay true in context. Reset here.
       */
      setEditMode(false);

      cleanUpEvent();
    };
  }, []);

  /**
   * fetch eventTemplate details once event is loaded
   * Generate event permission based actions once event has loaded
   */
  useEffect(() => {
    if (event) {
      fetchEventTemplateDetails().catch((e: any) => {
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return */
        addErrorFlashbar(e.toString() as string);
      });
      generateDropdownItems();
    }
  }, [event]);

  useEffect(() => {
    if (!event?.name) {
      return;
    }
    const isParticipantRoute = EVENT_DETAILS_ROUTES.Participants.replaceTokens([event.name]) === location.pathname;
    const isOwnerAndPermissionRoute =
      EVENT_DETAILS_ROUTES.OwnerAndPermission.replaceTokens([event.name]) === location.pathname;
    setParticipantRoute(isParticipantRoute);
    setOwnerAndPermissionRoute(isOwnerAndPermissionRoute);
  }, [event, location]);

  // handleChallengeAction need editedEvent, which
  // if not initialized won't run the function
  // hance we update the redoChallenges state
  // to re-execute the same function again
  useEffect(() => {
    if (redoChallenges.length) {
      handleChallengeAction(redoChallenges);
      setRedoChallenges([]);
    }
  }, [redoChallenges]);

  const handleChallengeAction = (payload: ChallengeDescriptor[], toggleEdit = false) => {
    if (toggleEdit && !editMode) {
      handleToggleEdit();
      setRedoChallenges(payload);
    }
    if (editedEvent && editMode) {
      const actions = [];
      actions.push({
        action: EditEventActions.PER_CHALLENGE_BACKUP_CHALLENGES,
        payload: editedEvent.backupChallengeConfig?.perChallengeBackups,
      });
      actions.push({ action: EditEventActions.CHALLENGE_DESCRIPTORS, payload });
      bulkHandleUpdateEditEvent(actions);
      handleGenerateWarnings(editedEvent, payload);
    }
  };

  const handleEventTemplateChangeAction = (payload: IEventTemplate) => {
    if (editMode && editedEvent) {
      const actions = [];

      actions.push({
        action: EditEventActions.CHANGE_CATALOG,
        payload: payload.id,
      });
      localLogger({ actions });
      bulkHandleUpdateEditEvent(actions);
    }
  };

  const onChooseDifferentEvent = () => {
    setInitialOpenedTab(ChallengeTabIds.CHOOSE_DIFFERENT_EVENT);
    handleToggleEdit();
    setTimeout(setInitialOpenedTab, 1000, undefined);
  };

  const handleGenerateWarnings = (eventToScan: Event, challengeDescriptors: ChallengeDescriptor[]) => {
    const selectedChallengeWrapperMap: { [key: string]: ChallengeWrapper } = {};
    challengeDescriptors.forEach((challengeDescriptor: ChallengeDescriptor) => {
      if (challengeDescriptor.challengeId) {
        selectedChallengeWrapperMap[challengeDescriptor.challengeId] =
          challengeWrapperMap[challengeDescriptor.challengeId];
      }
    });
    eventToScan?.generateWarnings(selectedChallengeWrapperMap);
  };

  if (loading) {
    return <LoadingBar />;
  }

  if (history && event == null) {
    history.push(resolve404Path(eventRoute.resolvePath(eventName)));
    return null;
  }
  const eventComments = event.comments ? event.comments : [];

  const renderChildView = () => {
    return (
      <Switch>
        {/* These route is not developed in v2 redirecting to v1 */}
        <Route path={EVENT_DETAILS_ROUTES.Comments.wildcard()}>
          {config.enableV2 ? (
              // if the event is private invite keep in participant route
              <EventComments
                  comments={eventComments}
                  addNewComment={addEventComment}
                  updateComment={updateEventComment}
              />
          ) : (
              // else redirect to v1 participant route
              SwitchToLegacyComponent
          )}
        </Route>
        <Route path={EVENT_DETAILS_ROUTES.Labs.wildcard()}>
          {config.enableV2 ? (
            // If react for event pages enabled then keep labs page on v2
            <EventLabs event={event} />
          ) : (
            // Else redirect to v1 labs route
            SwitchToLegacyComponent
          )}
        </Route>
        <Route path={EVENT_DETAILS_ROUTES.Settings.wildcard()}>
          {config.enableV2 ? (
              // if the event is private invite keep in participant route
              <EventDetailsAdvancedSettings event={editedEvent || event} />
          ) : (
              // else redirect to v1 participant route
              SwitchToLegacyComponent
          )}
        </Route>
        <Route path={EVENT_DETAILS_ROUTES.Participants.wildcard()}>
          {event?.eventPrivacyType === EventPrivacyType.PRIVATE_INVITE ? (
            // if the event is private invite keep in participant route
            <Participants
              target={editedEvent || event}
              handleCancelOrDeleteEvent={handleCancelOrDeleteEvent}
              eventId={eventName}
            />
          ) : (
            <ParticipantsCode target={editedEvent || event} />
          )}
        </Route>
        <Route path={EVENT_DETAILS_ROUTES.ParticipantsV1.wildcard()}>{SwitchToLegacyComponent}</Route>
        <Route path={EVENT_DETAILS_ROUTES.OwnerAndPermission.wildcard()}>
          <OwnerAndPermissions target={editedEvent || event} />
        </Route>
        <Route path={EVENT_DETAILS_ROUTES.Feedback.wildcard()}>
          <Feedback event={editedEvent || event} />
        </Route>
        <Route path={EVENT_DETAILS_ROUTES.Challenges.wildcard()}>
          <ChallengeTabs
            target={editedEvent || event}
            challengeDescriptors={editedEvent?.challengeDescriptors || event?.challengeDescriptors}
            action={EditEventActions.CHALLENGE_DESCRIPTORS}
            handleChallengeAction={handleChallengeAction}
            handleEventTemplateChangeAction={handleEventTemplateChangeAction}
            toggleChallengeInfo={toggleChallengeInfo}
            eventTemplate={eventTemplateDetails}
            onChooseDifferentEvent={onChooseDifferentEvent}
            tabToSwitch={initialOpenedTab}
          />
        </Route>
        <Route path={EVENT_DETAILS_ROUTES.Revisions.wildcard()}>
          <Revisions
            eventPermissions={event.eventPermissions}
            changes={event.changeHistory}
            challengeDescriptors={event.challengeDescriptors}
          />
        </Route>
        <Route path={EVENT_DETAILS_ROUTES.Report.wildcard()}>
          <Report event={event} />
        </Route>
        {/* TODO: Remove once all pages have been implemented */}
        <Route path={'/my-events/*/*'}>
          <p>Not implemented</p>
        </Route>
        {/* Summary must be last */}
        <Route path={EVENT_DETAILS_ROUTES.Summary.wildcard()}>
          <EventDetailsSummary
            event={editedEvent || event}
            handleCancelOrDeleteEvent={handleCancelOrDeleteEvent}
            eventTemplate={eventTemplateDetails}
          />
        </Route>
      </Switch>
    );
  };

  let isCurrentSectionValid: () => Promise<boolean>;
  const validationHandler = (validateSection: () => Promise<boolean>) => {
    isCurrentSectionValid = validateSection;
  };

  const eventCancelReasonValidation: Validator<EventFields> = eventCancelReasonValidator(
    cancelReason,
    t(i18nKeys.events.eventDetails.messages.errors.validReason),
    new Map<EventFields, (error: string) => void>([
      [EventFields.REASON, (error: string) => setCancelReasonError(error)],
    ])
  );

  if (validationHandler && eventCancelReasonValidation) {
    validationHandler(() => {
      const eventCancelReasonValidationResult = eventCancelReasonValidation.isValidSection(true);
      if (eventCancelReasonValidationResult) {
        return Promise.resolve(eventCancelReasonValidationResult);
      } else {
        return Promise.reject(eventCancelReasonValidationResult);
      }
    });
  }

  const handleCancelEvent = async () => {
    try {
      const isValid = await isCurrentSectionValid();
      if (isValid) {
        setIsCancelling(true);
        await cancelEvent(eventName, cancelReason);
        setConfirmCancelVisible(false);
      }
    } catch (e) {
      // error handled in API
    }
    setIsCancelling(false);
  };

  const debouncedCancelReasonValidate = debounce(() => {
    eventCancelReasonValidation.isValidField(EventFields.REASON as EventFields, true);
  }, 300, { leading: false });

  const handleDeleteEvent = async () => {
    setConfirmDeleteVisible(false);
    await deleteEvent(eventName);

    if (history) {
      history.push(eventsRoute.path);
    }
  };

  const handleToggleEdit = () => {
    if (event) {
      initializeEditEvent(event);
    }
    toggleEditMode();
  };

  const generateCloneEvent = () => {
    const eventDetailsToClone: CloneEvent = {
      id: event.id || null,
      title: event.title || null,
      notes: event.notes || null,
      agreementId: null,
      audienceType: event?.audienceType ?? null,
      tags: event.tags.join(',') || null,
      owners: event.owners.join(',') || null,
      facilitators: event.getFacilitators().join(',') || null,
      skillbuilderSubs: event.validSkillBuilderSubscriptions || null,
      catalogId: event.catalogId || null,
      challenges:
        event.challengeDescriptors
          .map((challengeDescriptor: ChallengeDescriptor) => challengeDescriptor.challengeId)
          .join(',') || null,
      startDate: event.startDate,
      eventPrivacyType: event.eventPrivacyType,
      autoUnlockChallengesOffsetMinutes: event.autoUnlockChallengesOffsetMinutes,
    };
    return generateTemplateUrl(eventDetailsToClone, false);
  };

  const cloneEvent = () => {
    // when cloning an event, have the page update the query params and load the cloned event
    const cloneEventurl = generateCloneEvent() || '/events/new';
    history.replace(cloneEventurl);
  };

  const alertEmptyEventPermissions = () => {
    if (editedEvent) {
      const userNoPermissions = getEmptyEventPermissions(editedEvent);
      if (userNoPermissions.length > 0) {
        setUserEmptyPermissionsVisible(true);
        return;
      }
    }
    return true;
  };

  const saveEvent = (saveType: SAVE_ACTIONS, byPassAlert = false) => {
    if (editMode && user && eventConfig && hasChanges(user, eventConfig, event)) {
      setTypeOfSave(saveType);
      if (!byPassAlert) {
        const proceed = alertEmptyEventPermissions();
        if (!proceed) {
          return;
        }
      }
      setTypeOfSave(null);
      setUserEmptyPermissionsVisible(false);
      setIsLoading(true);
      saveUpdatedEvent(saveType)
        .then(() => {
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          preProdLogger('Error saving event', err.message);
        });
    }
  };

  const getPendingEventChanges = () => {
    return user && eventConfig ? event.getPendingChanges(user, eventConfig) : [];
  };

  const shouldShowButton = (buttonName: string) => {
    if (!event) {
      return false;
    }
    if (event.isCampaignEvent) {
      return false;
    }
    switch (buttonName) {
      case ActionButtonNames.CANCEL_CHANGE_REQUEST:
        // Can cancel a change request as long as the request is still in progress.
        return !user?.isEventAdmin && event.approved && event.pendingChangeRequest;

      case ActionButtonNames.CANCEL_EVENT:
        return (
          (!!user &&
            event.isEventOwner(user) &&
            !user.isEventAdmin &&
            event.approved &&
            !event.pendingChangeRequest &&
            !event.ended &&
            !event.isClone &&
            !event.oneClickTestEvent) ||
          (user?.isEventAdmin && !event.isClone && event.approved)
        );

      case ActionButtonNames.APPROVE_CHANGE_REQUEST:
        // Can approve a change request as long as it is not yet resolved.
        return user?.isEventAdmin && event.approved && event.changeRequest != null && !event.changeRequest.resolved;

      case ActionButtonNames.DENY_CHANGE_REQUEST:
        // Can deny a change request as long as it is not yet resolved.
        return user?.isEventAdmin && event.approved && event.changeRequest != null && !event.changeRequest.resolved;

      case ActionButtonNames.CANCEL_EVENT_REQUEST:
        // Can cancel an event request as long as it is not yet resolved.
        // EventSupport should not have this option as they approve/deny the requests.
        return !user?.isEventAdmin && !event.requestResolved;

      case ActionButtonNames.APPROVE_EVENT_REQUEST:
        // Can approve an event request as long as it is not yet resolved.
        return user?.isEventAdmin && !event.requestResolved;

      case ActionButtonNames.DENY_EVENT_REQUEST:
        // Can deny an event request as long as it is not yet resolved.
        return user?.isEventAdmin && !event.requestResolved;

      case ActionButtonNames.UNLOCK_CHALLENGES:
        return (
          !event.isCampaignEvent &&
          event.approved &&
          event.status === EventStatus.NOT_STARTED
        );

      case ActionButtonNames.LOCK_CHALLENGES:
        return (
          !event.isCampaignEvent &&
          event.approved &&
          event.status === EventStatus.ONGOING
        );

      case ActionButtonNames.CLONE_EVENT:
        return (
          !!user &&
          event.isEventOwner(user) &&
          event.approved &&
          !event.pendingChangeRequest &&
          !event.oneClickTestEvent &&
          !event.isClone
        );

      case ActionButtonNames.RESET_EVENT:
        return eventConfig?.allowEventReset && user?.isEventAdmin;

      case ActionButtonNames.RESET_EVENT_TEAMS:
        return eventConfig?.allowEventTeamsReset && user?.isEventAdmin;

      case ActionButtonNames.DELETE_EVENT:
        return user && user.isEventAdmin && !event?.isClone;

      case ActionButtonNames.VIEW_INVOICE:
        return event.orderId;

      default:
        return false;
    }
  };

  const handleActionClick = (clickEvent: any) => {
    switch (clickEvent.detail.id) {
      case ActionButtonNames.UNLOCK_CHALLENGES:
        void unlockChallengesForEvent(eventName);
        break;
      case ActionButtonNames.LOCK_CHALLENGES:
        void lockChallengesForEvent(eventName);
        break;
      case ActionButtonNames.CLONE_EVENT:
        cloneEvent();
        break;
      case ActionButtonNames.CANCEL_EVENT:
        handleCancelOrDeleteEvent();
        break;
      case ActionButtonNames.DELETE_EVENT:
        setConfirmDeleteVisible(true);
        break;
      case ActionButtonNames.RESET_EVENT:
        setConfirmResetEventVisible(true);
        break;
      case ActionButtonNames.RESET_EVENT_TEAMS:
        setConfirmResetTeamsVisible(true);
        break;
      case ActionButtonNames.VIEW_INVOICE:
        customEventTrigger('click', 'View Invoice', `${window.location.href}`, 'Actions - View Invoice', {});
        break;
      case ActionButtonNames.RESEND_EMAIL_INVITE:
        break;
      default:
        break;
    }
    return;
  };

  const handleCancelOrDeleteEvent = () => {
    setConfirmCancelVisible(true);
  };

  const generateDropdownItems = () => {
    const newDropdownItems: {
      text: string;
      id: string;
      disabled: boolean;
      href?: string;
      external?: boolean;
    }[] = [];
    if (shouldShowButton(ActionButtonNames.LOCK_CHALLENGES)) {
      newDropdownItems.push({
        text: t(i18nKeys.events.eventDetails.buttons.lockChallenges),
        id: ActionButtonNames.LOCK_CHALLENGES,
        disabled: event.readonly,
      });
    }
    if (shouldShowButton(ActionButtonNames.UNLOCK_CHALLENGES)) {
      newDropdownItems.push({
        text: t(i18nKeys.events.eventDetails.buttons.unlockChallenges),
        id: ActionButtonNames.UNLOCK_CHALLENGES,
        disabled: event.readonly,
      });
    }
    if (shouldShowButton(ActionButtonNames.CLONE_EVENT)) {
      newDropdownItems.push({
        text: t(i18nKeys.events.eventDetails.buttons.cloneEvent),
        id: ActionButtonNames.CLONE_EVENT,
        disabled: event.readonly,
      });
    }
    if (shouldShowButton(ActionButtonNames.VIEW_INVOICE)) {
      newDropdownItems.push({
        text: t(i18nKeys.events.eventDetails.buttons.viewInvoice),
        id: ActionButtonNames.VIEW_INVOICE,
        disabled: event.readonly,
        href: TRANSACTION_HISTORY_URL,
        external: true,
      });
    }
    if (shouldShowButton(ActionButtonNames.RESEND_EMAIL_INVITE)) {
      newDropdownItems.push({
        text: t(i18nKeys.events.eventDetails.buttons.resendEmailInvite),
        id: ActionButtonNames.RESEND_EMAIL_INVITE,
        disabled: event.readonly,
      });
    }
    if (shouldShowButton(ActionButtonNames.CANCEL_EVENT)) {
      newDropdownItems.push({
        text: t(i18nKeys.events.eventDetails.buttons.cancelEvent),
        id: ActionButtonNames.CANCEL_EVENT,
        disabled: event.readonly,
      });
    }
    if (shouldShowButton(ActionButtonNames.DELETE_EVENT)) {
      newDropdownItems.push({
        text: t(i18nKeys.events.eventDetails.buttons.deleteEvent),
        id: ActionButtonNames.DELETE_EVENT,
        disabled: event.readonly,
      });
    }
    if (shouldShowButton(ActionButtonNames.RESET_EVENT)) {
      newDropdownItems.push({
        text: t(i18nKeys.events.eventDetails.buttons.resetEvent),
        id: ActionButtonNames.RESET_EVENT,
        disabled: event.readonly,
      });
    }
    if (shouldShowButton(ActionButtonNames.RESET_EVENT_TEAMS)) {
      newDropdownItems.push({
        text: t(i18nKeys.events.eventDetails.buttons.resetEventTeams),
        id: ActionButtonNames.RESET_EVENT_TEAMS,
        disabled: event.readonly,
      });
    }
    setActionDropDownItems(newDropdownItems);
  };

  /**
   * Format to slug and approve event
   */
  const handleApproveEventRequestClick = () => {
    setApproveEventRequestId(event.title?.replaceAll(' ', '-').toLowerCase() || '');
    setConfirmApproveEventRequestVisible(true);
  };

  const approveEventRequest = () => {
    if (approveEventRequestID) {
      void approveJamEventRequest(event.name, approveEventRequestID, eventRequestComment);
      cleanUpActionModals();
    }
  };

  const denyEventRequest = () => {
    void denyJamEventRequest(event?.name, eventRequestComment);
    cleanUpActionModals();
  };

  const cancelEventRequest = () => {
    customEventTrigger('click', 'Cancel Event', window.location.href, 'Actions - Cancel Event', {});
    if (eventRequestComment === 'confirm') {
      void cancelJamEventRequest(event?.name, event?.title ?? '', eventRequestComment);
      cleanUpActionModals();
    } else {
      setEventRequestCommentError(t(i18nKeys.events.eventDetails.labels.eventActions.enterConfirm));
    }
  };

  const approveChangeRequest = () => {
    void approveJamEventChangeRequest(event?.name, eventRequestComment);
    cleanUpActionModals();
  };

  const denyChangeRequest = () => {
    void denyJamEventChangeRequest(event?.name, eventRequestComment);
    cleanUpActionModals();
  };

  const cancelChangeRequest = () => {
    void cancelJamEventChangeRequest(event?.name, eventRequestComment);
    cleanUpActionModals();
  };

  const handleResetEvent = async () => {
    customEventTrigger('click', 'Reset Event', window.location.href, 'Reset Event', {});
    await resetEvent(event?.name);
    cleanUpActionModals();
  };

  const handleResetEventTeams = async () => {
    customEventTrigger('click', 'Reset Event Teams', window.location.href, 'Reset Event Teams', {});
    await resetEventTeams(event?.name);
    cleanUpActionModals();
  };

  const cleanUpActionModals = () => {
    customEventTrigger('click', 'Cancel Modal', window.location.href, 'Cancel Modal', {});
    setApproveEventRequestId('');
    setEventRequestComment('');
    setConfirmDenyEventRequestVisible(false);
    setConfirmApproveEventRequestVisible(false);
    setConfirmCancelEventRequestVisible(false);
    setConfirmCancelChangeRequestVisible(false);
    setConfirmDenyChangeRequestVisible(false);
    setConfirmApproveChangeRequestVisible(false);
    setConfirmResetEventVisible(false);
    setConfirmResetTeamsVisible(false);
  };

  const getSaveButton = () => {
    if (user && eventConfig) {
      switch (getEventListStatus(event)) {
        case ChangeRequestPendingStatus.CHANGE_PENDING:
          return (
            <Button
              className="mr-5"
              variant="primary"
              loading={isLoading}
              disabled={!hasChanges(user, eventConfig, event)}
              onClick={() => saveEvent(SAVE_ACTIONS.UPDATE_CHANGE_REQUEST)}>
              {t(i18nKeys.events.eventDetails.buttons.updateChangeRequest)}
            </Button>
          );
        case RequestUnapprovedStatus.REQUEST_PENDING:
          return (
            <Button
              className="mr-5"
              variant="primary"
              loading={isLoading}
              disabled={!hasChanges(user, eventConfig, event)}
              onClick={() => saveEvent(SAVE_ACTIONS.UPDATE_EVENT_REQUEST)}>
              {!user.isOnlyBasicUser
                ? t(i18nKeys.events.eventDetails.buttons.updateEventRequest)
                : t(i18nKeys.general.save)}
            </Button>
          );
        default:
          return (
            <Button
              className="mr-5"
              variant="primary"
              loading={isLoading}
              disabled={!hasChanges(user, eventConfig, event)}
              onClick={() => saveEvent(SAVE_ACTIONS.SUBMIT_CHANGE_REQUEST)}>
              {!ownerAndPermissionRoute
                ? t(i18nKeys.events.eventDetails.buttons.createChangeRequest)
                : t(i18nKeys.general.save)}
            </Button>
          );
      }
    }
  };

  return (
    <ContentLayout
      header={
        <PageHeader
          title={event?.title}
          buttons={[
            <SpaceBetween direction="horizontal" size="xxs" key="event-details-button">
              {event.catalogId &&
                !editMode &&
                !event.ended &&
                pathname === EVENT_DETAILS_ROUTES.Summary.resolve(eventName) && (
                  <Button
                    className="mr-5"
                    variant="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(EVENT_DETAILS_ROUTES.Participants.resolve(eventName));
                    }}>
                    {t(i18nKeys.events.eventDetails.buttons.inviteParticipants)}
                  </Button>
                )}
              {event?.isCampaignEvent && !editMode && !participantRoute && (
                <Button
                  className="mr-5"
                  onClick={() => switchToLegacyConsole(`/campaigns/${event?.campaignId}`)}>
                  {t(i18nKeys.events.eventDetails.buttons.goToParentCampaign)}
                </Button>
              )}
              {!user?.hasBasicAccess && event?.testCloneEventName && !event?.isClone && !editMode && (
                <Button className="mr-5" href={`/events/${event?.testCloneEventName}`}>
                  {t(i18nKeys.events.eventDetails.buttons.goToTestEvent)}
                </Button>
              )}
              {!editMode && shouldShowButton(ActionButtonNames.DENY_CHANGE_REQUEST) && (
                <Button
                  className="mr-5"
                  onClick={() => {
                    customEventTrigger('click', 'Deny Change Request', window.location.href, 'Deny Change Request', {});
                    setConfirmDenyChangeRequestVisible(true);
                  }}
                  disabled={event.readonly}>
                  {t(i18nKeys.events.eventDetails.buttons.denyChangeRequest)}
                </Button>
              )}
              {!editMode && shouldShowButton(ActionButtonNames.APPROVE_CHANGE_REQUEST) && (
                <Button
                  className="mr-5"
                  onClick={() => {
                    customEventTrigger(
                      'click',
                      'Approve Change Request',
                      window.location.href,
                      'Approve Change Request',
                      {}
                    );
                    setConfirmApproveChangeRequestVisible(true);
                  }}
                  disabled={event.readonly}>
                  {t(i18nKeys.events.eventDetails.buttons.approveChangeRequest)}
                </Button>
              )}
              {!editMode && shouldShowButton(ActionButtonNames.DENY_EVENT_REQUEST) && !participantRoute && (
                <Button
                  className="mr-5"
                  onClick={() => {
                    customEventTrigger('click', 'Deny Event Request', window.location.href, 'Deny Event Request', {});
                    setConfirmDenyEventRequestVisible(true);
                  }}
                  disabled={event.readonly}>
                  {t(i18nKeys.events.eventDetails.buttons.denyEventRequest)}
                </Button>
              )}
              {!editMode && shouldShowButton(ActionButtonNames.APPROVE_EVENT_REQUEST) && !participantRoute && (
                <Button
                  className="mr-5"
                  onClick={() => {
                    customEventTrigger(
                      'click',
                      'Approve Event Request',
                      window.location.href,
                      'Approve Event Request',
                      {}
                    );
                    handleApproveEventRequestClick();
                  }}
                  disabled={event.readonly}>
                  {t(i18nKeys.events.eventDetails.buttons.approveEventRequest)}
                </Button>
              )}
               {!editMode && shouldShowButton(ActionButtonNames.CANCEL_CHANGE_REQUEST) && !participantRoute && (
                <Button
                  className="mr-5"
                  data-testid="event-details__cancel-change-request"
                  onClick={() => {
                    customEventTrigger(
                      'click',
                      'Cancel Change Request',
                      window.location.href,
                      'Cancel Change Request',
                      {}
                    );
                    setConfirmCancelChangeRequestVisible(true);
                  }}
                  disabled={event.readonly}>
                  {t(i18nKeys.events.eventDetails.buttons.cancelChangeRequest)}
                </Button>
              )}
              {!editMode && shouldShowButton(ActionButtonNames.CANCEL_EVENT_REQUEST) && (
                <Button
                  data-testid="event-details__cancel-event-request"
                  onClick={() => {
                    customEventTrigger(
                      'click',
                      'Cancel Event Request',
                      window.location.href,
                      'Cancel Event Request',
                      {}
                    );
                    setConfirmCancelEventRequestVisible(true);
                  }}
                  disabled={event?.readonly}>
                  {t(i18nKeys.events.eventDetails.buttons.cancelEventRequest)}
                </Button>
              )}
              {editMode && user && !participantRoute && (
                <>
                  <Button className="mr-5" variant="link" onClick={() => toggleEditMode()} loading={isLoading}>
                    {t(i18nKeys.general.cancel)}
                  </Button>
                  {eventConfig && getSaveButton()}
                </>
              )}
              {(!editMode || participantRoute) && (
                <>
                  {actionDropDownItems?.length > 0 && (
                    <ButtonDropdown
                      data-testid="actions-dropdown-menu"
                      className="mr-5"
                      onItemClick={handleActionClick}
                      items={actionDropDownItems}>
                      {t(i18nKeys.events.eventDetails.buttons.actionsPlaceholder)}
                    </ButtonDropdown>
                  )}
                </>
              )}
              {showEditButton && <Button onClick={() => handleToggleEdit()}>{t(i18nKeys.general.edit)}</Button>}
            </SpaceBetween>,
          ]}
        />
      }>
      <SpaceBetween size="l">
        {!participantRoute && !ownerAndPermissionRoute && showTimeline && (
          <Container key="top">
            {!user?.hasBasicAccess ? (
              <Columns columns={4}>
                <Column size="s">
                  <KeyValue className="secondary-text" label={t(i18nKeys.events.eventDetails.labels.eventStatus)}>
                    {event && <StatusBadge status={getEventListStatus(event)} />}
                  </KeyValue>
                </Column>
                <Column size="s">
                  <KeyValue className="secondary-text" label={t(i18nKeys.events.eventDetails.labels.ends)}>
                    <DateTime startOrEnd={DateTimeKeys.END} event={event} timezoneFormat={TimezoneFormat.LOCAL} />
                  </KeyValue>
                </Column>
                <Column size="s">
                  <KeyValue className="secondary-text" label={t(i18nKeys.events.eventDetails.labels.tags)}>
                    <>
                      <div className="tag-group">
                        {event?.tags?.map((tag, key) => {
                          return (
                            <Badge color="grey" key={key}>
                              {tag}
                            </Badge>
                          );
                        })}
                      </div>
                    </>
                  </KeyValue>
                </Column>
                {event?.isCampaignEvent && (
                  <Column size="s">
                    <div style={{ marginTop: 5, width: '90%' }}>
                      <Icon name="status-info" className="aws-orange-info" />{' '}
                      {t(i18nKeys.events.eventDetails.messages.toMakeChangesToEvent)}
                      <Link to={`/campaigns/${event?.campaignId}`} className="link">
                        {t(i18nKeys.events.eventDetails.messages.parentCampaign)}
                      </Link>
                    </div>
                  </Column>
                )}
              </Columns>
            ) : (
              <div>
                <Header
                  variant="h2"
                  description={
                    user.isOnlyBasicUser ? t(i18nKeys.events.eventDetails.headers.timeline.description) : ''
                  }>
                  {t(i18nKeys.events.eventDetails.headers.timeline.title)}
                </Header>
                <TargetTimeline target={event} />
              </div>
            )}
          </Container>
        )}
        { pathname === EVENT_DETAILS_ROUTES.Summary.resolve(eventName) &&
          <ChangeRequestSummary
            key="change-request-summary"
            source={event} getPendingChanges={getPendingEventChanges} />
        }
        <div key="child">
          {renderChildView()}
        </div>
      </SpaceBetween>
      {editedEvent && typeOfSave && (
        <ConfirmModal
          visible={userEmptyPermissionsVisible}
          title={t(i18nKeys.events.eventDetails.labels.eventActions.emptyEventPermissionsDetected)}
          message={t(i18nKeys.events.eventDetails.messages.eventActions.emptyEventPermissions, {
            emails: getEmptyEventPermissions(editedEvent).join(', '),
          })}
          onCancel={() => setUserEmptyPermissionsVisible(false)}
          onConfirm={() => {
            customEventTrigger('click', 'Save Event', window.location.href, 'Save Event', {});
            saveEvent(typeOfSave, true);
          }}
        />
      )}
      <ConfirmModal
        visible={confirmCancelVisible}
        message={
          <>
            {t(i18nKeys.events.eventDetails.messages.cancelEventMessage)}
            <FormField errorText={cancelReason ? cancelReasonError : null}>
              <Input
                id="cancel-reason-textbox"
                className="mt-10"
                value={cancelReason}
                onChange={({ detail }) => {
                  setCancelReason(detail.value);
                  debouncedCancelReasonValidate();
                }}
                onBlur={() => debouncedCancelReasonValidate()}
              />
            </FormField>
          </>
        }
        cancelBtnLabel={t(i18nKeys.events.eventDetails.buttons.noGoBack)}
        confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.yesCancelEvent)}
        onConfirm={handleCancelEvent}
        onCancel={() => setConfirmCancelVisible(false)}
        disabled={!cancelReason || !!cancelReasonError?.length}
        cancelButtonId="cancel-reason-button"
        submitButtonId="submit-cancel-reason-button"
      />
      <ConfirmModal
        visible={confirmDeleteVisible}
        message={t(i18nKeys.events.eventDetails.messages.deleteEventMessage)}
        confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.yesDelete)}
        onConfirm={handleDeleteEvent}
        onCancel={() => setConfirmDeleteVisible(false)}
      />
      <ConfirmModal
        visible={confirmDenyEventRequestVisible}
        title={t(i18nKeys.events.eventDetails.labels.eventActions.denyEventRequest)}
        onCancel={() => cleanUpActionModals()}
        onConfirm={() => denyEventRequest()}
        confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.denyEventRequest)}
        message={
          <>
            <div>{t(i18nKeys.events.eventDetails.messages.eventActions.denyEventRequest)}</div>
            <Input value={eventRequestComment} onChange={({ detail }) => setEventRequestComment(detail.value)} />
          </>
        }
      />
      <ConfirmModal
        visible={confirmApproveEventRequestVisible}
        title={t(i18nKeys.events.eventDetails.labels.eventActions.approveEventRequest)}
        onCancel={() => cleanUpActionModals()}
        onConfirm={() => approveEventRequest()}
        confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.approveEventRequest)}
        disabled={_.isEmpty(approveEventRequestID)}
        message={
          <>
            <KeyValue
              required
              label={t(i18nKeys.events.eventDetails.labels.eventID)}
              description={`${t(i18nKeys.events.eventDetails.messages.eventActions.approveEventRequest)}
                  /valid-event`}>
              <Input
                value={approveEventRequestID}
                onChange={({ detail }) => setApproveEventRequestId(detail.value.replaceAll(' ', '-').toLowerCase())}
              />
            </KeyValue>
            <KeyValue
              label={t(i18nKeys.events.eventDetails.labels.optionalComment)}
              description={t(i18nKeys.events.eventDetails.messages.eventActions.approveEventRequestComment)}>
              <Textarea value={eventRequestComment} onChange={({ detail }) => setEventRequestComment(detail.value)} />
            </KeyValue>
          </>
        }
      />
      <ConfirmModal
        visible={confirmCancelEventRequestVisible}
        title={t(i18nKeys.events.eventDetails.labels.eventActions.cancelEvent)}
        onCancel={() => cleanUpActionModals()}
        onConfirm={() => cancelEventRequest()}
        confirmBtnLabel={t(i18nKeys.events.eventDetails.labels.eventActions.cancelEvent)}
        message={
          <>
            <Trans i18nKey="events.eventDetails.labels.eventActions.permanentlyDelete" values={{eventName: event?.title ?? ''}}>
              Permanently delete <b>{event?.title ?? event?.name ?? ''}</b>? You can’t undo this action.
            </Trans>
            <Alert statusIconAriaLabel="Warning" type="warning">
              {t(i18nKeys.events.eventDetails.labels.eventActions.proceedActionWarning)}
            </Alert>
            <div>{t(i18nKeys.events.eventDetails.labels.eventActions.writtenConsent)}</div>
            { /* intentionally leaving 'confirm' untranslated as the customer needs to type that string literal. */ }
            <Trans i18nKey="events.eventDetails.labels.eventActions.typeConfirm" values={{confirm: 'confirm'}}>
              To confirm this cancellation, type <b>confirm</b>.
            </Trans>
            <FormField errorText={eventRequestCommentError}>
              <Input value={eventRequestComment}
                     onChange={({ detail }) => setEventRequestComment(detail.value)}
                     data-testid="cancel-event-request-input"
              />
            </FormField>
          </>
        }
      />
      <ConfirmModal
        visible={confirmApproveChangeRequestVisible}
        title={t(i18nKeys.events.eventDetails.labels.eventActions.approveChangeRequest)}
        onCancel={() => cleanUpActionModals()}
        onConfirm={() => approveChangeRequest()}
        confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.approveChangeRequest)}
        message={
          <>
            <div>{t(i18nKeys.events.eventDetails.messages.eventActions.approveChangeRequest)}</div>
            <Input value={eventRequestComment} onChange={({ detail }) => setEventRequestComment(detail.value)} />
          </>
        }
      />
      <ConfirmModal
        visible={confirmDenyChangeRequestVisible}
        title={t(i18nKeys.events.eventDetails.labels.eventActions.denyChangeRequest)}
        onCancel={() => cleanUpActionModals()}
        onConfirm={() => denyChangeRequest()}
        confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.denyChangeRequest)}
        disabled={_.isEmpty(eventRequestComment)}
        message={
          <>
            <div>{t(i18nKeys.events.eventDetails.messages.eventActions.denyChangeRequest)}</div>
            <Input value={eventRequestComment} onChange={({ detail }) => setEventRequestComment(detail.value)} />
          </>
        }
      />
      <ConfirmModal
        visible={confirmCancelChangeRequestVisible}
        title={t(i18nKeys.events.eventDetails.labels.eventActions.cancelChangeRequest)}
        onCancel={() => cleanUpActionModals()}
        onConfirm={() => cancelChangeRequest()}
        confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.cancelChangeRequest)}
        message={
          <>
            <div>{t(i18nKeys.events.eventDetails.messages.eventActions.cancelChangeRequest)}</div>
            <Input value={eventRequestComment} onChange={({ detail }) => setEventRequestComment(detail.value)} />
          </>
        }
      />
      <ConfirmModal
        visible={confirmResetEventVisible}
        title={t(i18nKeys.events.eventDetails.labels.eventActions.resetEvent)}
        onCancel={() => cleanUpActionModals()}
        onConfirm={() => handleResetEvent()}
        confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.yesResetEvent)}
        message={t(i18nKeys.events.eventDetails.messages.eventActions.resetEvent)}
      />
      <ConfirmModal
        visible={confirmResetTeamsVisible}
        title={t(i18nKeys.events.eventDetails.labels.eventActions.resetEventTeams)}
        onCancel={() => cleanUpActionModals()}
        onConfirm={() => handleResetEventTeams()}
        confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.yesResetTeams)}
        message={t(i18nKeys.events.eventDetails.messages.eventActions.resetEventTeams)}
      />
    </ContentLayout>
  );
};

export default EventDetails;
