import { Challenge, ChallengeDifficulty } from '@/src/types/Challenge';
import {
  Box,
  Button,
  ButtonDropdown,
  ColumnLayout,
  Container,
  Header,
  Modal,
  SpaceBetween,
  Textarea,
} from '@amzn/awsui-components-react';
import React, { useMemo, useState } from 'react';
import { KeyValue } from '../../../common/KeyValue';
import { LabDashboardChartData, LabMetadata, LabStatusCounts, LabStatusSnapshot } from '@/src/types/LabModels';
import { useTranslation } from 'react-i18next';
import { getDaysHoursMinutes } from '@/src/utils/event-time.utils';
import { LAB_PROVIDER_LABELS } from '@/src/types/LabProvider';
import { CHALLENGE_DETAILS_ROUTES } from '@/src/routes';
import { useApi } from '@/src/store/api.context';
import { useUser } from '@/src/store/user.context';
import { LabShutoffCandidate, LabShutoffStatus } from '@/src/types/LabShutoff';
import ProgressMeter from '../ProgressMeter';
import LabStatus from '../LabStatus';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { localizeLabStatus } from '@/src/utils/lab-dashboard-chart.utils';
import styles from './ChallengeLab.module.scss';

interface ChallengeLabProps {
  challenge: Challenge;
  snapshot: LabStatusSnapshot;
  getUnusedImportedPropsCount: (id: string) => number;
  getUsedImportedPropsCount: (id: string) => number;
  labProvider: string;
  eventName: string;
  labShutoffStatus?: LabShutoffStatus;
  changeTab: (id: string) => void;
  metadata: LabMetadata;
  showAuditTrail: (showFor: string) => void;
  labDashboardChartData?: LabDashboardChartData;
  labInternalStatusCounts: LabStatusCounts;
  setChallengeAsFilter: () => void;
}

export class BarMeterData {
  value = 0;
  label?: string = '';
  info?: string = '';
  color = '#ccc';
  percent?: number = 0;
  widthPercent?: number = 0;
}

const ChallengeLab: React.FC<ChallengeLabProps> = ({
  challenge,
  snapshot,
  getUnusedImportedPropsCount,
  getUsedImportedPropsCount,
  labProvider,
  eventName,
  changeTab,
  labShutoffStatus,
  metadata,
  showAuditTrail,
  labDashboardChartData,
  labInternalStatusCounts,
  setChallengeAsFilter
}) => {
  const { t } = useTranslation();
  const id = challenge.id || '';
  const unusedImportedPropsCount = getUnusedImportedPropsCount(id);
  const usedImportedPropsCount = getUsedImportedPropsCount(id);
  const { eventsApi, labshutoffAPI } = useApi();
  const { user } = useUser();
  const [reason, setReason] = useState('');
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [showMetadata, setShowMetadata] = useState(false);

  const startedChallengeCount = useMemo(() => {
    return (labDashboardChartData?.challengeStartTimes[id] || []).length;
  }, [id, labDashboardChartData]);

  const solvedChallengeCount = useMemo(() => {
    return (labDashboardChartData?.challengeCompletionTimes[id] || []).length;
  }, [id, labDashboardChartData]);

  const labInternalStatusTableRows = useMemo(() => {
    return Object.keys(labInternalStatusCounts)
      .sort()
      .filter((status) => labInternalStatusCounts[status] > 0)
      .map((status) => {
        const count = labInternalStatusCounts[status];
        const label = localizeLabStatus(t, status);
        const value = `${count}`;

        return { label, value };
      });
  }, [labInternalStatusCounts]);

  const onTerminateLabs = async () => {
    if (confirm(t(i18nKeys.eventLabs.challengeLab.messages.terminateLabsSure1))) {
      if (confirm(t(i18nKeys.eventLabs.challengeLab.messages.terminateLabsSure2))) {
        await eventsApi.terminateChallengeLabs(eventName, id);
      }
    }
  };

  /**
   * Shut Off lab deployment for this challenge.
   */
  const shutoffChallengeLabs = async () => {
    const labShutoffCandidate = LabShutoffCandidate.ofEventChallengePair(eventName, id);
    await labshutoffAPI.shutoffCandidate(reason, labShutoffCandidate, false);
    // TODOSAGAR:
    // this.updateLabShutoffStatus();
  };

  /**
   * Turn on lab deployment for this challenge.
   */
  const turnOnChallengeLabs = async () => {
    const labShutoffCandidate = LabShutoffCandidate.ofEventChallengePair(eventName, id);
    await labshutoffAPI.turnOnCandidate(labShutoffCandidate, false);
    // this.updateLabShutoffStatus();
  };

  const onAction = (actionId: string) => {
    switch (actionId) {
      case 'VIEW_CHALLENGE':
        window.open(CHALLENGE_DETAILS_ROUTES.Summary.replaceTokens([id]), '_blank');
        break;
      case 'TERMINATE_LABS':
        void onTerminateLabs();
        break;
      case 'TURN_ON_DEP':
        void turnOnChallengeLabs();
        break;
      case 'SHUFOFF_LAB_DEP':
        void setShowReasonModal(true);
        break;
      case 'VIEW_LAB_ACCOUNTS':
        setChallengeAsFilter();
        changeTab('LAB_ACCOUNTS');
        break;
      case 'VIEW_METADATA':
        setShowMetadata(true);
        break;
      case 'AUDIT_TRAIL':
        showAuditTrail(id);
        break;
    }
  };

  return (
    <Container
      key={challenge.id}
      header={
        <Header
          actions={
            <ButtonDropdown
              ariaLabel="Control instance"
              variant="icon"
              items={[
                { text: t(i18nKeys.eventLabs.challengeLab.actions.viewChallenge) || '', id: 'VIEW_CHALLENGE' },
                { text: t(i18nKeys.eventLabs.challengeLab.actions.viewLabAccounts) || '', id: 'VIEW_LAB_ACCOUNTS' },
                ...(user?.isEventAdmin && metadata
                  ? [{ text: t(i18nKeys.eventLabs.challengeLab.actions.viewMetadata) || '', id: 'VIEW_METADATA' }]
                  : []),
                { text: t(i18nKeys.eventLabs.challengeLab.actions.auditTrail) || '', id: 'AUDIT_TRAIL' },
                ...(user?.isSuperAdmin
                  ? [
                      !labShutoffStatus?.selfShutoff
                        ? {
                            text: t(i18nKeys.eventLabs.challengeLab.actions.shutoffLabDep) || '',
                            id: 'SHUFOFF_LAB_DEP',
                          }
                        : { text: t(i18nKeys.eventLabs.challengeLab.actions.turnonLabDep) || '', id: 'TURN_ON_DEP' },
                    ]
                  : []),

                { text: t(i18nKeys.eventLabs.challengeLab.actions.terminateLabs) || '', id: 'TERMINATE_LABS' },
              ]}
              onItemClick={({ detail }) => onAction(detail.id)}
            />
          }
          variant="h3">
          {challenge.props.title}
        </Header>
      }>
      <SpaceBetween size="m">
        <div className={styles.labProgress} style={{ padding: '16px', borderRadius: '8px' }}>
          <ProgressMeter snapshot={snapshot} />
        </div>
        <ColumnLayout columns={4} variant="text-grid">
          <KeyValue label={<strong>{t(i18nKeys.eventLabs.challengeLab.infoSections.challengeId)}</strong>}>
            {challenge.id}
          </KeyValue>
          <KeyValue label={<strong>{t(i18nKeys.eventLabs.challengeLab.infoSections.difficultyLevel)}</strong>}>
            {t(ChallengeDifficulty.getByKey(challenge.props.difficulty).i18nKeyShort)}
          </KeyValue>
          {user?.isSuperAdmin && (
            <KeyValue label={<strong>{t(i18nKeys.eventLabs.challengeLab.infoSections.labStatus)}</strong>}>
              <LabStatus labShutoffStatus={labShutoffStatus} />
            </KeyValue>
          )}
          <KeyValue label={<strong>{t(i18nKeys.eventLabs.challengeLab.infoSections.totalLabsNeeded)}</strong>}>
            {snapshot.adjustedDesiredLabCount || 0}
          </KeyValue>
          <KeyValue label={<strong>{t(i18nKeys.eventLabs.challengeLab.infoSections.avgDeployTime)}</strong>}>
            {challenge.hasStack ? (
              <>
                {getDaysHoursMinutes(challenge.avgStackDeployTime)} /{' '}
                {getDaysHoursMinutes(challenge.avgDeployResolveTime)}
              </>
            ) : (
              `No Stack${challenge.hasIamPolicy ? ' (Only IAM)' : ''}`
            )}
          </KeyValue>
          {!!challenge.props.idleMinsBeforeReady && challenge.props.idleMinsBeforeReady > 0 && (
            <KeyValue label={<strong>{t(i18nKeys.eventLabs.challengeLab.infoSections.holdTime)}</strong>}>
              {getDaysHoursMinutes(challenge.props.idleMinsBeforeReady * 60000, false)}
            </KeyValue>
          )}
          {unusedImportedPropsCount > 0 ||
            (usedImportedPropsCount > 0 && (
              <KeyValue label={<strong>{t(i18nKeys.eventLabs.challengeLab.infoSections.importedProps)}</strong>}>
                {{ unusedImportedPropsCount }} free, {{ usedImportedPropsCount }} used
              </KeyValue>
            ))}
          <KeyValue label={<strong>{t(i18nKeys.eventLabs.challengeLab.infoSections.teamActivity)}</strong>}>
            {t(i18nKeys.eventLabs.challengeLab.infoSections.teamActivityCount, {
              startCount: startedChallengeCount,
              solvedCount: solvedChallengeCount,
            })}
          </KeyValue>
          <KeyValue label={<strong>{t(i18nKeys.eventLabs.challengeLab.infoSections.labAssignmentQueue)}</strong>}>
            {(snapshot.assignmentQueue || []).length}
          </KeyValue>
          {labInternalStatusTableRows.map((row) => (
            <KeyValue key={row.value} label={<strong>{row.label}</strong>}>
              {row.value}
            </KeyValue>
          ))}
          <KeyValue label={<strong>{t(i18nKeys.eventLabs.challengeLab.infoSections.poweredBy)}</strong>}>
            {LAB_PROVIDER_LABELS[labProvider]}
          </KeyValue>
        </ColumnLayout>
      </SpaceBetween>
      <Modal
        header={t(i18nKeys.general.confirm)}
        visible={showReasonModal}
        onDismiss={() => setShowReasonModal(false)}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="m">
              <Button variant="link" onClick={() => setShowReasonModal(false)}>
                {t(i18nKeys.events.eventDetails.buttons.noGoBack)}
              </Button>
              <Button variant="primary" disabled={!reason.trim()} onClick={() => {
                void shutoffChallengeLabs();
                setShowReasonModal(false);
              }}>
                {t(i18nKeys.general.submit)}
              </Button>
            </SpaceBetween>
          </Box>
        }>
        <Box>{t(i18nKeys.eventLabs.challengeLab.messages.enterReason)}</Box>
        <Textarea value={reason} onChange={({ detail }) => setReason(detail.value)} />
      </Modal>
      <Modal
        visible={showMetadata}
        onDismiss={() => setShowMetadata(false)}
        footer={
          <Box float="right">
            ``
            <SpaceBetween direction="horizontal" size="m">
              <Button variant="primary" onClick={() => setShowMetadata(false)}>
                {t(i18nKeys.general.ok)}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={<Header>{t(i18nKeys.eventLabs.challengeLab.messages.labMetadata)}</Header>}>
        <Box>
          <pre>{JSON.stringify(metadata, null, 4)}</pre>
        </Box>
      </Modal>
    </Container>
  );
};

export default ChallengeLab;
