import { config } from '../config/app-config';
import { Nullable } from './common';

/**
 * Identity providers supported by the Jam console.
 *
 * Enum values correspond to the idp's query parameter value.
 */
export enum IdentityProvider {
  /**
   * Training and certification (T&C), or Skill Builder, or Gandalf.
   */
  TC = 'tc',

  /**
   * AmazonFederate.
   */
  AmazonFederate = 'AmazonFederate',

  /**
   * AWSBuilderID.
   */
  AWSBuilderID = 'AWSBuilderID',
}

/**
 * Mapping of IdentityProvider to the identity provider name as it exists in Cognito.
 */
export const IdpToProviderName: { [key in IdentityProvider]: string } = {
  [IdentityProvider.TC]: config.cognito.gandalfIdpName,
  [IdentityProvider.AmazonFederate]: config.cognito.amazonFederateIdpName,
  [IdentityProvider.AWSBuilderID]: config.cognito.awsSkillBuilderIdpName,
};

/**
 * Reverse mapping of IdpToProviderName.
 */
export const ProviderNameToIdp = Object.entries(IdpToProviderName).reduce((prev, curr) => {
  return {
    ...prev,
    [curr[1]]: curr[0],
  };
}, {}) as Record<string, IdentityProvider>;

/**
 * Mapping of IdentityProvider to a user-facing identity provider label (i.e. Amazon Federate).
 */
export const IdpToProviderLabel: { [key in IdentityProvider]: string } = {
  [IdentityProvider.TC]: 'AWS Skill Builder',
  [IdentityProvider.AmazonFederate]: 'Amazon Federate',
  [IdentityProvider.AWSBuilderID]: 'AWSBuilderID',
};

/**
 * Determine whether the given idp string is a valid `IdentityProvider` enum member.
 */
export const isValidIdp = (idp?: Nullable<string>): idp is IdentityProvider => {
  if (!idp) {
    return false;
  }

  return (Object.values(IdentityProvider) as string[]).includes(idp);
};
