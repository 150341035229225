/* eslint-disable @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { ChallengeSet } from '../types/ChallengeSet';
import { Nullable } from '../types/common';
import _, { isArray, uniq } from 'lodash';
import { Event, EventPermission, EventPermissionType } from '../types/Event';
import { parseQueryParam, parseQueryParamList } from '../utils/route.utils';
import { SkillBuilderSubscription } from '../types/SkillBuilderSubscription';
import { isEmailValid, isNotEmpty } from '../utils/string.utils';
import { isEmpty, safeArray, safeFilterNulls } from '../utils/list.utils';
import { useChallenges } from './challenge.context';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../utils/i18n.utils';
import { ChallengeDescriptor } from '../types/Challenge';
import { useApi } from './api.context';
import { fromPlainObject } from '../utils/mapper.utils';
import { Campaign, JamCampaignRequest } from '../types/Campaign';
import { useCampaigns } from './campaigns.context';
import { preProdLogger } from '../utils/log.utils';
import { SAVE_ACTIONS } from '../components/campaigns/CampaignModel';

export type SetNewCampaignImportSources = Dispatch<SetStateAction<NewCampaignImportSources>>;
export type SetNewDuration = Dispatch<SetStateAction<string | null>>;
export type ToggleEditMode = () => void;
export type ToggleNewCampaign = () => void;
export type InitializeEditCampaign = (_event: Campaign) => void;
export type InitializeNewCampaign = () => void;
export type HandleUpdateEditCampaign = (_action: string, _payload: any) => void;
export type BulkHandleUpdateCampaign = (edits: CampaignEdit[]) => void;
export type DestroyEdits = () => void;
export type PopulateWithUrlParams = (params: URLSearchParams) => string[];
export type HasChanges = (campaign: Campaign) => boolean;
export type SaveUpdatedCampaign = (saveType: SAVE_ACTIONS) => Promise<void>;

// eslint-disable-next-line no-shadow
export enum EditCampaignActions {
  ADD_TAG = 'add-tag',
  REMOVE_TAG = 'remove-tag',
  TITLE = 'title',
  SKILLBUILDER_SUBSCRIPTION = 'skill-builder-subscription',
  AGREEMENT_ID = 'agreement-id',
  TYPE = 'type',
  SUPPORT_AND_COLLABORATION = 'support-and-collaboration',
  EVENT_PERMISSIONS = 'event-permissions',
  SCORING_SETTINGS = 'scoring-settings',
  CHIME_WEBHOOK_URL = 'chime-webhook-url',
  GAMIFICATION = 'gamification',
  PARTICIPANT_DOMAIN_ALLOWLIST = 'participant-domain-allowlist',
  FACILITATOR_DOMAIN_ALLOWLIST = 'facilitator-domain-allowlist',
  REGION_ALLOWLIST = 'region-allowlist',
  REGION_DENYLIST = 'region-denylist',
  USAGE_PLAN = 'usage-plan',
  CHALLENGE_DESCRIPTORS = 'challenge-descriptors',
  SLUG = 'SLUG',
  PARTICIPANT_LIMIT = 'participant-limit',
  REPORT_RECIPIENTS = 'report-recipients',
  LAB_TIMEOUT = 'lab-timeout',
  CUSTOM_SUBJECT = 'custom-subject',
  CUSTOM_MESSAGE = 'custom-message',
  CAMPAIGN_TYPE = 'campaign-type',
  ENABLE_CLUES = 'enable-clues',
  TIME_LIMIT = 'time-limit',
  PASSING_SCORE = 'passing-score',
  NUMBER_OF_ATTEMPTS = 'number-of-attempts',
}

// eslint-disable-next-line no-shadow
export enum CampaignURLParameter {
  TITLE = 'title',
  ID = 'id',
  OWNERS = 'owners',
  FACILITATORS = 'facilitators',
  TAGS = 'tags',
  CHALLENGES = 'challenges',
  CHALLENGE_SETS = 'challengeSets',
  AGREEMENT_ID = 'agreementId',
  SKILL_BUILDER_SUBSCRIPTION = 'skillBuilderSubscription',
}

export interface CampaignEdit {
  action: string;
  payload: any;
}
export interface NewCampaignImportSources {
  challengeSet: Nullable<ChallengeSet>;
  event: Nullable<Event>;
}

export interface EditCampaignContextValue {
  editedCampaign: Campaign | null;
  newEditedCampaign: Campaign | null;
  campaignEditMode: boolean;
  newCampaignMode: boolean;
  newCampaignImportSources: NewCampaignImportSources;
  newDuration: string | null;
  setNewCampaignImportSources: SetNewCampaignImportSources;
  setNewDuration: SetNewDuration;
  toggleEditMode: ToggleEditMode;
  toggleNewCampaign: ToggleNewCampaign;
  initializeEditCampaign: InitializeEditCampaign;
  initializeNewCampaign: InitializeNewCampaign;
  handleUpdateEditCampaign: HandleUpdateEditCampaign;
  bulkHandleUpdateEditCampaign: BulkHandleUpdateCampaign;
  destroyEdits: DestroyEdits;
  populateWithUrlParams: PopulateWithUrlParams;
  hasChanges: HasChanges;
  saveUpdatedCampaign: SaveUpdatedCampaign;
}

export const EditCampaignContext = React.createContext<EditCampaignContextValue>({
  editedCampaign: null,
  newEditedCampaign: null,
  campaignEditMode: false,
  newCampaignMode: false,
  newCampaignImportSources: { challengeSet: null, event: null },
  newDuration: null,
  setNewDuration: () => {
     // do nothing
  },
  setNewCampaignImportSources: () => {
     // do nothing
  },
  toggleEditMode: () => {
     // do nothing
  },
  toggleNewCampaign: () => {
     // do nothing
  },
  initializeEditCampaign: () => {
     // do nothing
  },
  initializeNewCampaign: () => {
     // do nothing
  },
  handleUpdateEditCampaign: () => {
     // do nothing
  },
  bulkHandleUpdateEditCampaign: (_edits: CampaignEdit[]) => {
     // do nothing
  },
  destroyEdits: () => {
     // do nothing
  },
  populateWithUrlParams: () => [],
  hasChanges: (_campaign: Campaign) => false,
  saveUpdatedCampaign: (_saveType: SAVE_ACTIONS) => new Promise(() => {
     // do nothing
  }),
});

const EditCampaignProvider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const [editedCampaign, setEditedCampaign] = useState<Campaign | null>(null);
  const [campaignEditMode, setCampaignEditMode] = useState(false);
  const [newEditedCampaign, setNewEditedCampaign] = useState<Campaign | null>(null);
  const [newCampaignMode, setNewCampaignMode] = useState(false);
  const [newDuration, setNewDuration] = useState<string | null>(null);
  const [newCampaignImportSources, setNewCampaignImportSources] = useState<NewCampaignImportSources>({
    challengeSet: null,
    event: null,
  });
  const { getChallengeDescriptors, getChallengeDescriptorsFromSet } = useChallenges();
  const { campaignApi } = useApi();
  const { getCampaignById, campaign } = useCampaigns();

  useEffect(() => {
    if (!campaignEditMode) {
      setEditedCampaign(null);
    }
  }, [editedCampaign]);

  const toggleEditMode = () => {
    if (campaignEditMode) {
      setEditedCampaign(null);
    }
    if (newCampaignMode) {
      setNewCampaignMode(false);
    }
    setCampaignEditMode(!campaignEditMode);
  };

  const destroyEdits = () => {
    setNewEditedCampaign(null);
    setEditedCampaign(null);
    setNewCampaignMode(false);
    setCampaignEditMode(false);
    setNewCampaignImportSources({ challengeSet: null, event: null });
    setNewDuration(null);
  };

  const toggleNewCampaign = () => {
    if (newCampaignMode) {
      setNewEditedCampaign(null);
    } else {
      initializeNewCampaign();
      if (campaignEditMode) {
        setCampaignEditMode(false);
      }
    }
    setNewCampaignMode(!newCampaignMode);
  };

  const initializeNewCampaign = () => {
    setNewEditedCampaign(new Campaign().withDefaults());
  };

  const initializeEditCampaign = (oldCampaign: Campaign) => {
    setEditedCampaign(_.cloneDeep(oldCampaign));
  };

  const saveUpdatedCampaign = async (saveType: SAVE_ACTIONS) => {
    if (editedCampaign && editedCampaign.id) {
      if (campaign && !_.isEqual(campaign?.tags, editedCampaign.tags)) {
        await campaignApi.updateTags(editedCampaign);
      }
      const jamCampaignRequest = fromPlainObject(editedCampaign, JamCampaignRequest) as JamCampaignRequest;
      switch (saveType) {
        case SAVE_ACTIONS.SUBMIT_CHANGE_REQUEST:
          return await campaignApi
            .createJamCampaignChangeRequest(editedCampaign.id, jamCampaignRequest)
            .then((res: Campaign) => {
              if (res?.id) {
                getCampaignById(res.id)
                  .then(() => {
                    destroyEdits();
                  })
                  .catch((err) => {
                    preProdLogger('Error retrieving saved campaign', err.message);
                  });
              }
            });
        case SAVE_ACTIONS.UPDATE_CHANGE_REQUEST:
          return await campaignApi
            .updateJamCampaignChangeRequest(editedCampaign.id, jamCampaignRequest)
            .then((res: Campaign) => {
              if (res.id) {
                getCampaignById(res.id)
                  .then(() => {
                    destroyEdits();
                  })
                  .catch((err) => {
                    preProdLogger('Error retrieving saved campaign', err.message);
                  });
              }
            });
        case SAVE_ACTIONS.UPDATE_CAMPAIGN_REQUEST:
          return await campaignApi
            .updateJamCampaignRequest(editedCampaign.id, jamCampaignRequest)
            .then((res: Campaign) => {
              if (res.id) {
                getCampaignById(res.id)
                  .then(() => {
                    destroyEdits();
                  })
                  .catch((err) => {
                    preProdLogger('Error retrieving saved campaign', err.message);
                  });
              }
            });
      }
    }
  };

  const bulkHandleUpdateEditCampaign = (edits: CampaignEdit[]) => {
    if (isEmpty(edits)) {
      return;
    }
    const newCampaign = updateCampaign(edits);
    if (newCampaignMode) {
      setNewEditedCampaign(newCampaign);
    } else {
      setEditedCampaign(newCampaign);
    }
  };

  const handleUpdateEditCampaign = (action: string, payload: any) => {
    bulkHandleUpdateEditCampaign([{ action, payload }]);
  };

  const updateCampaign = (edits: CampaignEdit[]): Campaign => {
    const campaignToEdit = newCampaignMode ? newEditedCampaign : editedCampaign;
    const newCampaign = _.cloneDeep(campaignToEdit);
    if (newCampaign) {
      for (const edit of edits) {
        switch (edit.action) {
          case EditCampaignActions.ADD_TAG:
            newCampaign?.addTag(edit.payload);
            break;
          case EditCampaignActions.REMOVE_TAG:
            newCampaign?.removeTag(edit.payload);
            break;
          case EditCampaignActions.TITLE:
            newCampaign.title = edit.payload;
            break;
          case EditCampaignActions.SKILLBUILDER_SUBSCRIPTION:
            if (newCampaign.campaignSettings.validSkillBuilderSubscriptions.includes(edit.payload)) {
              newCampaign.campaignSettings.validSkillBuilderSubscriptions =
                newCampaign.campaignSettings.validSkillBuilderSubscriptions.filter(
                  (skillbuilderType) => skillbuilderType !== edit.payload
                );
            } else {
              newCampaign.campaignSettings.validSkillBuilderSubscriptions.push(edit.payload);
            }
            break;
          case EditCampaignActions.TYPE:
            newCampaign.type = edit.payload;
            break;
          case EditCampaignActions.EVENT_PERMISSIONS:
            newCampaign.campaignSettings.eventPermissions = edit.payload;
            break;
          case EditCampaignActions.SCORING_SETTINGS:
            newCampaign.campaignSettings.scoringSettings = edit.payload;
            break;
          case EditCampaignActions.SUPPORT_AND_COLLABORATION:
            if (isArray(edit.payload)) {
              newCampaign.campaignSettings.collaborationOptions.messagingEnabled =
                edit.payload.includes('messagingEnabled');
              newCampaign.campaignSettings.collaborationOptions.supportChatEnabled =
                edit.payload.includes('supportChatEnabled');
            }
            break;
          case EditCampaignActions.CHIME_WEBHOOK_URL:
            newCampaign.campaignSettings.chimeWebHookUrl = edit.payload;
            break;
          case EditCampaignActions.GAMIFICATION:
            newCampaign.campaignSettings.gamified = edit.payload;
            break;
          case EditCampaignActions.PARTICIPANT_DOMAIN_ALLOWLIST:
            newCampaign.campaignSettings.participantDomainAllowlist = edit.payload;
            break;
          case EditCampaignActions.FACILITATOR_DOMAIN_ALLOWLIST:
            newCampaign.campaignSettings.facilitatorDomainAllowlist = edit.payload;
            break;
          case EditCampaignActions.REPORT_RECIPIENTS:
            newCampaign.campaignSettings.reportRecipients = edit.payload;
            break;
          case EditCampaignActions.REGION_ALLOWLIST:
            newCampaign.campaignSettings.regionAllowlist = edit.payload;
            break;
          case EditCampaignActions.REGION_DENYLIST:
            newCampaign.campaignSettings.regionDenylist = edit.payload;
            break;
          case EditCampaignActions.USAGE_PLAN:
            newCampaign.usagePlanId = edit.payload;
            break;
          case EditCampaignActions.CHALLENGE_DESCRIPTORS:
            newCampaign.challengeDescriptors = edit.payload;
            break;
          case EditCampaignActions.SLUG:
            newCampaign.id = edit.payload;
            break;
          case EditCampaignActions.PARTICIPANT_LIMIT:
            newCampaign.campaignSettings.maxExpectedParticipants = edit.payload;
            break;
          case EditCampaignActions.LAB_TIMEOUT:
            newCampaign.campaignSettings.labTimeoutHours = edit.payload;
            break;
          case EditCampaignActions.CUSTOM_SUBJECT:
            newCampaign.campaignSettings.inviteEmailSubject = edit.payload;
            break;
          case EditCampaignActions.CUSTOM_MESSAGE:
            newCampaign.campaignSettings.inviteEmailMessage = edit.payload;
            break;
          case EditCampaignActions.CAMPAIGN_TYPE:
            newCampaign.type = edit.payload;
            break;
          case EditCampaignActions.ENABLE_CLUES:
            newCampaign.campaignSettings.cluesAllowed = edit.payload;
            break;
          case EditCampaignActions.TIME_LIMIT:
            newCampaign.campaignSettings.sessionDurationLimitHours = edit.payload;
            break;
          case EditCampaignActions.PASSING_SCORE:
            newCampaign.campaignSettings.passScorePercent = edit.payload;
            break;
          case EditCampaignActions.NUMBER_OF_ATTEMPTS:
            newCampaign.campaignSettings.allowedAttempts = edit.payload;
            break;
          default:
            break;
        }
      }
    }
    return newCampaign || new Campaign();
  };

  const populateWithUrlParams = (urlParams: URLSearchParams): string[] => {
    if (!newCampaignMode || !newEditedCampaign) {
      return [];
    }
    const edits: (CampaignEdit | null)[] = [];

    // Campaign title
    edits.push(getEditActionFromParam(urlParams, CampaignURLParameter.TITLE, EditCampaignActions.TITLE));

    // Campaign id/slug
    edits.push(getEditActionFromParam(urlParams, CampaignURLParameter.ID, EditCampaignActions.SLUG));

    // Campaign Agreement ID/SOW
    edits.push(getEditActionFromParam(urlParams, CampaignURLParameter.AGREEMENT_ID, EditCampaignActions.AGREEMENT_ID));

    // Campaign permissions
    const addedPermissions = createEventPermissions(
      urlParams.getAll(CampaignURLParameter.OWNERS),
      urlParams.getAll(CampaignURLParameter.FACILITATORS)
    );

    // Campaign tags
    const tags = urlParams.getAll(CampaignURLParameter.TAGS);
    if (tags?.length) {
      edits.push({
        action: EditCampaignActions.ADD_TAG,
        payload: parseQueryParamList([...tags, ...safeArray(newEditedCampaign.tags)]),
      });
    }

    // Skill Builder subscription types
    const subscriptionTypes = urlParams.getAll(CampaignURLParameter.SKILL_BUILDER_SUBSCRIPTION);
    if (subscriptionTypes?.length) {
      // Get all values from the query params, casting to lower case and validating against SkillBuilderSubscriptionAlias enum.
      const types = parseQueryParamList(
        [...subscriptionTypes, ...safeArray(newEditedCampaign.campaignSettings.validSkillBuilderSubscriptions)],
        (v: string) => (v ? v.toUpperCase() : v),
        (v: string) => Object.values(SkillBuilderSubscription).includes(v as any)
      );

      edits.push({
        action: EditCampaignActions.SKILLBUILDER_SUBSCRIPTION,
        payload: types.map((type) => SkillBuilderSubscription[type as keyof typeof SkillBuilderSubscription]),
      });
    }

    const challengeIds = urlParams.getAll(CampaignURLParameter.CHALLENGES);
    if (challengeIds?.length) {
      edits.push({
        action: EditCampaignActions.CHALLENGE_DESCRIPTORS,
        payload: [
          ...safeArray(getChallengeDescriptors(challengeIds)),
          ...safeArray(newEditedCampaign.challengeDescriptors),
        ],
      });
    }

    const challengeSets = urlParams.getAll(CampaignURLParameter.CHALLENGE_SETS);
    if (challengeSets?.length) {
      const challenges = challengeSets.map((id) => getChallengeDescriptorsFromSet(id)).flat();
      edits.push({
        action: EditCampaignActions.CHALLENGE_DESCRIPTORS,
        payload: [
          ...safeArray(newEditedCampaign.challengeDescriptors),
          ...ChallengeDescriptor.filterNonUniqueChallengeDescriptorsFromOriginal(
            newEditedCampaign.challengeDescriptors,
            challenges
          ),
        ],
      });
    }

    if (!isEmpty(addedPermissions)) {
      // populate new campaign with permissions and notify of the change
      edits.push({ action: EditCampaignActions.EVENT_PERMISSIONS, payload: addedPermissions });
    }

    bulkHandleUpdateEditCampaign(safeFilterNulls(edits));
    return uniq(
      safeFilterNulls(
        edits.map((e) => {
          if (e) return EditCampaignActionTranslated(e.action) || null;
        })
      )
    ) as string[];
  };

  const getEditActionFromParam = (
    urlParams: URLSearchParams,
    paramKey: CampaignURLParameter,
    action: EditCampaignActions
  ): CampaignEdit | null => {
    const payload = parseQueryParam(urlParams, paramKey);
    if (isNotEmpty(payload)) {
      return { action, payload };
    }
    return null;
  };

  /**
   * make a list of eventPermissions object for the event from lists of owners and facilitators
   */
  const createEventPermissions = (rawOwnerParams: string[], rawFacilitatorParams: string[]) => {
    const owners = parseQueryParamList(
      [...safeArray(rawOwnerParams), ...safeArray(newEditedCampaign?.getOwners())],
      (v: string) => (v ? v.toLowerCase() : v),
      isEmailValid
    );

    const facilitators = parseQueryParamList(
      [...safeArray(rawFacilitatorParams), ...safeArray(newEditedCampaign?.getFacilitators())],
      (v: string) => (v ? v.toLowerCase() : v),
      isEmailValid
    );

    const users = uniq([...owners, ...facilitators]);
    const permissions: EventPermission[] = [];

    users.forEach((email) => {
      const isOwner = owners.includes(email);
      const isFacilitator = facilitators.includes(email);
      permissions.push(
        Object.assign(new EventPermission(), {
          email,
          eventPermissionType: isOwner
            ? EventPermissionType.OWNER
            : isFacilitator
            ? EventPermissionType.FACILITATOR
            : null,
        })
      );
    });
    return permissions;
  };

  const EditCampaignActionTranslated = (action: string): string => {
    switch (action) {
      case EditCampaignActions.TITLE:
        return t(i18nKeys.events.fields.title.title);
      case EditCampaignActions.SLUG:
        return t(i18nKeys.events.fields.slug.title);
      // TODO: add notes after it's translated
      case EditCampaignActions.EVENT_PERMISSIONS:
        return t(i18nKeys.events.eventDetails.headers.ownersAndPermissions);
      case EditCampaignActions.ADD_TAG:
        return t(i18nKeys.events.eventDetails.headers.tags);
      case EditCampaignActions.CHALLENGE_DESCRIPTORS:
        return t(i18nKeys.challenges.title);
      case EditCampaignActions.AGREEMENT_ID:
        return t(i18nKeys.events.eventDetails.headers.agreementId);
      case EditCampaignActions.SKILLBUILDER_SUBSCRIPTION:
        return t(i18nKeys.events.eventDetails.headers.awsSkillBuilderSubscription);
      default:
        return '';
    }
  };

  const hasChanges = (oldCampaign: Campaign) => {
    if (editedCampaign) {
      return Campaign.diff(oldCampaign, editedCampaign).changes.length > 0;
    }
    return false;
  };

  const data: EditCampaignContextValue = {
    editedCampaign,
    newEditedCampaign,
    campaignEditMode,
    newCampaignMode,
    newCampaignImportSources,
    setNewCampaignImportSources,
    newDuration,
    setNewDuration,
    toggleEditMode,
    toggleNewCampaign,
    initializeEditCampaign,
    initializeNewCampaign,
    handleUpdateEditCampaign,
    bulkHandleUpdateEditCampaign,
    destroyEdits,
    populateWithUrlParams,
    hasChanges,
    saveUpdatedCampaign,
  };

  return <EditCampaignContext.Provider value={data}>{children}</EditCampaignContext.Provider>;
};

const useEditCampaign = () => {
  const context = useContext(EditCampaignContext);
  if (context === undefined) {
    throw new Error('useEditCampaign can only be used inside EditCampaignProvider');
  }
  return context;
};

export { EditCampaignProvider, useEditCampaign };
