import { Button, Icon, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import { useTranslation } from 'react-i18next';
import { useUser } from '@/src/store/user.context';
import { Markdown } from '@/src/components/common/Markdown';
import { getTimeInBrowserLocalTime } from '@/src/utils/event-time.utils';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { Comment } from '@/src/types/common';
import './EventComments.scss';


interface CommentProps {
  commentDetails: Comment;
  key: string;
  updateComment: (id: string, value: string) => Promise<void>;
}

const EventCommentsDetails: React.FC<CommentProps> = ({ commentDetails, updateComment }) => {
    const { user } = useUser();
    const [editing, setEditing] = useState(false);
    const [editedComment, setEditedComment] = useState<string>('');
    const { t } = useTranslation();
  
    const canEditComment = () => {
      return commentDetails.author === user?.email;
    };
  
    const cancelEdit = () => {
      setEditing(false);
      setEditedComment('');
    };
  
    const saveEditedComment = () => {
        if (commentDetails.id && editedComment) {
            void updateComment(commentDetails.id, editedComment)
                .catch((e) =>  {throw e}).finally(() =>  cancelEdit());
        }
    };
  
  
    useEffect(() => {
      if (commentDetails.value) {
        setEditedComment(commentDetails.value);
      }
    }, [editing]);
  
    return (
      <div className='event-comments' style={{position: 'relative'}}>
        <SpaceBetween direction="horizontal" size="s">
          <Icon name="user-profile" />
          <strong>{commentDetails.author}</strong>
          {canEditComment() && (
            <div style={{ float: 'right' }}>
              {editing && (
                <div>
                  <SpaceBetween size="s" direction="horizontal">
                    <Button variant="link" onClick={() => cancelEdit()}>
                      {t(i18nKeys.general.cancel)}
                    </Button>
                    <Button variant="primary" onClick={saveEditedComment}>
                      {t(i18nKeys.general.save)}
                    </Button>
                  </SpaceBetween>
                </div>
              )}
            </div>
          )}
          {!commentDetails.updatedAt && (
            <div style={{ color: awsui.colorTextFormSecondary }}>
              {getTimeInBrowserLocalTime(commentDetails.createdAt, {
                includeDate: true,
                includeTime: true,
                includeComma: true,
              })}
            </div>
          )}
          {commentDetails.updatedAt && (
            <div style={{ color: awsui.colorTextFormSecondary }}>
              {t(i18nKeys.events.eventDetails.labels.edited)}
              {getTimeInBrowserLocalTime(commentDetails.updatedAt, {
                includeDate: true,
                includeTime: true,
                includeComma: true,
              })}
            </div>
          )}
          
          {commentDetails?.tags && commentDetails?.tags?.length > 0 && commentDetails?.tags.map((tag, index)=> <div style={{
              backgroundColor: awsui.colorBackgroundCellShaded,
              padding: '2px'
            }} key={index}>{tag}</div>)}
            <div style={{position:'absolute', right: '20px'}}>
              <SpaceBetween direction="horizontal" size={"xxl"} alignItems="end">
              {canEditComment() && (<>{!editing && (<Button iconName="edit" variant="inline-link" onClick={() => setEditing(!editing)}>{t(i18nKeys.general.edit)}</Button>)}</>)}
              </SpaceBetween>
            </div>
          </SpaceBetween>
          {!editing && <Markdown content={commentDetails.value || ''} />}
          {editing && <Textarea value={editedComment} onChange={({ detail }) => setEditedComment(detail.value)} />}
        
      </div>
    );
};

export default EventCommentsDetails;
