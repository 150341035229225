import React, { useContext, useState } from 'react';
import { Campaign, CampaignGroup, CampaignAttemptResponse, CampaignEventDetails } from '../types/Campaign';
import { DateRangeFilter } from '../types/common';
import { preProdLogger } from '../utils/log.utils';
import { useApi } from './api.context';
import { config } from '../config/app-config';

export interface CampaignsContextValue {
  campaignStarted: boolean;
  showCampaignRules: boolean;
  isFetchingCampaign: boolean;
  campaigns: Campaign[];
  campaign: Campaign | undefined;
  campaignGroup: CampaignGroup | undefined;
  campaignAttempt: CampaignAttemptResponse | undefined;
  loadCampaigns: (filter: DateRangeFilter) => Promise<void>;
  getCampaignById: (id: string) => Promise<void>;
  addCampaignComment: (comment: string) => Promise<void>;
  updateCampaignComment: (commentId: string, commentValue: string) => Promise<void>;
  deleteCampaignComment: (commentId: string) => Promise<void>;
  approveChangeRequest: (comment: string) => Promise<void>;
  denyChangeRequest: (comment: string) => Promise<void>;
  markChangeRequestPending: () => Promise<void>;
  markRequestPending: () => Promise<void>;
  approveCampaignRequest: (slug: string, comment: string) => Promise<void>;
  denyCampaignRequest: (comment: string) => Promise<void>;
  deleteCampaign: () => Promise<void>;
  createCampaignGroup: (campaignGroup: CampaignGroup) => Promise<void>;
  cancelChangeRequest: (comment: string) => Promise<void>;
  cancelCampaignRequest: (comment: string) => Promise<void>;
  getCampaignGroupById: (campaignGroupId: string) => Promise<void>;
  startCampaignAttempt: (eventName: string, silent?: boolean, registrationId?: string) => Promise<void>;
  startCampaignAttemptWithRegistrationId: (eventName: string, registrationId: string, isFetching?: boolean, silent?: boolean) => Promise<void>;
  updateCampaignAttemptFromWSMessage: (attempt: CampaignAttemptResponse) => void,
  getCampaignEventDetails: (eventName: string, isFetching?: boolean, silent?:boolean) => Promise<CampaignEventDetails>
  handleShowCampaignRules: (show: boolean) => void;
  handleIsFetchingCampaign: (isFetching: boolean) => void;
}

const CampaignsContext = React.createContext<CampaignsContextValue>({
  campaignStarted: false,
  showCampaignRules: false,
  isFetchingCampaign: true,
  campaigns: [],
  campaign: undefined,
  campaignGroup: undefined,
  campaignAttempt: undefined,
  loadCampaigns: () => new Promise(() => {
     // do nothing
  }),
  getCampaignById: () => new Promise(() => {
     // do nothing
  }),
  addCampaignComment: (_commentValue: string) => new Promise(() => {
     // do nothing
  }),
  updateCampaignComment: (_commentId: string, _commentValue: string) => new Promise(() => {
     // do nothing
  }),
  deleteCampaignComment: (_commentId: string) => new Promise(() => {
     // do nothing
  }),
  approveChangeRequest: (_comment: string) => new Promise(() => {
     // do nothing
  }),
  denyChangeRequest: (_comment: string) => new Promise(() => {
     // do nothing
  }),
  markChangeRequestPending: () => new Promise(() => {
     // do nothing
  }),
  markRequestPending: () => new Promise(() => {
     // do nothing
  }),
  approveCampaignRequest: (_slug: string, _comment: string) => new Promise(() => {
     // do nothing
  }),
  denyCampaignRequest: (_comment: string) => new Promise(() => {
     // do nothing
  }),
  deleteCampaign: () => new Promise(() => {
     // do nothing
  }),
  createCampaignGroup: () => new Promise(() => {
     // do nothing
  }),
  cancelChangeRequest: () => new Promise(() => {
     // do nothing
  }),
  cancelCampaignRequest: () => new Promise(() => {
     // do nothing
  }),
  getCampaignGroupById: () => new Promise(() => {
     // do nothing
  }),
  startCampaignAttempt: (_eventName: string, _silent?: boolean, _registrationId?: string) => new Promise(() => {
    // do nothing
  }),
  startCampaignAttemptWithRegistrationId: (_eventName: string, _registrationId: string, _isFetching?: boolean, _silent?: boolean) => new Promise(() => {
    // do nothing
  }),
  updateCampaignAttemptFromWSMessage: (_attempt: CampaignAttemptResponse) => {
    // do nothing
  },
  getCampaignEventDetails: (_eventName: string, _isFetching?: boolean, _silent?: boolean) => new Promise(() => {
    // do nothing
  }),
  handleShowCampaignRules: (_show: boolean) => {
    // do nothing
  },
  handleIsFetchingCampaign: (_isFetching: boolean) => {
    // do nothing
  },
});

const CampaignsProvider: React.FC = ({ children }) => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [campaign, setCampaign] = useState<Campaign | undefined>();
  const [campaignGroup, setCampaignGroup] = useState<CampaignGroup | undefined>();
  const [campaignAttempt, setCampaignAttempt] = useState<CampaignAttemptResponse | undefined>();
  const [campaignStarted, setCampaignStarted] = useState(false);
  const [showCampaignRules, setShowCampaignRules] = useState(false);
  const [isFetchingCampaign, setIsFetchingCampaign] = useState(true);
  const { campaignApi } = useApi();

  const loadCampaigns = async (filter: DateRangeFilter) => {
    await campaignApi
      .getJamCampaignsByDateRange(filter)
      .then((res) => {
        setCampaigns(res);
      })
      .catch((err) => {
        preProdLogger('Error getting campaigns', err.message);
      });
  };

  const getCampaignById = async (id: string) => {
    await campaignApi
      .getCampaign(id)
      .then((res) => {
        setCampaign(res);
      })
      .catch((err) => {
        preProdLogger('Error getting campaigns', err.message);
      });
  };

  const addCampaignComment = async (commentValue: string) => {
    if (campaign?.id) {
      await campaignApi
        .addComment(campaign.id, commentValue)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error adding comment', err.message);
        });
    }
  };

  const updateCampaignComment = async (commentId: string, commentValue: string) => {
    if (campaign?.id) {
      await campaignApi
        .updateComment(campaign.id, commentId, commentValue)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error updating comment', err.message);
        });
    }
  };

  const deleteCampaignComment = async (commentId: string) => {
    if (campaign?.id) {
      await campaignApi
        .deleteComment(campaign.id, commentId)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error deleting comment', err.message);
        });
    }
  };

  const approveChangeRequest = async (comment: string) => {
    if (campaign?.id) {
      await campaignApi
        .approveJamCampaignChangeRequest(campaign.id, comment)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error approving campaign request', err.message);
        });
    }
  };

  const denyChangeRequest = async (comment: string) => {
    if (campaign?.id) {
      await campaignApi
        .denyJamCampaignChangeRequest(campaign.id, comment)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error denying change request', err.message);
        });
    }
  };

  const cancelChangeRequest = async (comment: string) => {
    if (campaign?.id) {
      await campaignApi
        .cancelJamCampaignChangeRequest(campaign?.id, comment)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error cancelling change request', err.message);
        });
    }
  };

  const cancelCampaignRequest = async (comment: string) => {
    if (campaign?.id) {
      await campaignApi
        .cancelJamCampaignRequest(campaign?.id, comment)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error cancelling campaign request', err.message);
        });
    }
  };

  const markChangeRequestPending = async () => {
    if (campaign?.id) {
      await campaignApi
        .setJamCampaignChangeRequestPending(campaign.id)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error setting change request as pending', err.message);
        });
    }
  };

  const markRequestPending = async () => {
    if (campaign?.id) {
      await campaignApi
        .setJamCampaignRequestPending(campaign.id)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error setting request as pending', err.message);
        });
    }
  };

  const approveCampaignRequest = async (slug: string, comment: string) => {
    if (campaign?.id) {
      await campaignApi
        .approveJamCampaignRequest(campaign.id, slug, comment)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error setting request as pending', err.message);
        });
    }
  };

  const denyCampaignRequest = async (comment: string) => {
    if (campaign?.id) {
      await campaignApi
        .denyJamCampaignRequest(campaign.id, comment)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error setting request as pending', err.message);
        });
    }
  };

  const deleteCampaign = async () => {
    if (campaign?.id) {
      await campaignApi
        .deleteCampaign(campaign)
        .then(() => {
          preProdLogger('Deleted campaign');
        })
        .catch((err) => {
          preProdLogger('Error deleting campaign', err.message);
        });
    }
  };

  const createCampaignGroup = async (newCampaignGroup: CampaignGroup) => {
    await campaignApi.createCampaignGroup(newCampaignGroup);
  };

  const getCampaignGroupById = async (campaignGroupId: string) => {
    if (campaign?.id) {
      await campaignApi.getCampaignGroup(campaign?.id, campaignGroupId).then((res) => {
        setCampaignGroup(res);
      });
    }
  };


  const startCampaignAttemptWithRegistrationId = async (eventName: string, registrationId: string, silent = true) => {
    if (config.isLrsIntegrationEnabled !== true) {
      await startCampaignAttempt(eventName, silent, null);
    } else {
      await startCampaignAttempt(eventName, silent, registrationId);
    }
  }
  /**
   * This method creates new a campaign attempt for the event. If none exist, the attemptNumber 
   * is set to one, otherwise the attemptNumber gets incremented by 1.
   * 
   * @param eventName 
   * @param silent 
   */
  const startCampaignAttempt = async (eventName: string, silent = true, registrationId: string | null = null) => {
    try{
      await campaignApi.startCampaignAttempt(eventName, silent, registrationId);
      preProdLogger('Started new campaign attempt');
    } catch (err){
      // Error handling happens in the calling location
      throw err;
    } 
  }

  /**
   * Update the Campaign Attempt for WS messaging
   * 
   * @param attempt 
   */
  const updateCampaignAttemptFromWSMessage = (attempt: CampaignAttemptResponse) => {
    setCampaignAttempt({...campaignAttempt, ...attempt});
  }

  /**
   * Get campaign event details and start campaign attempt 0 if npt
   * already started. 
   * 
   * @param eventName 
   * @param isFetching 
   * @returns 
   */
  const getCampaignEventDetails = async (eventName: string, isFetching = true, silent = true): Promise<CampaignEventDetails> => {
    if(isFetching){
      setIsFetchingCampaign(isFetching);
    }
    try{
      const eventCodeResponse = await campaignApi.getCampaignEventDetails(eventName, silent);
      setCampaignAttempt(eventCodeResponse.campaignAttempt);
      setCampaignStarted(
          eventCodeResponse.campaignAttempt && 
          eventCodeResponse.campaignAttempt.attemptNumber > 0 && 
          eventCodeResponse.campaignAttempt.started
      );

      preProdLogger('Successfully loaded event response');
      return eventCodeResponse;
    } catch (err){
      // Error handling happens in the calling location
      throw err;
    } finally {
      if(isFetching){
        setIsFetchingCampaign(false);
      }
    }
  }

  /**
   * Sets the value of showCampaignRules to true or false
   */
  const handleShowCampaignRules = (show: boolean) => {
    setShowCampaignRules(show);
  }

  /**
   * Sets the value of isFetchingCampaign
   * 
   * @param isFetching 
   */
  const handleIsFetchingCampaign = (isFetching: boolean) => {
    setIsFetchingCampaign(isFetching);
  }

  const data: CampaignsContextValue = {
    campaignStarted,
    showCampaignRules,
    isFetchingCampaign,
    campaigns,
    campaign,
    campaignGroup,
    campaignAttempt,
    loadCampaigns,
    getCampaignById,
    addCampaignComment,
    updateCampaignComment,
    deleteCampaignComment,
    approveChangeRequest,
    denyChangeRequest,
    markChangeRequestPending,
    markRequestPending,
    approveCampaignRequest,
    denyCampaignRequest,
    cancelChangeRequest,
    cancelCampaignRequest,
    deleteCampaign,
    createCampaignGroup,
    getCampaignGroupById,
    startCampaignAttempt,
    startCampaignAttemptWithRegistrationId,
    updateCampaignAttemptFromWSMessage,
    getCampaignEventDetails,
    handleShowCampaignRules,
    handleIsFetchingCampaign
  };

  return <CampaignsContext.Provider value={data}>{children}</CampaignsContext.Provider>;
};

const useCampaigns = () => {
  const context = useContext(CampaignsContext);
  if (context === undefined) {
    throw new Error('useCampaigns can only be used inside CampaignsProvider');
  }
  return context;
};

export { CampaignsProvider, useCampaigns };
