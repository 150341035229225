import { Alert, Box, ExpandableSection, Grid, Input, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditEventActions, useEditEvent } from '../../../../store/edit-event.context';
import { useUser } from '../../../../store/user.context';
import { Event } from '../../../../types/Event';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { Campaign } from '@/src/types/Campaign';

interface PrizesProps {
  event: Event | Campaign;
}

const Prizes: React.FC<PrizesProps> = ({ event }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { editMode, editedEvent, handleUpdateEditEvent } = useEditEvent();

  if (event instanceof Campaign || event?.isCampaignEvent) {
    return null;
  }

  const getPrizeTeamCount = (): number => {
    if (editedEvent?.prizeInformation.prizeCount == null || editedEvent?.prizeInformation.prizeCount < 1) {
      return 0;
    }
    return Math.floor(editedEvent?.prizeInformation.prizeCount / event.maxTeamSize);
  };

  return (
    <ExpandableSection
      variant="container"
      headerText={t(i18nKeys.events.eventDetails.headers.prizes.header)}>
      <Box>{t(i18nKeys.events.eventDetails.headers.prizes.description)}</Box>
      {editMode && editedEvent && user && (
        <SpaceBetween size="s" direction="vertical">
          <Alert
            type="warning"
            visible={
              (editedEvent.prizeInformation.prizeCount &&
                editedEvent?.prizeInformation?.prizeCount > 0 &&
                editedEvent?.prizeInformation?.prizeCount < event.maxTeamSize) ||
              false
            }>
            {t(i18nKeys.events.eventDetails.messages.prizes.morePrizesThanTeamSize)}
          </Alert>
          <div style={{ marginTop: '-6px' }}>
            {t(i18nKeys.events.eventDetails.messages.prizes.awardUpTo)}
            <Input
              inputMode="numeric"
              type="number"
              disabled={!event?.canEditAttribute('prizeInformation', user)}
              className="numeric-input"
              value={editedEvent?.prizeInformation.prizeCount?.toString() || '0'}
              onChange={({ detail }) => handleUpdateEditEvent(EditEventActions.PRIZE_COUNT, Number(detail.value))}
            />
            {t(i18nKeys.events.eventDetails.messages.prizes.totalPrizes)}
          </div>
          {editedEvent?.prizeInformation?.prizeCount && editedEvent?.prizeInformation?.prizeCount > 0 && (
            <SpaceBetween size="s" direction="vertical">
              <div>
                {getPrizeTeamCount() > 1 && (
                  <span>
                    {t(i18nKeys.events.eventDetails.messages.prizes.awardOnePrizeForEachPlural, {
                      teamCount: getPrizeTeamCount(),
                    })}
                    <sup>1</sup>
                    {t(i18nKeys.events.eventDetails.messages.prizes.thatSolveAnyChallengePlural)}
                  </span>
                )}
                {getPrizeTeamCount() < 2 && (
                  <span>
                    {t(i18nKeys.events.eventDetails.messages.prizes.awardOnePrizeForEach)}
                    <sup>1</sup>
                    {t(i18nKeys.events.eventDetails.messages.prizes.thatSolveAnyChallenge)}
                  </span>
                )}
                <Input
                  inputMode="numeric"
                  type="number"
                  disabled={!event?.canEditAttribute('prizeInformation', user)}
                  className="numeric-input"
                  onChange={({ detail }) => handleUpdateEditEvent(EditEventActions.MAX_CLUES, Number(detail.value))}
                  value={editedEvent?.prizeInformation.maxClues?.toString() || '0'}
                />
                {t(i18nKeys.events.eventDetails.messages.prizes.clues)}
              </div>
              <div className="secondary-text">
                <sup>1</sup>
                {t(i18nKeys.events.eventDetails.messages.prizes.disclaimer)}
              </div>
            </SpaceBetween>
          )}
        </SpaceBetween>
      )}
      {!editMode && (
        <>
          <div className="section-first-row">
            <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
              <div className="secondary-text">{t(i18nKeys.events.eventDetails.labels.prizes.prizeCount)}</div>
              <div>
                {!event?.prizeInformation?.prizeCount &&
                  t(i18nKeys.events.eventDetails.labels.prizes.noPrizeCountMessage)}
                {event?.prizeInformation?.prizeCount === 1 &&
                  t(i18nKeys.events.eventDetails.labels.prizes.onePrizeCountMessage)}
                {!!event?.prizeInformation?.prizeCount &&
                  event?.prizeInformation?.prizeCount > 1 &&
                  t(i18nKeys.events.eventDetails.labels.prizes.manyPrizeCountMessage, { prizeCount: event?.prizeInformation?.prizeCount })}
              </div>
            </Grid>
          </div>
          {!!event?.prizeInformation.prizeCount && event?.prizeInformation.prizeCount > 0 && (
            <div className="grey-section-divider-top" style={{ paddingBottom: '18px' }}>
              <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
                <div className="secondary-text">{t(i18nKeys.events.eventDetails.labels.prizes.maxClues)}</div>
                <div>
                  {!!event?.prizeInformation.maxClues && event?.prizeInformation?.maxClues > 0
                    ? event?.prizeInformation.maxClues
                    : t(i18nKeys.events.eventDetails.labels.none)}
                </div>
              </Grid>
            </div>
          )}
        </>
      )}
    </ExpandableSection>
  );
};
export default Prizes;
