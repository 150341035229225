import { isEmpty, noop } from 'lodash';
import { InputValidationHelper } from '../types/InputValidator';
import { LocalizedError } from '../types/LocalizedError';
import { NullableNumber, NullableString } from '../types/common';
import { isEmailValid } from './string.utils';

export const trapError = (func: () => any): LocalizedError | null => {
  try {
    func();
    return null;
  } catch (e: any) {
    if (LocalizedError.isLocalizedError(e)) {
      return e as LocalizedError;
    }
    return new LocalizedError(e.message as string, undefined, true);
  }
};

export const phoneNumberIsValid = (phoneNumber: string): boolean => {
  return /^\+\d{5,20}$/.test(phoneNumber);
};

export const isValidLength = (attribute: NullableString, minLength: number, maxLength: number, isRequired = true) => {
  if (!attribute?.trim() && !isRequired) {
    return true;
  }
  if (minLength > maxLength || minLength < 0 || maxLength < 0) {
    throw new Error('Invalid args');
  }

  const str = attribute?.trim() || '';
  return attribute !== null && str.length >= minLength && str.length <= maxLength;
};

export const isValidInteger = (attribute: NullableNumber, min: number, max: number, isRequired?: boolean) => {
  if (!attribute && !isRequired) {
    return true;
  }

  if (min > max || min < 0 || max < 0) {
    throw new Error('Invalid args');
  }

  return attribute !== null && attribute >= min && attribute <= max;
};

export const isValidMinMax = (minVal: NullableNumber, maxVal: NullableNumber, minLimit: number, maxLimit: number) => {
  return (
    minVal &&
    maxVal &&
    minVal <= maxVal &&
    isValidInteger(minVal, minLimit, maxLimit, true) &&
    isValidInteger(maxVal, minLimit, maxLimit, true)
  );
};

export const isValidUrl: (attr: NullableString) => boolean = (attribute: NullableString) => {
  if (!attribute) {
    return false;
  }
  let url: URL;
  try {
    url = new URL(attribute);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const containsYear = (attribute: NullableString) => {
  // checks if attribute contains year, if attribute is null then it implicitly doesn't contain year
  return /((19)|(20))\d\d/.test(attribute || '');
};

export const containsOnlyNumbers = (attribute: NullableString) => {
  // checks if attribute contains only numbers, if attribute is null then it implicitly doesn't contain only numbers
  return /^\d+$/.test(attribute || '');
};

export const containsLargeEventName = (attribute: NullableString) => {
  if (attribute) {
    return (
      attribute.includes('reinvent') ||
      attribute.includes('re-invent') ||
      attribute.includes('reinforce') ||
      attribute.includes('re-inforce') ||
      attribute.includes('re:invent') ||
      attribute.includes('re:inforce') ||
      attribute.includes('reignite') ||
      attribute.includes('re-ignite') ||
      attribute.includes('re:ignite')
    );
  }

  return false;
};

export const containsWhitespace = (attribute: NullableString) => {
  // checks if attribute contains any whitespace, if attribute is null then it implicitly doesn't contain whitespace
  return /\s/.test(attribute || '');
};

export const isValidChallengeId = (attribute: NullableString) => {
  // Checks if attribute is a valid challenge ID, such that it only consists of letters a-z, A-Z, hyphens, and
  // underscores. Length is tested elsewhere. We use '+' instead of '*' here to exclude null attributes.
  return /^[a-zA-Z0-9\-_]+$/.test(attribute || '');
};

export const isValidEmail = (attribute: NullableString) => {
  // Checks if attribute is a valid email ID, such that it only consists of letters a-z, A-Z, hyphens, and underscores.
  return /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,}$/.test(attribute || '');
};

export const validateSection = <T>(
  validatorHelperByField: Map<T, InputValidationHelper>,
  errorSetterByField: Map<T, (error: string) => void>,
  setErrors = false
): boolean => {
  const fieldIsValid: boolean[] = [];
  const fields = Array.from(errorSetterByField.keys());
  for (const key of fields) {
    fieldIsValid.push(validateField(validatorHelperByField, errorSetterByField, key, setErrors));
  }
  return fieldIsValid.reduce((prev, next) => prev && next);
};

export const validateField = <T>(
  validatorHelperByField: Map<T, InputValidationHelper>,
  errorSetterByField: Map<T, (error: string) => void>,
  field: T,
  setError = true
) => {
  const validation = validatorHelperByField.get(field);
  if (!validation) {
    throw new Error('Validation logic does not exist!');
  }
  const isValid = validation.isValid();
  const error = validation.checkErrors();
  if (setError) {
    const setErrorFn = errorSetterByField.get(field) || noop;
    setErrorFn(error);
  }
  return isValid;
};

export const validateIsEmpty = (value: string | undefined, errorText: string) => {
  const isValid = !isEmpty(value);

  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};

export const validateEmailField = (email: string, errorText: string) => {
  let isValid = !isEmpty(email);
  if (isValid) {
    isValid = isEmailValid(email);
  }

  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};

export const validateMatchField = (value: string, matchValue: string, errorText: string) => {
  const isValid = value === matchValue;

  return {
    isValid: () => isValid,
    checkErrors: () => (!isValid ? errorText : ''),
  };
};

export interface Validator<T> {
  isValidSection: (setErrors?: boolean) => boolean;
  isValidField: (field: T, setError?: boolean) => boolean;
}
