import { Box, Checkbox, ExpandableSection, Grid } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditCampaignActions, useEditCampaign } from '../../../store/edit-campaign.context';
import { EditEventActions, useEditEvent } from '../../../store/edit-event.context';
import { useUser } from '../../../store/user.context';
import { Campaign } from '../../../types/Campaign';
import { Event } from '../../../types/Event';
import { i18nKeys } from '../../../utils/i18n.utils';

interface GamifiedProps {
  target?: Event | Campaign;
}

const Gamified: React.FC<GamifiedProps> = ({ target }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { editMode, editedEvent, handleUpdateEditEvent } = useEditEvent();
  const { campaignEditMode, editedCampaign, handleUpdateEditCampaign } = useEditCampaign();
  const isEvent = target instanceof Event;
  const canEdit = editMode || campaignEditMode;
  const gamified = isEvent ? target.gamified : target?.campaignSettings?.gamified;
  const editedGamified = isEvent ? editedEvent?.gamified : editedCampaign?.campaignSettings.gamified;

  return (
    <ExpandableSection
      variant="container"
      headerText={t(i18nKeys.events.eventDetails.headers.gamification.header)}>
      <Box margin={{ bottom: 'm' }}>
        {isEvent
          ? t(i18nKeys.events.eventDetails.headers.gamification.description)
          : t(i18nKeys.campaigns.headers.campaignDetails.descriptions.gamification)}
      </Box>
      {canEdit && user && (
        <Checkbox
          checked={editedGamified !== undefined ? editedGamified : true}
          disabled={!target?.canEditAttribute('gamified', user)}
          onChange={({ detail }) =>
            isEvent
              ? handleUpdateEditEvent(EditEventActions.GAMIFICATION, detail.checked)
              : handleUpdateEditCampaign(EditCampaignActions.GAMIFICATION, detail.checked)
          }>
          {t(i18nKeys.events.eventDetails.labels.gamify)}
        </Checkbox>
      )}
      {!canEdit && (
        <div className="section-first-row">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
            <div className="secondary-text">{t(i18nKeys.events.eventDetails.labels.gamified)}</div>
            <div>{gamified ? t(i18nKeys.general.yes) : t(i18nKeys.general.no)}</div>
          </Grid>
        </div>
      )}
    </ExpandableSection>
  );
};
export default Gamified;
