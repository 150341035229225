    import { i18nKeys } from '@/src/utils/i18n.utils';
	 import { Button, Container, Grid, Header, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
	 import React, { useState } from 'react';
	 import { useTranslation } from 'react-i18next';
	 import EventCommentsDetails from './EventCommentsDetails';
	 import { Comment } from '@/src/types/common';
     import "./EventComments.scss";
	 
	 interface CommentsProps {
	   comments: Comment[];
	   addNewComment: (value: string) => Promise<void>;
	   updateComment: (id: string, value: string) => Promise<void>;
	 }
	 
	 const EventComments: React.FC<CommentsProps> = ({ comments, addNewComment, updateComment }) => {
	   const { t } = useTranslation();
	   const [newComment, setNewComment] = useState('');
	 
	   const handleAddNewComment = () => {
		   if (newComment.length > 0) {
			   void addNewComment(newComment).then(() => setNewComment(''))
				   .catch((e) => {throw e});
		   }
	   };
	 
	   return (
	     <div>
	       <SpaceBetween size="s">
	       <Container header={<Header variant="h2">{t(i18nKeys.comments.header)} {`(${comments.length})`}</Header>}>
	           {comments.length > 0 && (
	             <SpaceBetween size="l">
                    <div className='event-comments'>
	               {comments.map((value: Comment) => {
	                 return (
                        <EventCommentsDetails
                            commentDetails={value}
                            key={`comment-${value.id}`}
                            updateComment={updateComment}
                        />
	                 );
	               })}
                   </div>
	             </SpaceBetween>
	           )}
	           {comments.length === 0 && <span>{t(i18nKeys.comments.messages.noComments)}</span>}
	         </Container>
	         <Container header={<Header variant="h2">{t(i18nKeys.comments.labels.enterComment)}</Header>}>
	           <Grid gridDefinition={[{ colspan: 8 }, { colspan: 8 }]}>
	             <Textarea
	               placeholder={t(i18nKeys.comments.placeHolder)}
	               value={newComment}
	               onChange={({ detail }) => setNewComment(detail.value)}
	             />
	             <div style={{ float: 'right' }}>
	               <Button variant="primary" disabled={newComment.trim().length === 0} onClick={handleAddNewComment}>
	                 {t(i18nKeys.general.submit)}
	               </Button>
	             </div>
	           </Grid>
	         </Container>
	       </SpaceBetween>
	     </div>
	   );
	 };
	 export default EventComments;