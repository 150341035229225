import { ChartProperties, DataEntry } from '@/src/utils/chart.utils';

import { AreaChart, AreaChartProps, LineChart, LineChartProps } from '@amzn/awsui-components-react';
import moment from 'moment';
import React, { useMemo } from 'react';

interface ChartProps {
  chartProperty: ChartProperties;
}

type LineChartPropsType = LineChartProps<Date | number>;
type AreaChartPropsType = AreaChartProps<Date | number>;

const AutoScalingChart: React.FC<ChartProps> = ({ chartProperty }) => {
  const isAreaChart = chartProperty.type === 'value-over-time-area';

  const getChartData = () => {
    const entries = chartProperty.dataSets.reduce<DataEntry[]>(
      (output, dataSet) => [...output, ...dataSet.entries],
      []
    );

    const oldestTimestamp = entries.reduce((oldest: number, entry: DataEntry): number => {
      if (!oldest) {
        return entry.time || 0;
      }
      if ((entry.time || 0) < oldest) {
        return entry.time || 0;
      }
      return oldest;
    }, 0);

    const newestTimestamp = entries.reduce((newest: number, entry: DataEntry): number => {
      if (!newest) {
        return entry.time || 0;
      }
      if ((entry.time || 0) > newest) {
        return entry.time || 0;
      }
      return newest;
    }, 0);

    const oldestTime = new Date(oldestTimestamp);
    // if the first entry is before the time range,
    // then make the first point 10 minutes before the first entry,
    // else make the first point the time range start
    const firstPointDate = moment(oldestTime).isBefore(moment.parseZone(chartProperty.timeRangeStart))
      ? moment(oldestTime).subtract(10, 'minutes').toDate()
      : moment.parseZone(chartProperty.timeRangeStart).toDate();

    // make different datasets for initial included or not
    const chatData = chartProperty.dataSets.map((dataSet) => {
      const data = [
        ...dataSet.entries.map((entry) => ({
          x: new Date(entry.time as number),
          y: entry.value,
        })),
      ];
      if (!chartProperty.noInitial) {
        data.unshift({ x: firstPointDate, y: 0 });
      }
      return {
        title: dataSet.label || '',
        color: dataSet.color as string,
        data,
        type: isAreaChart ? 'area' : 'line',
      };
    });

    return {
      chatData,
      newestTimestamp: new Date(newestTimestamp),
      oldestTimestamp: chartProperty.noInitial ? new Date(oldestTimestamp) : firstPointDate,
    };
  };

  const chartFeed = useMemo(getChartData, [chartProperty]);

  const chartProps: AreaChartPropsType | LineChartPropsType = {
    key: chartProperty.id,
    xTickFormatter: chartProperty.xTickFormatter,
    // @ts-expect-error ignore
    series: chartFeed.chatData,
    xDomain: [chartFeed.oldestTimestamp, chartFeed.newestTimestamp],
    yDomain: [0, chartProperty.ySuggestedMax],
    ariaLabel: chartProperty.title,
    height: 300,
    hideFilter: true,
    xScaleType: 'time',
    xTitle: chartProperty.xLabel,
    yTitle: chartProperty.yLabel,
  };

  if (isAreaChart) {
    return <AreaChart {...(chartProps as AreaChartPropsType)} />;
  }

  return <LineChart {...(chartProps as LineChartPropsType)} />;
};

export default AutoScalingChart;
