import { Container, Grid, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Event } from '../../../types/Event';
import { i18nKeys } from '../../../utils/i18n.utils';;
import Gamified from '../../common/CampaignEventComponents/Gamified';
import ParticipantRegistration from './AdvancedSettings/ParticipantRegistration';
import Scoring from '../../common/CampaignEventComponents/Scoring';
import Prizes from './AdvancedSettings/Prizes';
import SkillBuilderEventSettings from '../../common/CampaignEventComponents/SkillBuilder';
import AccessControl from '../../common/CampaignEventComponents/AccessControl';
import LabAvailabilty from '../../common/CampaignEventComponents/LabAvailability';
import Link from '../../ui/atoms/Link/Link';
import { RoutePath } from '@/src/RoutePath';
import { useUser } from '@/src/store/user.context';
import TeamSettings from './Summary/TeamSettings';
import { noop } from 'lodash';

interface EventDetailsAdvancedSettingsProps {
  event: Event;
}

const EventDetailsAdvancedSettings: React.FC<EventDetailsAdvancedSettingsProps> = ({ event }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  return (
    <SpaceBetween direction="vertical" size="s">
      <Container>
        <Grid gridDefinition={[{ colspan: 8 }]}>
          <Header
            variant="h2"
            description={
              <div>
                {t(i18nKeys.events.eventDetails.headers.advancedSettings.description)}
                <Link href={RoutePath.FAQ}>FAQ</Link>
              </div>
            }>
            {t(i18nKeys.events.eventDetails.headers.advancedSettings.header)}
          </Header>
        </Grid>
      </Container>
      <Scoring target={event} />
      {/* <ChimeNotifications target={event} /> */}
      <Gamified target={event} />
      {/* Removing PublicEventSettings as part of https://sim.amazon.com/issues/JAM-13572 because it does not currently work.*/}
      {/* {user?.isSuperAdmin && <PublicEventSettings event={event} />} */}
      {/* {user?.isSuperAdmin && <SecretKeyDetails event={event} />} */}
      <SkillBuilderEventSettings target={event} />
      <TeamSettings target={event} validationHandler={noop} isAdvancedSettings />
      {/* {user?.isSuperAdmin && <PreEventLobbyVideo event={event} />} */}
      <Prizes event={event} />
      <ParticipantRegistration event={event} />
      <AccessControl target={event} />
      {user?.isSuperAdmin && <LabAvailabilty target={event} />}
      {/* {user?.isSuperAdmin && <Sponsorship event={event} />} */}
      {/* <EventCapacityRedirectSettings event={event} /> */}
    </SpaceBetween>
  );
};

export default EventDetailsAdvancedSettings;
