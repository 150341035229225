import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Campaign} from '../../../types/Campaign';
import {Event, EventStatus} from '../../../types/Event';
import './TargetTimeline.scss';
import {i18nKeys} from '@/src/utils/i18n.utils';
import {getTimelineFormattedDate} from '@/src/utils/time.utils';
import {SpaceBetween} from '@amzn/awsui-components-react';
import {ApprovalStatus} from '@/src/types/common';
import moment from 'moment';
import {Icon} from '@amzn/awsui-components-react/uxdg';

interface TargetTimelineProps {
  target?: Event | Campaign;
}

const getFormattedDate = (dateVal: number | string | null ) => {
  if (!dateVal) return ''

  const date = new Date(dateVal)
  return `${getTimelineFormattedDate(date)} (GMT${moment(date).format('Z')})`
}


const TargetTimeline: React.FC<TargetTimelineProps> = ({ target }) => {
  const { t } = useTranslation();
  const event = target as Event;

  const isEventStarted = (): boolean => {
    return (new Date(event?.startDate || '') < new Date()) && (event?.status !== EventStatus.NOT_STARTED);
  };

  const isEventEnd = (): boolean => {
    return new Date(event?.endDate || '') < new Date();
  };

  const circleWithDiv = (num: number): JSX.Element => <div className="circle-new">{num}</div>;

  const approvalIcon = useMemo(() => {
    switch(target?.approvalStatus) {
      case ApprovalStatus.REQUEST_SUBMITTED:
        return <Icon data-testid="submitted-icon" name="status-in-progress" size="medium" />;
      case ApprovalStatus.REQUEST_PENDING:
        return <Icon data-testid="pending-icon" name="status-pending" size="medium" />;
      case ApprovalStatus.REQUEST_CANCELLED:
      case ApprovalStatus.REQUEST_DENIED:
        return <Icon data-testid="x-icon" name="status-negative" size="medium" />;
      case ApprovalStatus.REQUEST_APPROVED:
        return <Icon data-testid="check-icon" name="status-positive" size="medium" variant="success" />;
    }
  }, [target?.approvalStatus]);

  return (
    <ol className="c-stepper">
      {[ApprovalStatus.REQUEST_SUBMITTED, ApprovalStatus.REQUEST_PENDING, ApprovalStatus.REQUEST_APPROVED].includes(target?.approvalStatus as ApprovalStatus) ? (
        <>
          <li className={`c-stepper__item_new ${target?.approvalStatus === ApprovalStatus.REQUEST_APPROVED ? 'active' : ''}`}>
            <div>
              <SpaceBetween direction="horizontal" size="xxs" alignItems="center">
                <span>{ approvalIcon }</span>
                <h3 className="c-stepper__title">{
                  [ApprovalStatus.REQUEST_PENDING, ApprovalStatus.REQUEST_SUBMITTED].includes(target?.approvalStatus as ApprovalStatus) ?
                    t(i18nKeys.events.eventDetails.labels.statuses.requestPending) :
                    t(i18nKeys.events.eventDetails.timeline.scheduled)
                }</h3>
              </SpaceBetween>
              <div className="c-stepper__subtitle">
                <div className="c-stepper__subtitle__text">{t(i18nKeys.events.eventDetails.timeline.createdOn)}</div>{' '}
                {getFormattedDate(event?.createdDate)}
              </div>
            </div>
          </li>
          <li className={`c-stepper__item_new ${isEventStarted() ? 'active' : ''}`}>
            <div>
              <SpaceBetween direction="horizontal" size="xxs" alignItems="center">
                <span>{isEventStarted() ? <Icon name="status-positive" size="medium" variant="success" /> : circleWithDiv(2)}</span>
                <h3 className="c-stepper__title">{t(i18nKeys.events.eventDetails.timeline.start)}</h3>
              </SpaceBetween>
              <div className="c-stepper__subtitle">
                <div className="c-stepper__subtitle__text">{t(i18nKeys.events.eventDetails.timeline.scheduledFor)}</div>
                {getFormattedDate(event?.startDate)}
              </div>
            </div>
          </li>
          <li className={`c-stepper__item_new ${isEventEnd() ? 'active' : ''}`}>
            <div>
              <SpaceBetween direction="horizontal" size="xxs" alignItems="center">
                <span>{isEventEnd() ? <Icon name="status-positive" size="medium" variant="success" /> : circleWithDiv(3)}</span>
                <h3 className="c-stepper__title">{t(i18nKeys.events.eventDetails.timeline.end)}</h3>
              </SpaceBetween>
              <div className="c-stepper__subtitle">
                {getFormattedDate(event?.endDate)}
              </div>
            </div>
          </li>
        </>
      ) : (
        <>
          <li className={`c-stepper__item`}>
            <div>
              <h3 className="c-stepper__title">{t(i18nKeys.events.eventDetails.timeline.created)}</h3>
              <div className="c-stepper__subtitle">
                <div className="c-stepper__subtitle__text">{t(i18nKeys.events.eventDetails.timeline.createdOn)}</div>
                  {getFormattedDate(event?.createdDate)}
              </div>
            </div>
          </li>
          <li className={`c-stepper__item_new cancelled`}>
            <div>
              <h3 className="c-stepper__title flex-item">
                {approvalIcon}
                <span className="pl-4">{target?.approvalStatus === ApprovalStatus.REQUEST_CANCELLED ? t(i18nKeys.general.cancelled) : t(i18nKeys.general.denied)}</span>
              </h3>
              <div className="c-stepper__subtitle">
                <div className="c-stepper__subtitle__text">{target?.approvalStatus === ApprovalStatus.REQUEST_CANCELLED ? t(i18nKeys.events.eventDetails.timeline.cancelledOn) : t(i18nKeys.events.eventDetails.timeline.deniedOn)}</div>
                  {getFormattedDate(event?.lastUpdatedDate)}
              </div>
            </div>
          </li>
        </>
      )}
    </ol>
  );
};
export default TargetTimeline;
