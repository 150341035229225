import moment from 'moment';
import { TFunction } from 'react-i18next';
import { EventLabSummary } from '../types/EventLabSummary';
import {
  LAB_STATUS_SORT_ORDER,
  Lab,
  LabAutoScalingDecision,
  LabAutoScalingDecisionMin,
  LabDashboardChartData,
  LabStatus,
} from '../types/LabModels';
import { fromPlainObject } from './mapper.utils';
import { ChartProperties, ChartType, DataSet, DataEntry } from './chart.utils';
import * as chartUtils from './chart.utils';
import { labProviderStatusColors, labStatusColors } from '../constants/lab-status-color';
import { capitalize, uniq } from 'lodash';
import { LAB_PROVIDER_LABELS, formatLabProviderLabel } from '../types/LabProvider';
import { i18nKeys } from '@/src/utils/i18n.utils';

const labProviderStatusSortOrder = {
  CREATED: 0,
  INITIALIZING: 1,
  DEPLOYING: 2,
  UNDEPLOYING: 3,
  UNDEPLOYED: 4,
  DEPLOYED: 5,
  READY: 6,
  TERMINATING: 7,
  TERMINATED: 8,
  FAILED: 9,
};

type labStatusSortOrderKey = keyof typeof LAB_STATUS_SORT_ORDER;
export type labStatusColorsKey = keyof typeof labStatusColors;
type labProviderStatusSortOrderKey = keyof typeof labProviderStatusSortOrder;
export type labProviderStatusColorsKey = keyof typeof labProviderStatusColors;

export class LabChartSeedData {
  eventLabSummary: EventLabSummary;
  labs: Lab[] = [];
  autoScalingDecisions: LabAutoScalingDecision[] = [];
  teamCreationTimes: number[] = [];
  challengeStartTimes: number[] = [];
  challengeCompletionTimes: number[] = [];
}

export const getAggregatedAutoScalingCharts = (
  t: TFunction,
  eventLabSummary: EventLabSummary,
  labDashboardChartData: LabDashboardChartData
): ChartProperties[] => {
  return makeAutoScalingCharts(
    t,
    'event-lab-summary',
    makeAggregatedLabChartSeedData(eventLabSummary, labDashboardChartData)
  );
};

export const makeChart = (
  id: string,
  type: ChartType,
  xLabel: string,
  yLabel: string,
  title: string,
  subtitle: string | null,
  eventLabSummary: EventLabSummary,
  dataSets: DataSet[]
): ChartProperties => {
  const timeRangeStart = moment(eventLabSummary.labStartTime).valueOf();
  const timeRangeEnd = moment(eventLabSummary.labEndTime).valueOf();

  let highestValue = 0;

  dataSets.forEach((dataSet) => {
    dataSet.entries.forEach((entry) => {
      if (entry.value > highestValue) {
        highestValue = entry.value;
      }
    });
  });

  // add 20% to the highest value
  const ySuggestedMax = Math.ceil(highestValue * 1.2);

  return chartUtils.makeChart(
    id,
    type,
    xLabel,
    yLabel,
    ySuggestedMax,
    title,
    subtitle,
    timeRangeStart,
    timeRangeEnd,
    dataSets,
    undefined,
    labDashboardXTickFormatter
  );
};

export const getNumLiveLabsDataSet = (t: TFunction, data: LabChartSeedData): DataSet => {
  const key = 'numLiveLabs';

  const deployedLabs = data.labs.filter((l) => l.wasEverAssignable);
  const terminatedLabs = deployedLabs.filter((l) => l.isInFinalStatus && l.terminatedTime);

  const allTimes = [
    ...deployedLabs.map((lab) => ({ type: 'deployed', time: lab.deployedTime })),
    ...terminatedLabs.map((lab) => ({ type: 'terminated', time: lab.terminatedTime })),
  ];

  // sort all times
  allTimes.sort((a, b) => {
    if (!a.time || !b.time) return 0;
    return a.time > b.time ? 1 : -1;
  });

  let liveLabsCount = 0;

  const entries: chartUtils.DataEntry[] = allTimes.map(({ time, type }, _) => {
    if (type === 'terminated') {
      liveLabsCount = Math.max(0, liveLabsCount - 1);
    } else {
      liveLabsCount += 1;
    }

    return Object.assign(new DataEntry(), {
      key,
      time,
      value: liveLabsCount,
    });
  });

  return chartUtils.makeDataSet({
    key,
    color: '#27a5ab',
    label: t(i18nKeys.eventLabs.metrics.infoSections.liveLabs),
    entries,
  });
};

export const getTeamsJoinedDataSet = (t: TFunction, data: LabChartSeedData): DataSet => {
  const key = 'teamsJoined';
  const timeRangeStart = moment(data.eventLabSummary.labStartTime).valueOf();
  // const timeRangeEnd = moment(data.eventLabSummary.labEndTime).valueOf();

  // filter team creation to only those created during the lab availability window
  // this prevents the auto-created Facilitator team from showing up on the chart at the time the event was created
  const teamCreationTimes = (data.teamCreationTimes || []).filter((time) => {
    return time > timeRangeStart;
  });

  return chartUtils.makeDataSet({
    key,
    color: 'blue',
    label: t(i18nKeys.eventLabs.metrics.infoSections.teamsJoined),
    entries: chartUtils.mapTimesToEntries(key, teamCreationTimes),
  });
};

export const getTeamsStartedDataSet = (t: TFunction, data: LabChartSeedData): DataSet => {
  const key = 'teamsStarted';
  return chartUtils.makeDataSet({
    key,
    color: 'green',
    label: t(i18nKeys.eventLabs.metrics.infoSections.teamsStarted),
    entries: chartUtils.mapTimesToEntries(key, data.challengeStartTimes),
  });
};

export const getTeamsInProgressDataSet = (t: TFunction, data: LabChartSeedData): DataSet => {
  const key = 'teamsInProgress';

  const labEndTime = moment(data.eventLabSummary.labEndTime).valueOf();

  const allTimes = [
    ...(data.challengeStartTimes || []).map((time) => ({ type: 'started', time })),
    ...(data.challengeCompletionTimes || []).map((time) => ({ type: 'completed', time })),
  ];

  // sort all times
  allTimes.sort((a, b) => (a.time > b.time ? 1 : -1));

  let inProgressCount = 0;

  const entries: DataEntry[] = allTimes.map(({ time, type }, _) => {
    if (type === 'completed') {
      inProgressCount = Math.max(0, inProgressCount - 1);
    } else {
      inProgressCount += 1;
    }

    return Object.assign(new DataEntry(), {
      key,
      time,
      // after the lab availability window ends, override with 0 teams in progress because the labs are no longer available
      value: time > labEndTime ? 0 : inProgressCount,
    });
  });

  return chartUtils.makeDataSet({
    key,
    color: '#ff1dac',
    label: t(i18nKeys.eventLabs.metrics.infoSections.teamsInProgress),
    entries,
  });
};

export const getLabsByStatusDataSet = (t: TFunction, data: LabChartSeedData): DataSet => {
  const key = 'labsByStatus';

  const labsByStatus = EventLabSummary.getAggregatedLabStatusCounts(data.eventLabSummary);

  const entries = Object.entries(labsByStatus)
    .filter(([_, count]) => count > 0)
    .sort(
      ([a], [b]) =>
        LAB_STATUS_SORT_ORDER[a as labStatusSortOrderKey] - LAB_STATUS_SORT_ORDER[b as labStatusSortOrderKey]
    );

  return chartUtils.makeDataSet({
    key,
    color: entries.map(([status]) => labStatusColors[status as labStatusColorsKey]),
    fill: true,
    entries: entries
      .sort(
        ([a], [b]) =>
          LAB_STATUS_SORT_ORDER[a as labStatusSortOrderKey] - LAB_STATUS_SORT_ORDER[b as labStatusSortOrderKey]
      )
      .map(([status, count]) =>
        Object.assign(new DataEntry(), {
          key,
          label: localizeLabStatus(t, status),
          value: count,
          status,
        })
      ),
  });
};

export const getLabsByExternalStatusDataSet = (t: TFunction, data: LabChartSeedData, labProvider: string): DataSet => {
  const key = 'labsByExternalStatus';

  const labsByStatus: { [status: string]: number } = (data.labs || [])
    .filter((lab) => lab.labProvider === labProvider)
    .reduce<{ [status: string]: number }>((mapByStatus, lab) => {
      const status = lab.extStatus;
      if (!status) {
        return mapByStatus;
      }
      if (mapByStatus[status]) {
        mapByStatus[status] += 1;
      } else {
        mapByStatus[status] = 1;
      }
      return mapByStatus;
    }, {});

  const entries = Object.entries(labsByStatus)
    .sort(
      ([a], [b]) =>
        labProviderStatusSortOrder[a as labProviderStatusSortOrderKey] -
        labProviderStatusSortOrder[b as labProviderStatusSortOrderKey]
    );

  return chartUtils.makeDataSet({
    key,
    color: entries.map(([status]) => labProviderStatusColors[status as labProviderStatusColorsKey]),
    fill: true,
    entries: entries
      .sort(
        ([a], [b]) =>
          labProviderStatusSortOrder[a as labProviderStatusSortOrderKey] -
          labProviderStatusSortOrder[b as labProviderStatusSortOrderKey]
      )
      .map(([status, count]) =>
        Object.assign(new DataEntry(), {
          key,
          label: localizeLabProviderStatus(t, status),
          value: count,
          status,
        })
      ),
  });
};

export const getLabsNeededDataSet = (t: TFunction, data: LabChartSeedData): DataSet => {
  const key = 'labsNeeded';
  return chartUtils.makeDataSet({
    key,
    color: 'red',
    label: t(i18nKeys.eventLabs.metrics.infoSections.labsNeeded),
    entries: data.autoScalingDecisions.map((decision) =>
      Object.assign(new DataEntry(), {
        key,
        time: decision.time,
        value: decision.count,
      })
    ),
  });
};

export const makeAutoScalingCharts = (t: TFunction, id: string, data: LabChartSeedData): ChartProperties[] => {
  const charts = [
    makeChart(
      id,
      'value-over-time-line',
      t(i18nKeys.eventLabs.metrics.infoSections.time),
      t(i18nKeys.eventLabs.metrics.infoSections.count),
      t(i18nKeys.eventLabs.metrics.infoSections.numLiveLabsAndTeamsInProgress),
      t(i18nKeys.eventLabs.metrics.infoSections.numLiveLabsAndTeamsInProgressSubtitle),
      data.eventLabSummary,
      [getNumLiveLabsDataSet(t, data), getTeamsInProgressDataSet(t, data)]
    ),
  ];

  if (data.autoScalingDecisions.length > 0) {
    charts.push(
      makeChart(
        id,
        'value-over-time-area',
        t(i18nKeys.eventLabs.metrics.infoSections.time),
        t(i18nKeys.eventLabs.metrics.infoSections.count),
        t(i18nKeys.eventLabs.metrics.infoSections.autoScaling),
        t(i18nKeys.eventLabs.metrics.infoSections.autoScalingSubtitle),
        data.eventLabSummary,
        [getLabsNeededDataSet(t, data), getTeamsJoinedDataSet(t, data), getTeamsStartedDataSet(t, data)]
      )
    );
  }

  return charts;
};

const reduceMapToList = <T>(map: { [key: string]: T[] }): T[] => {
  return Object.keys(map || {})
    .map((key) => map[key] || [])
    .reduce((all, items) => [...all, ...items], []);
};

const fillInMissingDecisions = (autoScalingDecisions: { [challengeId: string]: LabAutoScalingDecisionMin[] }) => {
  const roundTime = (decision: LabAutoScalingDecisionMin) => {
    // round up the time to an even increment, so we can group them
    const groupMergeRange = 3 * 60_000; // 3 minutes
    decision.time = Math.ceil(moment(decision.time).valueOf() / groupMergeRange) * groupMergeRange;
    return decision;
  };

  // track all times in which decisions were made
  const times: { [time: number]: any } = {};
  const decisionsByChallengeAndTime: { [key: string]: LabAutoScalingDecisionMin } = {};

  // 1. round the times for each auto-scaling decision
  // 2. track the time in the map of times
  // 3. index the decisions by challengeId+time
  Object.entries(autoScalingDecisions).forEach(([challengeId, decisions]) => {
    autoScalingDecisions[challengeId] = decisions.map((decision) => {
      decision = roundTime(decision);
      if (decision.time) {
        times[decision.time] = 1;
        decisionsByChallengeAndTime[`${challengeId}${decision.time}`] = decision;
      }
      return decision;
    });
  });

  // now iterate the challenge decisions again, and fill in any missing entries that were omitted due to being duplicates
  Object.entries(autoScalingDecisions).forEach(([challengeId, _decisions]) => {
    // track the previous time that we looked at
    let previousTime: number | null = null;

    // iterate all of the times on the timeline, and map to the decision for this challenge at that time.
    // if no decision exists for a particular time, then use the count from the previous time.
    // if there is no decision for the previous time, then use 0.
    autoScalingDecisions[challengeId] = Object.keys(times)
      .sort()
      .map((t) => {
        const targetTime = parseInt(t, 10);

        // check if we have a decision indexed for this time
        let decision = decisionsByChallengeAndTime[`${challengeId}${targetTime}`];

        // if no decision was found for this time, then try to find the count from the previous time
        if (!decision) {
          if (previousTime) {
            const previousDecision = decisionsByChallengeAndTime[`${challengeId}${previousTime}`];
            if (previousDecision) {
              decision = fromPlainObject(
                { time: targetTime, count: previousDecision.count },
                LabAutoScalingDecisionMin
              ) as LabAutoScalingDecisionMin;
            }
          }
        }

        // if we didn't find a decision for this target time, then create one now.
        if (!decision) {
          decision = fromPlainObject(
            { time: targetTime, count: 0 },
            LabAutoScalingDecisionMin
          ) as LabAutoScalingDecisionMin;
        }

        // index the decision by challengeId+time, for the case where we just created this decision
        decisionsByChallengeAndTime[`${challengeId}${targetTime}`] = decision;

        // set the previous time for the next iteration
        previousTime = targetTime;

        return decision;
      });
  });
};

export const makeAggregatedLabChartSeedData = (
  eventLabSummary: EventLabSummary,
  labDashboardChartData: LabDashboardChartData
): LabChartSeedData => {
  fillInMissingDecisions(labDashboardChartData.labAutoScalingDecisions);

  const decisions = labDashboardChartData.labAutoScalingDecisions;

  // group autoScalingDecisions by time, then sum the counts
  const decisionsGroupedByTime: { [time: number]: (LabAutoScalingDecision | LabAutoScalingDecisionMin)[] } =
    reduceMapToList(decisions).reduce(
      (groupedByTime: { [time: number]: (LabAutoScalingDecision | LabAutoScalingDecisionMin)[] }, decision) => {
        if (!decision.time) return groupedByTime;
        const time = decision.time;
        if (groupedByTime[time]) {
          groupedByTime[time].push(decision);
        } else {
          groupedByTime[time] = [decision];
        }
        return groupedByTime;
      },
      {}
    );

  const mergedDecisions = Object.values(decisionsGroupedByTime)
    .map((items) => {
      const count = items.reduce((sum, d) => sum + d.count, 0);
      const time = items[0].time;
      return fromPlainObject({ count, time }, LabAutoScalingDecision) as LabAutoScalingDecision;
    })
    .sort((a, b) => {
      if (!a.time || !b.time) return 0;
      return a.time - b.time;
    });

  return Object.assign(new LabChartSeedData(), {
    eventLabSummary,
    labs: reduceMapToList(eventLabSummary.labs),
    autoScalingDecisions: mergedDecisions,
    teamCreationTimes: labDashboardChartData.teamCreationTimes || [],
    challengeStartTimes: reduceMapToList(labDashboardChartData.challengeStartTimes),
    challengeCompletionTimes: reduceMapToList(labDashboardChartData.challengeCompletionTimes),
  });
};

export const makeLabMetricCharts = (t: TFunction, id: string, data: LabChartSeedData): ChartProperties[] => {
  const allLabsByInternalStatusChart: ChartProperties = makeChart(
    id,
    'bar',
    t(i18nKeys.lab.table.titles.status),
    t(i18nKeys.eventLabs.metrics.infoSections.numLabAccounts),
    t(i18nKeys.eventLabs.metrics.infoSections.labsByStatus),
    null,
    data.eventLabSummary,
    [getLabsByStatusDataSet(t, data)]
  );

  // get a list of all lab providers used in this event
  const allLabProviders = uniq(
    (data.labs || [])
      // get the lab provider from every lab in this event
      .map((lab) => lab.labProvider)
      // filter out falsy values
      .filter((l) => !!l)
  );

  // make a chart for each lab provider used in this event
  const labExternalStatusCharts = allLabProviders.map((labProviderInc) => {
    const labProvider = labProviderInc || '';
    const labProviderLabel = LAB_PROVIDER_LABELS[labProvider] || formatLabProviderLabel(labProvider);
    return makeChart(
      id,
      'bar',
      t(i18nKeys.eventLabs.metrics.infoSections.providerStatus, {provider: labProviderLabel}),
      t(i18nKeys.eventLabs.metrics.infoSections.numLabAccounts),
      t(i18nKeys.eventLabs.metrics.infoSections.labsByProviderStatus, {provider: labProviderLabel}),
      null,
      data.eventLabSummary,
      [getLabsByExternalStatusDataSet(t, data, labProvider)]
    );
  });

  return [allLabsByInternalStatusChart, ...labExternalStatusCharts];
};

export const getAggregatedLabMetricCharts = (
  t: TFunction,
  eventLabSummary: EventLabSummary,
  labDashboardChartData: LabDashboardChartData
): ChartProperties[] => {
  return makeLabMetricCharts(t,'lab-dashboard', makeAggregatedLabChartSeedData(eventLabSummary, labDashboardChartData));
};

export const localizeLabStatus = (t: TFunction, status: string) => {
  if (!status) {
    return '--';
  }

  switch (LabStatus[status as keyof typeof LabStatus]) {
    case LabStatus.UNASSIGNED:
      return t(i18nKeys.eventLabs.challengeLab.progressMeter.unassigned);
    case LabStatus.ASSIGNED:
      return t(i18nKeys.eventLabs.challengeLab.progressMeter.assigned);
    case LabStatus.COMPLETED:
      return t(i18nKeys.eventLabs.challengeLab.progressMeter.completed);
    case LabStatus.INVALID:
      return t(i18nKeys.eventLabs.challengeLab.progressMeter.invalid);
    case LabStatus.INELIGIBLE:
      return t(i18nKeys.eventLabs.challengeLab.progressMeter.ineligible);
    case LabStatus.ON_HOLD:
      return t(i18nKeys.eventLabs.challengeLab.progressMeter.onHold);
    case LabStatus.NOT_READY:
      return t(i18nKeys.eventLabs.challengeLab.progressMeter.notReady);
    case LabStatus.PREPARING_RESOURCES:
      return t(i18nKeys.eventLabs.challengeLab.progressMeter.preparingResources);
    case LabStatus.RESTARTED:
      return t(i18nKeys.eventLabs.challengeLab.progressMeter.restarted);
    case LabStatus.TERMINATED:
      return t(i18nKeys.eventLabs.challengeLab.progressMeter.terminated);
    case LabStatus.TIMED_OUT:
      return t(i18nKeys.eventLabs.challengeLab.progressMeter.timedOut);
    default:
      // Render in English if we get an unexpected status
      return status.split('_').map(capitalize).join(' ');
  }
};

// Also referred to as "external lab status"
export const localizeLabProviderStatus = (t: TFunction, status: string) => {
  if (!status) {
    return '--';
  }

  // There is no enum for LabProvider status, but it should follow the values from src/constants/lab-status-color.ts:45
  switch (status) {
    case 'CREATED': return t(i18nKeys.eventLabs.labAccounts.table.status.created);
    case 'INITIALIZING': return t(i18nKeys.eventLabs.labAccounts.table.status.initializing);
    case 'DEPLOYING': return t(i18nKeys.eventLabs.labAccounts.table.status.deploying);
    case 'PROVISIONING': return t(i18nKeys.eventLabs.labAccounts.table.status.provisioning);
    case 'UNDEPLOYING': return t(i18nKeys.eventLabs.labAccounts.table.status.undeploying);
    case 'UNDEPLOYED': return t(i18nKeys.eventLabs.labAccounts.table.status.undeployed);
    case 'DEPLOYED': return t(i18nKeys.eventLabs.labAccounts.table.status.deployed);
    case 'READY': return t(i18nKeys.eventLabs.labAccounts.table.status.ready);
    case 'RUNNING': return t(i18nKeys.eventLabs.labAccounts.table.status.running);
    case 'TERMINATING': return t(i18nKeys.eventLabs.labAccounts.table.status.terminating);
    case 'TERMINATED': return t(i18nKeys.eventLabs.labAccounts.table.status.terminated);
    case 'EXPIRED': return t(i18nKeys.eventLabs.labAccounts.table.status.expired);
    case 'ENDED': return t(i18nKeys.eventLabs.labAccounts.table.status.ended);
    case 'FAILED': return t(i18nKeys.eventLabs.labAccounts.table.status.failed);
    case 'ERROR': return t(i18nKeys.eventLabs.labAccounts.table.status.error);
    default:
      // Render in English if we get an unexpected status
      return status.split('_').map(capitalize).join(' ');
  }
}

export const labDashboardXTickFormatter = (value: number | Date) => {
  const date = moment(value);
  if (date.hour() === 0 && date.minute() === 0) {
    return moment(value).format('MMM DD');
  }
  return moment(value).format('HH:mm');
}
