import React, { useEffect, useMemo, useRef, useState } from 'react';
import LabOverview from './LabOverview';
import LabTabs from './LabTabs';
import { Box, Container, SpaceBetween } from '@amzn/awsui-components-react';
import { useApi } from '@/src/store/api.context';
import { Event } from '../../../types/Event';
import { EventLabSummary } from '@/src/types/EventLabSummary';
import JamSpinner from '../../common/JamSpinner';
import moment from 'moment-timezone';
import { getAggregatedAutoScalingCharts, getAggregatedLabMetricCharts } from '@/src/utils/lab-dashboard-chart.utils';
import { ChartProperties } from '@/src/utils/chart.utils';
import { useUser } from '@/src/store/user.context';
import { fromPlainObject } from '@/src/utils/mapper.utils';
import AuditTrail from './AuditTrail';
import { ChallengeDescriptor } from '@/src/types/Challenge';
import './EventLabs.scss';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { LabDashboardChartData } from '@/src/types/LabModels';

interface EventLabsProps {
  event: Event;
}

const EventLabs: React.FC<EventLabsProps> = ({ event }) => {
  const { t } = useTranslation();
  const { eventsApi, labshutoffAPI } = useApi();
  const { user } = useUser();

  const [summary, setSummary] = useState<EventLabSummary | null>(null);
  const [autoRefreshInterval, setAutoRefreshInterval] = useState(15);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [auditTrail, setAuditTrail] = useState<string | undefined>();
  const [labDashboardChartData, setLabDashboardChartData] = useState<LabDashboardChartData>();
  const [autoScalingChartProperties, setAutoScalingChartProperties] = useState<ChartProperties[]>([]);
  const [labChartProperties, setLabChartProperties] = useState<ChartProperties[]>([]);
  const eventId = event.id || '';
  const lastUpdated = useRef(moment());
  const isTestEvent = useMemo(() => {
    return summary?.testClone || summary?.testEvent;
  }, [summary]);

  const updateLabShutoffStatus = async (response: any): Promise<void> => {
    // super admin authorization is needed for shutoff API calls
    if (user?.isSuperAdmin && response) {
      // update the event lab summary's shutoff status, including test events (which requires a testCloneEventName to be provided)
      const [eventBase] = await labshutoffAPI.populateEventChallengeShutoffs(
        [
          // @ts-expect-error ignore
          fromPlainObject(
            {
              name: response.eventName as string,
              challengeDescriptors: response.challengeDescriptors as ChallengeDescriptor[],
              testCloneEventName:
                event?.testCloneEventName || ((isTestEvent ? response.eventName : undefined) as boolean),
            },
            Event
          ),
        ],
        true
      );

      // update this lab summary's shutoff-related properties since `populateEventChallengeShutoffs`
      // modifies a temporary object in place
      response.shutoffStatus = eventBase.shutoffStatus;
      response.testEventShutoffStatus = eventBase.testEventShutoffStatus;
      response.challengeDescriptors = eventBase.challengeDescriptors;

      setSummary(response as EventLabSummary);
    }
  };

  const loadLabDashboardChartData = async (eventLabSummary: EventLabSummary, silent: boolean) => {
    try {
      const data = await eventsApi.getLabDashboardChartData(event.name, silent);
      const autoScalingProperties = getAggregatedAutoScalingCharts(t, eventLabSummary, data);
      const labProperties = getAggregatedLabMetricCharts(t, eventLabSummary, data);
      setLabDashboardChartData(data);
      setAutoScalingChartProperties(autoScalingProperties);
      setLabChartProperties(labProperties);
    } catch (e) {
      // error
    }
  };

  const loadEventLabSummary = async (silent: boolean) => {
    try {
      setLoading(!silent);
      setRefreshing(!silent);
      const response = await eventsApi.getEventLabSummary(event.name);
      if (!response) {
        setSummary(null);
        setLoading(false);
        setRefreshing(false);
        return;
      }

      response.labs = await eventsApi.getEventLabs(event.name, Object.keys(response?.labStatusSnapshots || {}), !silent);
      
      await updateLabShutoffStatus(response);

      lastUpdated.current = moment();
      setSummary(response);
      if (response) {
        void loadLabDashboardChartData(response, silent);
      }
    } catch (e) {
      // todo:
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const initialize = (silent = false) => {
    void loadEventLabSummary(silent);
  };

  useEffect(() => {
    void initialize();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      void initialize(true);
    }, autoRefreshInterval * 1000);
    return () => clearInterval(interval);
  }, [autoRefreshInterval]);

  if (loading) {
    return <JamSpinner />;
  }

  if (!summary) {
    return (
      <Container>
        <Box>{t(i18nKeys.eventLabs.messages.couldNotLoadLabs)}</Box>
      </Container>
    );
  }

  return (
    <div className="event-labs">
      <SpaceBetween size="m">
        <LabOverview
          summary={summary}
          autoRefreshInterval={autoRefreshInterval}
          onChangeAutoRefreshInterval={setAutoRefreshInterval}
          onRefresh={() => void initialize(false)}
          refreshing={refreshing}
          lastUpdated={lastUpdated.current}
          showAuditTrail={(showFor: string) => setAuditTrail(showFor)}
        />

        <LabTabs
          summary={summary}
          autoScalingChartProperties={autoScalingChartProperties}
          labChartProperties={labChartProperties}
          eventName={eventId}
          showAuditTrail={(showFor: string) => setAuditTrail(showFor)}
          labDashboardChartData={labDashboardChartData}
        />
        <AuditTrail auditFor={auditTrail} onClose={() => setAuditTrail(undefined)} eventName={event.name} />
      </SpaceBetween>
    </div>
  );
};

export default EventLabs;
