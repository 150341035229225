import * as React from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { SideNavigation } from '@amzn/awsui-components-react';
import { useActiveRouteDetails } from '../../../hooks/useActiveRouteDetails';
import { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation/interfaces';
import { i18nKeys } from '../../../utils/i18n.utils';
import {
  CHALLENGE_DETAILS_ROUTES,
  challengeRoute,
  EVENT_DETAILS_ROUTES,
  CAMPAIGN_DETAILS_ROUTES,
  eventRoute,
  reviewChallengesRoute,
  campaignRoute,
  CHALLENGE_SETS_ROUTES,
  challengeSetRoute,
  EVENT_CATALOG_TEMPLATE_DETAILS_ROUTES,
  eventTemplateDetailsRoute,
} from '../../../routes';
import { useSplitPanel } from '../../../store/split-panel.context';
import { useToolPanel } from '../../../store/tool-panel.context';
import { useEditEvent } from '../../../store/edit-event.context';
import { HybridConsolePrefix } from '../../../utils/hybrid-console/index';
import { config } from '../../../config/app-config';
import { NewBadge } from '../../common/NewBadge';
import { useChallenges } from '../../../store/challenge.context';
import { useEvents } from '../../../store/events.context';
import { RoutePath } from '../../../RoutePath';
import { useUser } from '../../../store/user.context';
import { Challenge, ChallengeUtils, ChallengeWrapper } from '../../../types/Challenge';
import { Nullable } from '../../../types/common';
import { useChallengeSet } from '../../../store/challenge-set.context';
import { useCampaigns } from '../../../store/campaigns.context';
import { useEditCampaign } from '../../../store/edit-campaign.context';
import { useEditEventTemplate } from '@/src/store/edit-event-template.context';
import { EventTemplateStatusType } from '@/src/types/EventTemplate';
import {
  campaignSideNavRegex,
  challengeSideNavRegex,
  eventCatalogSideNavRegex,
  eventSideNavRegex,
} from '@/src/utils/myjam.utils';
import { EventPrivacyType } from '@/src/types/EventPrivacyType';
import { customEventTrigger } from '../../analytics/createEventTrigger';

interface RouteSet {
  path: string;
  keyName: string;
  set: (val: string) => void;
}

/**
 * Types that allow attaching custom "onClick" behavior to side navigation items.
 */
type FollowDetailWithClick = SideNavigationProps.FollowDetail & { onClick?: () => void };
type SideNavItemWithClick = SideNavigationProps.Item & { onClick?: () => void };

export const SideNav: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const history = useHistory();
  const location = useLocation();
  const { activeRoute } = useActiveRouteDetails();
  const [activeNav, setActiveNav] = useState('');
  const [eventName, setEventName] = useState('');
  const [campaignId, setCampaignId] = useState('');
  const [challengeId, setChallengeId] = useState('');
  const [challengeSetId, setChallengeSetId] = useState('');
  const [eventTemplateId, setEventTemplateId] = useState('');

  const { toggleShowSplitPanel } = useSplitPanel();
  const { toggleShowToolPanel } = useToolPanel();
  const { newEventMode } = useEditEvent();
  const { newCampaignMode } = useEditCampaign();
  const { event } = useEvents();
  const { campaign } = useCampaigns();
  const { challengeWrapperMap } = useChallenges();
  const { challengeSet } = useChallengeSet();
  const { newEventTemplateMode, eventTemplate } = useEditEventTemplate();
  const { fullChallenge } = useChallenges();

  // Add new route paths here to have them be matched
  const routePaths: RouteSet[] = [
    {
      path: eventRoute.path,
      keyName: 'eventName',
      set: (val: string) => {
        setEventName(val);
      },
    },
    {
      path: challengeRoute.path,
      keyName: 'challengeId',
      set: (val: string) => setChallengeId(val),
    },
    {
      path: challengeSetRoute.path,
      keyName: 'challengeSetId',
      set: (val: string) => setChallengeSetId(val),
    },
    {
      path: campaignRoute.path,
      keyName: 'campaignId',
      set: (val: string) => {
        setCampaignId(val);
      },
    },
    {
      path: eventTemplateDetailsRoute.path,
      keyName: 'eventTemplateId',
      set: (val: string) => {
        setEventTemplateId(val);
      },
    },
  ];

  const matchRoutePaths = () => {
    routePaths.forEach((route: RouteSet) => {
      // Setting type to any as the params are dynamic based on the param supplied and our strict typing expects params to be an empty object
      const path: string = route.path;
      const match: any = matchPath(location.pathname, {
        path,
        exact: false,
        strict: false,
      });

      route.set(match?.params[route.keyName] as string);
    });
  };

  // If the provided link is empty, do not redirect pages
  const onFollow = (e: CustomEvent<FollowDetailWithClick>) => {
    const href = e.detail.href;
    customEventTrigger(
      'linkclick',
      'Side Navigation',
      `${window.location.host}${href}`,
      'Side Navigation Event Click',
      { linkclicked: window.location.href }
    );
    if (href.indexOf(HybridConsolePrefix.Legacy) !== -1) {
      return;
    }
    toggleShowSplitPanel(false);
    toggleShowToolPanel(false);
    e.preventDefault();

    if (e.detail.onClick) {
      e.detail.onClick();
    } else if (href) {
      history.push(href);
    }
  };

  React.useEffect(() => {
    if (location.hash) {
      if (newEventMode) setActiveNav(location.hash);
      if (newCampaignMode) setActiveNav(location.hash);
    } else {
      setActiveNav(activeRoute);
    }

    matchRoutePaths();
  }, [
    activeRoute,
    location.hash,
    eventName,
    campaignId,
    challengeId,
    challengeSetId,
    eventTemplateId,
    location.pathname,
  ]);

  const showChallengeRoute = (route: string) => {
    const challengeWrapper: ChallengeWrapper | undefined = challengeWrapperMap[challengeId];
    const challenge: Nullable<Challenge> = challengeWrapper?.latest;

    if (challenge) {
      switch (route) {
        case CHALLENGE_DETAILS_ROUTES.LearningOutcome.resolve(challengeId):
        case CHALLENGE_DETAILS_ROUTES.Settings.resolve(challengeId):
        case CHALLENGE_DETAILS_ROUTES.NextSteps.resolve(challengeId):
        case CHALLENGE_DETAILS_ROUTES.Collaborators.resolve(challengeId):
        case CHALLENGE_DETAILS_ROUTES.Issues.resolve(challengeId):
        case CHALLENGE_DETAILS_ROUTES.Stats.resolve(challengeId):
          return !user?.isOnlyBasicUser;
        case CHALLENGE_DETAILS_ROUTES.Assets.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user);
        case CHALLENGE_DETAILS_ROUTES.IamPolicy.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user) || challenge.isDemo;
        case CHALLENGE_DETAILS_ROUTES.CfnTemplate.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user) || challenge.isDemo;
        case CHALLENGE_DETAILS_ROUTES.Wiki.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user) || challenge.isDemo;
        case CHALLENGE_DETAILS_ROUTES.Comments.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user);
        case CHALLENGE_DETAILS_ROUTES.Testing.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user);
        case CHALLENGE_DETAILS_ROUTES.Feedback.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user);
        case CHALLENGE_DETAILS_ROUTES.Revisions.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user);
        case CHALLENGE_DETAILS_ROUTES.Translation.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user) && challenge.majorVersion && challenge.majorVersion > 0;
        case CHALLENGE_DETAILS_ROUTES.FacilitatorNotes.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user);
        case CHALLENGE_DETAILS_ROUTES.Help.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user);
        default:
          return true;
      }
    }
    return false;
  };

  const unresolvedChallengeIssues = fullChallenge?.globalStatistics.unresolvedChallengeIssues ?? 0;
  const allChallengeDetailRoutes: SideNavigationProps.Item[] = [
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.summary),
      href: CHALLENGE_DETAILS_ROUTES.Summary.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.learningPlan),
      href: CHALLENGE_DETAILS_ROUTES.LearningOutcome.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.settings),
      href: CHALLENGE_DETAILS_ROUTES.Settings.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.tasks),
      href: CHALLENGE_DETAILS_ROUTES.Tasks.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.assetsResources),
      href: CHALLENGE_DETAILS_ROUTES.Assets.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.iamPolicy),
      href: CHALLENGE_DETAILS_ROUTES.IamPolicy.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.cfnTemplate),
      href: CHALLENGE_DETAILS_ROUTES.CfnTemplate.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.nextSteps),
      href: CHALLENGE_DETAILS_ROUTES.NextSteps.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.wiki),
      href: CHALLENGE_DETAILS_ROUTES.Wiki.resolve(challengeId),
    },
    {
      type: 'divider',
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.collaborators),
      href: CHALLENGE_DETAILS_ROUTES.Collaborators.resolve(challengeId),
    },
    {
      type: 'link',
      text: `${t(i18nKeys.challenges.subNavigation.issues)}${unresolvedChallengeIssues > 0 ? ` (${String(unresolvedChallengeIssues)})` : ""}`,
      href: CHALLENGE_DETAILS_ROUTES.Issues.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.comments),
      href: CHALLENGE_DETAILS_ROUTES.Comments.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.testing),
      href: CHALLENGE_DETAILS_ROUTES.Testing.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.feedback),
      href: CHALLENGE_DETAILS_ROUTES.Feedback.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.stats),
      href: CHALLENGE_DETAILS_ROUTES.Stats.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.revisions),
      href: CHALLENGE_DETAILS_ROUTES.Revisions.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.translation),
      href: CHALLENGE_DETAILS_ROUTES.Translation.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.facilitatorNotes),
      href: CHALLENGE_DETAILS_ROUTES.FacilitatorNotes.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.help),
      href: CHALLENGE_DETAILS_ROUTES.Help.resolve(challengeId),
    },
  ];

  const allEventDetailRoutes: SideNavigationProps.Item[] = [
    {
      type: 'link',
      text: t(i18nKeys.events.subNavigation.summary),
      href: EVENT_DETAILS_ROUTES.Summary.resolve(eventName),
    },
    {
      type: 'link',
      text: t(i18nKeys.events.subNavigation.challenges),
      href: EVENT_DETAILS_ROUTES.Challenges.resolve(eventName),
    },
    ...((!user?.isOnlyBasicUser) || event?.isEventOwner(user)
      ? ([
          {
            type: 'link',
            text: t(i18nKeys.events.subNavigation.advancedSettings),
            href: EVENT_DETAILS_ROUTES.Settings.resolve(eventName),
          },
        ] as SideNavigationProps.Item[])
      : []),
    {
      type: 'link',
      text: t(i18nKeys.events.subNavigation.comments),
      href: EVENT_DETAILS_ROUTES.Comments.resolve(eventName),
    },
    {
      type: 'link',
      text:
        event?.eventPrivacyType === EventPrivacyType.PRIVATE_INVITE
          ? t(i18nKeys.events.subNavigation.participants)
          : t(i18nKeys.events.subNavigation.participantsV1),
      href: EVENT_DETAILS_ROUTES.Participants.resolve(eventName),
    },
    {
      type: 'link',
      text: t(i18nKeys.events.subNavigation.ownersAndPermissions),
      href: EVENT_DETAILS_ROUTES.OwnerAndPermission.resolve(eventName),
    },
    ...((!user?.isOnlyBasicUser) || event?.isEventOwner(user)
      ? ([
          {
            type: 'link',
            text: t(i18nKeys.events.subNavigation.labs),
            href: EVENT_DETAILS_ROUTES.Labs.resolve(eventName),
          },
        ] as SideNavigationProps.Item[])
      : []),
    {
      type: 'link',
      text: t(i18nKeys.events.subNavigation.feedback),
      href: EVENT_DETAILS_ROUTES.Feedback.resolve(eventName),
    },
    ...((!user?.isOnlyBasicUser) || event?.isEventOwner(user)
      ? ([
          {
            type: 'link',
            text: t(i18nKeys.events.subNavigation.revisions),
            href: EVENT_DETAILS_ROUTES.Revisions.resolve(eventName),
          },
        ] as SideNavigationProps.Item[])
      : []),
    {
      type: 'link',
      text: t(i18nKeys.events.subNavigation.report),
      href: EVENT_DETAILS_ROUTES.Report.resolve(eventName),
    },
  ];

  const allGeneralEventDetailRoutes: SideNavigationProps.Item[] = [
    {
      type: 'link',
      text: t(i18nKeys.events.subNavigation.summary),
      href: EVENT_DETAILS_ROUTES.Summary.resolve(eventName),
    },
    {
      type: 'link',
      text: t(i18nKeys.events.subNavigation.challenges),
      href: EVENT_DETAILS_ROUTES.Challenges.resolve(eventName),
    },
    {
      type: 'link',
      text: t(i18nKeys.events.subNavigation.participants),
      href: EVENT_DETAILS_ROUTES.Participants.resolve(eventName),
    },
    {
      type: 'link',
      text: t(i18nKeys.events.subNavigation.ownersAndPermissions),
      href: EVENT_DETAILS_ROUTES.OwnerAndPermission.resolve(eventName),
    },
    {
      type: 'link',
      text: t(i18nKeys.events.subNavigation.feedback),
      href: EVENT_DETAILS_ROUTES.Feedback.resolve(eventName),
    },
    ...((!user?.isOnlyBasicUser) || event?.isEventOwner(user)
      ? ([
          {
            type: 'link',
            text: t(i18nKeys.events.subNavigation.revisions),
            href: EVENT_DETAILS_ROUTES.Revisions.resolve(eventName),
          },
        ] as SideNavigationProps.Item[])
      : []),
    {
      type: 'link',
      text: t(i18nKeys.events.subNavigation.report),
      href: EVENT_DETAILS_ROUTES.Report.resolve(eventName),
    },
  ];

  const allCampaignDetailsRoutes: SideNavigationProps.Item[] = [
    {
      type: 'link',
      text: t(i18nKeys.campaigns.subNavigation.summary),
      href: CAMPAIGN_DETAILS_ROUTES.Summary.resolve(campaignId),
    },
    {
      type: 'link',
      text: t(i18nKeys.campaigns.subNavigation.challenges),
      href: CAMPAIGN_DETAILS_ROUTES.Challenges.resolve(campaignId),
    },
    {
      type: 'link',
      text: t(i18nKeys.campaigns.subNavigation.settings),
      href: CAMPAIGN_DETAILS_ROUTES.Settings.resolve(campaignId),
    },
    {
      type: 'link',
      text: t(i18nKeys.campaigns.subNavigation.advancedSettings),
      href: CAMPAIGN_DETAILS_ROUTES.AdvancedSettings.resolve(campaignId),
    },
    { type: 'divider' },
    {
      type: 'link',
      text: t(i18nKeys.campaigns.subNavigation.comments),
      href: CAMPAIGN_DETAILS_ROUTES.Comments.resolve(campaignId),
    },
  ];

  const allEventTemplateDetailRoutes: SideNavigationProps.Item[] = [
    {
      type: 'link',
      text: t(i18nKeys.eventTemplates.subNavigation.details),
      href: EVENT_CATALOG_TEMPLATE_DETAILS_ROUTES.Details.resolve(eventTemplateId),
    },
    {
      type: 'link',
      text: t(i18nKeys.eventTemplates.subNavigation.challenges),
      href: EVENT_CATALOG_TEMPLATE_DETAILS_ROUTES.Challenges.resolve(eventTemplateId),
    },
    // pages is not developed so for now commenting it
    // {
    //   type: 'link',
    //   text: t(i18nKeys.eventTemplates.subNavigation.feedback),
    //   href: EVENT_CATALOG_TEMPLATE_DETAILS_ROUTES.Feedback.resolve(eventTemplateId),
    // },
    // {
    //   type: 'link',
    //   text: t(i18nKeys.eventTemplates.subNavigation.reporting),
    //   href: EVENT_CATALOG_TEMPLATE_DETAILS_ROUTES.Reporting.resolve(eventTemplateId),
    // },
  ];

  const campaignGroupItem: SideNavigationProps.Item = {
    type: 'link',
    text: t(i18nKeys.campaigns.subNavigation.groups),
    href: CAMPAIGN_DETAILS_ROUTES.Groups.resolve(campaignId),
  };

  if (campaign?.wasEverApproved) {
    allCampaignDetailsRoutes.splice(5, 0, campaignGroupItem);
  }

  // Get event title when on an event detail page
  const eventTitle: string = event?.title || 'Untitled Event';
  const campaignTitle: string = campaign?.title || 'Untitled Campaign';
  const eventTemplateTitle: string = eventTemplate?.name || 'Untitled Event Template';

  // get challenge title when on a challenge detail page
  const challengeTitle: string = challengeWrapperMap[challengeId]?.latest?.props.title || 'Untitled Challenge';

  // get challenge set title when on a challenge set detail page
  const challengeSetTitle: string = challengeSet?.title || 'Untitled Challenge Set';

  // filter through all challenge routes for only link routes allowed to the user
  const challengeDetailRoutes = allChallengeDetailRoutes.filter((item: SideNavigationProps.Item) => {
    if (item.type === 'link') {
      return showChallengeRoute(item.href);
    }
    return true;
  });

  const showLegacyConsoleMenu = useMemo(() => {
    return (
      (config.useHybridExperience && !user?.isOnlyBasicUser && location.pathname.includes(RoutePath.EVENTS)) ||
      location.pathname.includes('/events/') ||
      location.pathname.includes('/admin')
    );
  }, [user, location.pathname]);

  const legacyConsoleLink = useMemo(() => {
    const urlSplit = location.pathname.split('/');
    const url = `${HybridConsolePrefix.Legacy}/events/${urlSplit?.[2] ?? ''}`;
    return url;
  }, [location.pathname]);

  const eventCatalogMenu = useMemo(() => {
    if (!config.isEventTemplateEnabled || !user?.isSuperAdmin) {
      return [];
    }
    return [
      {
        type: 'link-group',
        text: t(i18nKeys.header.nav.admin.listECT.text),
        href: RoutePath.EVENT_CATALOG_TEMPLATES,
        items:
          !newEventTemplateMode && eventTemplateId != null && eventTemplate?.status !== EventTemplateStatusType.DRAFT
            ? [
                {
                  type: 'link-group',
                  text: eventTemplateTitle,
                  href: EVENT_CATALOG_TEMPLATE_DETAILS_ROUTES.Details.resolve(eventTemplateId),
                  items: allEventTemplateDetailRoutes,
                },
              ]
            : [],
      },
    ] as SideNavItemWithClick[];
  }, [
    config.isEventTemplateEnabled,
    user?.isSuperAdmin,
    newEventTemplateMode,
    eventTemplateId,
    allEventTemplateDetailRoutes,
    eventTemplate,
    eventTemplateTitle,
  ]);

  const eventMenu = useMemo(() => {
    return [
      {
        type: 'link-group',
        text: t(i18nKeys.events.title),
        href: RoutePath.EVENTS,
        items:
          !newEventMode && eventName != null
            ? [
                {
                  type: 'link-group',
                  text: eventTitle.length > 17 ? `${eventTitle.slice(0, 17)}...` : eventTitle,
                  href: EVENT_DETAILS_ROUTES.Summary.resolve(eventName),
                  items:
                    (user && event?.isEventOwner(user)) || user?.isSuperAdmin
                      ? allEventDetailRoutes
                      : allGeneralEventDetailRoutes,
                },
              ]
            : [],
      },
    ] as SideNavItemWithClick[];
  }, [newEventMode, eventName, allGeneralEventDetailRoutes, allEventDetailRoutes, eventTitle]);

  const campaignMenu = useMemo(() => {
    return [
      {
        type: 'link-group',
        text: t(i18nKeys.campaigns.title),
        href: RoutePath.CAMPAIGNS,
        items:
          !newCampaignMode && campaignId != null
            ? [
                {
                  type: 'link-group',
                  text: campaignTitle,
                  href: CAMPAIGN_DETAILS_ROUTES.Summary.resolve(campaignId),
                  items: allCampaignDetailsRoutes,
                },
              ]
            : [],
      },
    ] as SideNavItemWithClick[];
  }, [newCampaignMode, campaignId, campaignTitle, allCampaignDetailsRoutes]);

  const challengeMenu = useMemo(() => {
    const items = challengeWrapperMap[challengeId] ? [
      {
        type: 'link-group',
        text: challengeTitle,
        href: CHALLENGE_DETAILS_ROUTES.Summary.resolve(challengeId),
        items: challengeDetailRoutes,
      },
    ] : [
      {
        type: 'link-group',
        text: t(i18nKeys.challenges.challengeSets),
        href: RoutePath.CHALLENGE_SETS,
        items:
          challengeSetId != null
            ? [
              {
                type: 'link',
                text: challengeSetTitle,
                href: CHALLENGE_SETS_ROUTES.Summary.resolve(challengeSetId),
              },
            ]
            : [],
      },
    ];

    items.push({
      type: 'link-group',
      text: t(i18nKeys.header.nav.admin.challengeLibary.text),
      href: RoutePath.CHALLENGES,
      items: [],
    });

    // Only add the snippets and game boards links when on the challenge/challenge set list pages.
    // TODO - update these to the V2 links once those pages are ready
    if (!(challengeSetId || challengeId)) {
      items.push(
        {
          type: 'link-group',
          text: t(i18nKeys.challenges.challengeSnippets),
          href: RoutePath.CHALLENGES_SNIPPETS_V1,
          items: [],
        },
        {
          type: 'link-group',
          text: t(i18nKeys.challenges.gameBoards),
          href: RoutePath.GAME_BOARDS_V1,
          items: [],
        }
      );
    }

    return [
      {
        type: 'link-group',
        text: t(i18nKeys.challenges.title),
        href: RoutePath.CHALLENGES,
        items,
      },
    ] as SideNavItemWithClick[];
  }, [challengeWrapperMap, challengeId, challengeTitle, challengeSetId, challengeSetTitle, challengeDetailRoutes]);

  const labMenu = [{ type: 'link', text: t(i18nKeys.labs.title), href: RoutePath.LABS }] as SideNavItemWithClick[];

  const reportsMenu = [
    { type: 'link', text: t(i18nKeys.reports.title), href: HybridConsolePrefix.Legacy + RoutePath.REPORTS },
  ] as SideNavItemWithClick[];

  const prizesMenu = [
    { type: 'link', text: t(i18nKeys.header.nav.admin.prizeRedemption.text), href: RoutePath.PRIZES },
  ] as SideNavItemWithClick[];

  const settingsMenu = [
    { type: 'link', text: t(i18nKeys.header.nav.admin.settings.advancedSettings), href: RoutePath.SETTINGS },
  ] as SideNavItemWithClick[];

  const readyForReviewMenu = useMemo(() => {
    if (config.isChallengeReviewEnabled && (user?.isBarRaiser || user?.isChallengeAdmin || user?.isSuperAdmin)) {
      return [
        {
          type: 'expandable-link-group',
          text: t(i18nKeys.readyForReview.title),
          defaultExpanded: true,
          href: '#',
          items: [
            {
              type: 'link',
              text: t(i18nKeys.challenges.title),
              info: <NewBadge />,
              href: reviewChallengesRoute.path,
            },
          ],
        },
      ] as SideNavItemWithClick[];
    }
    return [];
  }, [config.isChallengeReviewEnabled, user, reviewChallengesRoute]);

  const usageMenu = useMemo(() => {
    if (!config.isUsagePlanEnabled) {
      return [];
    }
    return [
      { type: 'link', text: t(i18nKeys.usagePlan.title), info: <NewBadge />, href: RoutePath.USAGE_PLANS },
    ] as SideNavItemWithClick[];
  }, [config.isUsagePlanEnabled]);

  // eslint disable below useMemo hook is required because of the way in which we have defined and it's required.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const legacyMenu = useMemo(() => {
    if (!showLegacyConsoleMenu) {
      return [];
    }
    return [
      { type: 'divider' },
      {
        type: 'link',
        external: true,
        text: t(i18nKeys.hybrid.original),
        href: legacyConsoleLink,
      },
    ] as SideNavItemWithClick[];
  }, [showLegacyConsoleMenu, legacyConsoleLink]);

  // don't use useMemo here.. else you'll have to add all the dependancy from
  // all the above menus here. very long list and unnecessary
  const getMainMenu = () => {
    if (location.pathname === RoutePath.EVENTS || location.pathname === RoutePath.CAMPAIGNS) {
      return [
        ...eventMenu,
        ...(!user?.isOnlyBasicUser ? campaignMenu : []),
        // NOTE: disabled until list events on the labs page is fixed
        // ...labMenu,
        ...reportsMenu,
        ...prizesMenu,
        ...readyForReviewMenu,
        ...usageMenu,
      ];
    }
    // Condition added but right now this will not work as we have EventSideNav.tsx
    // getting used to render side menu for event details screen.
    // we need to move it here later
    if (eventSideNavRegex.test(location.pathname)) {
      return [...eventMenu];
    }
    // Condition added but right now this will not work as we have EventSideNav.tsx
    // getting used to render side menu for event details screen.
    // we need to move it here later
    if (campaignSideNavRegex.test(location.pathname)) {
      return [...campaignMenu];
    }

    if (eventCatalogSideNavRegex.test(location.pathname)) {
      return [...eventCatalogMenu];
    }

    if (challengeSideNavRegex.test(location.pathname)) {
      return [...challengeMenu];
    }

    if ([RoutePath.ADMIN, RoutePath.SETTINGS, RoutePath.LABS].includes(location.pathname as RoutePath)) {
      return [
        ...eventCatalogMenu,
        ...eventMenu,
        ...campaignMenu,
        ...challengeMenu,
        ...labMenu,
        ...reportsMenu,
        ...prizesMenu,
        ...settingsMenu,
        ...readyForReviewMenu,
        ...usageMenu,
      ];
    }

    return [];
  };

  const mainMenu = getMainMenu();

  if (!mainMenu.length) {
    return null;
  }

  return (
    <SideNavigation
      activeHref={activeNav}
      header={{
        href: RoutePath.ROOT,
        text: 'AWS Jam',
      }}
      items={mainMenu}
      onFollow={onFollow}
    />
  );
};
