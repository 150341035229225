import { Patterns } from '../constants/shared/patterns';
import { capitalize, upperCase } from 'lodash';

import { NullableString } from '../types/common';
import { safeArray } from './list.utils';

const NumbersPattern = /^\d+$/;

export const defaultIfEmpty = (value: string | null, defaultValue: string): string => {
  return !value ? defaultValue : value;
};

export const defaultBlank = (value: string | null): string => {
  return defaultIfEmpty(value, '--');
};

export const splitWithTail = (str: string, delimiter: string, limit: number) => {
  // dont allow limit of less than one
  limit = Math.max(1, limit);
  const parts = str.split(delimiter, limit);
  parts[parts.length - 1] = str
    .split(delimiter)
    .slice(limit - 1)
    .join(delimiter);
  return parts;
};

export const isEmpty = (value: string | null): boolean => {
  return value == null || value.length < 1;
};

export const containsOnlyNumbers = (value: string): boolean => {
  return NumbersPattern.test(value);
};

export const isNotEmpty = (value: string | null): boolean => {
  return !isEmpty(value);
};

export const safeString = (value: NullableString | undefined, defaultString = ''): string => {
  return value || defaultString;
};

export const htmlDecode = (input: string): string => {
  const e = document.createElement('textarea');
  e.innerHTML = input;
  return e.childNodes.length === 0 ? '' : safeString(e.childNodes[0].nodeValue);
};

export const replaceAll = (str: string, find: string, replace: string) => {
  return safeString(str).replace(new RegExp(escapeRegExp(find), 'g'), replace);
};

export const escapeRegExp = (str: string) => {
  return safeString(str).replace(/([.*+?^=!:${}()|\]\\])/g, '\\$1');
};

/**
 * decodes html entities
 *
 * @param str
 */
export const decodeForMarkdown = (str: string) => {
  [
    { find: '&#34;', replace: '"' },
    { find: '&#96;', replace: '`' },
    { find: '&#43;', replace: '+' },
    { find: '&#61;', replace: '=' },
    { find: '&#64;', replace: '@' },
    { find: '&#39;', replace: "'" },
    { find: '&amp;', replace: '&' },
    { find: '&gt;', replace: '>' },
    { find: '&lt;', replace: '<' },
  ].forEach(({ find, replace }) => {
    str = replaceAll(str, find, replace);
  });

  const hexCodeMatches = str.matchAll(/&#x([0-9a-fA-F]+);/g);

  [ ...hexCodeMatches ].forEach((match) => {
    if (match.length === 2) {
      const codePoint = parseInt(`0x${match[1]}`, 16);
      if (codePoint && !isNaN(codePoint)) {
        str = str.replace(match[0], String.fromCodePoint(codePoint));
      }
    }
  });

  // decode all HTML entities inside of code blocks
  const codeBlocks: string[] = str.match(/(```[\W\w\D\n\r\t]*```)/g) || [];
  codeBlocks.forEach((codeBlock: string) => {
    codeBlock = safeString(codeBlock);
    str = safeString(str).replace(codeBlock, htmlDecode(codeBlock));
  });

  return str;
};

export const isEmailValid = (email: string) => Patterns.EMAIL_REGEX.test(email);

export const isEmailListValid = (emails: string[]) => safeArray(emails).every(isEmailValid);

/**
 * Splits the hash and returns the path for use with existing react router hash
 *
 * @param hash
 * @returns path from hash
 */
export const getPathFromHash = (hash: string) => hash.split('/', 2)[1];

/**
 * Implements to Angular titleCase pipe for react usage
 *
 * @param value String value to convert to title casing
 */
export const toTitleCase = (value: NullableString | undefined) => {
  if (value) {
    value = value.replaceAll('_', ' ');
    value = value.toLocaleLowerCase();
    const words: string[] = value.split(' ');
    words.forEach((word, i) => {
      if (word === 'aws') {
        words[i] = upperCase(word);
      } else {
        words[i] = capitalize(word);
      }
    });
    value = words.join(' ');
  }
  return value;
};

/**
 * Take in a string and return an array, splitting the string on the possible delimiters that
 * could be used when pasting a list of emails.
 * Valid delimiters: , ; | space
 *
 * @param raw
 */
export const splitDelimitedEmails = (raw: string): string[] =>
  (raw || '')
    .replace('\n', ',')
    .replace('\t', '')
    .replace('\r', ',')
    .split(/\s*[,;\s|]+\s*/)
    .map((email: string): string => (email || '').trim().toLowerCase())
    .filter((email) => !!email);

/**
 * Validates domain provided and returns boolean depicting validity
 *
 * @param domainSuffix Domain to be validated
 * @returns boolean depicting validity of domain provided
 */
export const isValidDomainSuffix = (domainSuffix: string) => {
  if (!domainSuffix) {
    return false;
  }
  if (domainSuffix.includes('@')) {
    const numAtSigns = (domainSuffix.match(/@/g) || []).length;
    if (numAtSigns > 1) {
      return false;
    }
    return domainSuffix.indexOf('@') === 0;
  }
  return true;
};

/**
 * Parses a brace-delimited string and returns the array of strings.
 * For example, the input "{string1}, {string2}, {string3}" will return the array ['string1', 'string2', 'string3'].
 *
 * @param value The brace-delimited string to parse
 */
export const parseBraceDelimitedString = (value: NullableString | undefined): string[] | null => {
  // This regexp will find all entries in a string formatted as "{entry1}, {entry2}, {entry3}, ..." to convert it to
  // a string array ['{entry1}', '{entry2}', '{entry3}', ...]
  const regex = /{(.*?)}/g;
  const array = safeString(value).match(regex);

  return array == null
    ? null
    : array.map((entry) => {
        // Remove leading and trailing brace
        return entry.substring(1, entry.length - 1);
      });
};

/**
 * Combines an array of strings into a single brace-delimited string.
 * For example, the input array ['string1', 'string2', 'string3'] will be converted into the single string
 * "{string1}, {string2}, {string3}".
 *
 * @param values The array of strings to combine.
 */
export const stringArrayToBraceDelimitedString = (values: string[]): string => {
  return `{${safeArray(values).join('}, {')}}`;
};

export const hashCode = (text: string): number => {
  if (!text) {
    return 0;
  }
  let hash = 0;
  let i;
  let chr;
  if (text.length === 0) {
    return hash;
  }
  for (i = 0; i < text.length; i++) {
    chr = text.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const truncateString = (str: string, startIndex = 0, endIndex = 20, postFix = '...'): string => {
  if (!str) return '';
  return str.substring(startIndex, endIndex) + (str.length > endIndex ? postFix : '');
};

export const generateRandomKey = () => {
  const chars = '123456789ABCDEFGHJKLMNPQRSTUVWXTZabcdefghikmnpqrstuvwxyz';
  const length = 6;
  let output = '';
  while (output.length < length) {
    const index = Math.floor(Math.random() * chars.length);
    output += chars.substring(index, index + 1);
  }
  return output;
};

export const stringifyIfObject: (val: any) => string| number| undefined = (val: any) => {
  const type = typeof val;
  if (["string", "number", "undefined"].includes(type)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return val;
  }
  return JSON.stringify(val);
};
