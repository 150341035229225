import {
  Alert,
  Box,
  Button,
  ExpandableSection,
  Grid,
  Header,
  Icon,
  Link,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../store/api.context';
import { useChallenges } from '../../../store/challenge.context';
import { EditCampaignActions, useEditCampaign } from '../../../store/edit-campaign.context';
import { EditEventActions, EventEdit, useEditEvent } from '../../../store/edit-event.context';
import { useSplitPanel } from '../../../store/split-panel.context';
import { useToolPanel } from '../../../store/tool-panel.context';
import { Campaign } from '../../../types/Campaign';
import { ChallengeDescriptor, ChallengeForImport, ChallengeListItem } from '../../../types/Challenge';
import { ChallengeSet } from '../../../types/ChallengeSet';
import { Nullable, NullableString } from '../../../types/common';
import { Event } from '../../../types/Event';
import { i18nKeys } from '../../../utils/i18n.utils';
import { preProdLogger } from '../../../utils/log.utils';
import DifficultyIndicator from '../../challenges/challengesCommon/difficultyIndiciator/DifficultyIndicator';
import BrowseChallengesInSplitPanel from '../Challenges/BrowseChallengesInSplitPanel';
import GeneralBackupChallenges from '../Challenges/GeneralBackupChallenges';
import { getChallengeForImport } from '../Challenges/ImportChallenges';
import PerChallengeBackupChallenges from '../Challenges/PerChallengeBackupChallenges';
import { ChallengeSetSelection } from '../ChallengeSetSelection';
import { LoadingBar } from '../LoadingBar';
import { TinyEventSelection } from '../TinyEventSelection';
import { ChallengeSetList, EventChallengeList } from '../../root/ToolsContent';
import { CHALLENGE_SETS_ROUTES, EVENT_DETAILS_ROUTES } from '../../../routes';
import { ChallengeTargetType } from '../CommonModel';

interface NewEventChallengesProps {
  target: Event | Campaign;
  validationHandler?: (validateSection: () => Promise<boolean>) => void;
}

const NewEventChallenges: React.FC<NewEventChallengesProps> = ({ target, validationHandler }) => {
  const { eventsApi } = useApi();
  const { t } = useTranslation();
  const {
    handleUpdateEditCampaign,
    newCampaignImportSources,
    setNewCampaignImportSources,
    bulkHandleUpdateEditCampaign,
  } = useEditCampaign();
  const { handleUpdateEditEvent, newEventImportSources, setNewEventImportSources, bulkHandleUpdateEditEvent } =
    useEditEvent();
  const [selectedEventListLoading, setSelectedEventListLoading] = useState(false);
  const { challengeSets, getChallengeListItemFromChallengeId, getChallengeDescriptor } = useChallenges();
  const {
    toggleShowToolPanel,
    toggleToolPanel,
    setToolPanelWidth,
    setHideAfterClose,
    toolPanelOpen,
    renderToolPanelContent,
    toggleChallengeInfo,
  } = useToolPanel();
  const {
    toggleShowSplitPanel,
    renderSplitPanelContent,
    toggleHideAfterClose,
    showSplitPanel,
    toggleSplitPanel,
    splitPanelOpen,
  } = useSplitPanel();
  const isEvent = target instanceof Event;
  const enableDefaultSectionExpansion = true;

  if (validationHandler) {
    validationHandler(() => {
      return Promise.resolve(true);
    });
  }

  const handleSelectedChallengeSet = (challengeSet: OptionDefinition) => {
    const foundChallengeSet: ChallengeSet | undefined = challengeSets?.find(
      (cs: ChallengeSet) => cs.id === challengeSet.value
    );
    if (foundChallengeSet) {
      if (isEvent) {
        setNewEventImportSources({ ...newEventImportSources, challengeSet: foundChallengeSet });
      } else {
        setNewCampaignImportSources({ ...newCampaignImportSources, challengeSet: foundChallengeSet });
      }
      const challengeImportItems = generateItemsToImport(foundChallengeSet)?.filter(
        (challengeItem: ChallengeForImport | undefined) => !challengeItem?.missing
      );
      const challengeDescriptors: ChallengeDescriptor[] = [];
      challengeImportItems?.map((challenge: ChallengeForImport | undefined) => {
        if (challenge) {
          const challengeItem = getChallengeListItemFromChallengeId(challenge.id);
          if (challengeItem) {
            const challengeDescriptor = getChallengeDescriptor(challengeItem);
            if (challengeDescriptor) {
              challengeDescriptors.push(challengeDescriptor);
            }
          }
        }
      });
      const allNewChallengeDescriptors = _.cloneDeep([
        ...target.challengeDescriptors,
        ...ChallengeDescriptor.filterNonUniqueChallengeDescriptorsFromOriginal(
          target.challengeDescriptors,
          challengeDescriptors
        ),
      ]);
      if (isEvent) {
        handleUpdateEditEvent(EditEventActions.CHALLENGE_DESCRIPTORS, allNewChallengeDescriptors);
      } else {
        handleUpdateEditCampaign(EditCampaignActions.CHALLENGE_DESCRIPTORS, allNewChallengeDescriptors);
      }
      if (showSplitPanel && challengeDescriptors) {
        updateSplitPanelSelection(allNewChallengeDescriptors, handleSelectChallenges);
      }
    }
  };

  const removeChallengeSet = () => {
    const currentChallengeSet = isEvent
      ? newEventImportSources?.challengeSet?.challengeIds
      : newCampaignImportSources?.challengeSet?.challengeIds;
    removeChallengesFromPerChallengeBackups(currentChallengeSet || [], ChallengeTargetType.CHALLENGE_SET);
  };

  const removeSelectedEvent = () => {
    const currentChallengeSet = isEvent
      ? newEventImportSources?.event?.challengeDescriptors
      : newCampaignImportSources?.event?.challengeDescriptors;
    removeChallengesFromPerChallengeBackups(
      currentChallengeSet?.map((cd: ChallengeDescriptor) => cd.challengeId) || [],
      ChallengeTargetType.EVENT
    );
  };

  const removeChallengesFromPerChallengeBackups = (
    targetChallenges: NullableString[],
    targetType: ChallengeTargetType
  ) => {
    const newChallengeDescriptors: ChallengeDescriptor[] = target.challengeDescriptors;
    toggleToolPanel(false);
    const actions: EventEdit[] = [];
    targetChallenges.forEach((challengeId: NullableString) => {
      if (challengeId) {
        const foundChallengeDescriptorIndex = target.challengeDescriptors.findIndex(
          (challenge: ChallengeDescriptor) => challenge.challengeId === challengeId
        );
        if (foundChallengeDescriptorIndex > -1) {
          newChallengeDescriptors.splice(foundChallengeDescriptorIndex, 1);
          if (isEvent) {
            if (
              target.backupChallengeConfig?.perChallengeBackups &&
              target.backupChallengeConfig?.perChallengeBackups[challengeId]?.length > 0
            ) {
              target.backupChallengeConfig.removePerChallengeBackups(challengeId);
            }
          }
        }
      }
    });
    if (isEvent) {
      actions.push({
        action: EditEventActions.PER_CHALLENGE_BACKUP_CHALLENGES,
        payload: target.backupChallengeConfig?.perChallengeBackups,
      });
    }
    actions.push({ action: EditEventActions.CHALLENGE_DESCRIPTORS, payload: newChallengeDescriptors });
    const newImportSources = {
      ...(isEvent ? newEventImportSources : newCampaignImportSources),
      ...(targetType === ChallengeTargetType.EVENT && { event: null }),
      ...(targetType === ChallengeTargetType.CHALLENGE_SET && { challengeSet: null }),
    };
    if (isEvent) {
      bulkHandleUpdateEditEvent(actions);
      setNewEventImportSources(newImportSources);
    } else {
      bulkHandleUpdateEditCampaign(actions);
      setNewCampaignImportSources(newImportSources);
    }
    updateSplitPanelSelection(_.cloneDeep(newChallengeDescriptors), handleSelectChallenges);
  };

  const setupToolPanel = () => {
    toggleShowToolPanel(true);
    toggleToolPanel(true);
    setToolPanelWidth(400);
    setHideAfterClose(true);
  };

  const generateItemsToImport = (challengeSet?: ChallengeSet) => {
    const valueToMap = challengeSet
      ? challengeSet.challengeIds
      : (isEvent ? newEventImportSources : newCampaignImportSources).event?.challengeDescriptors.map(
          (cd: ChallengeDescriptor) => cd.challengeId
        );
    return valueToMap?.map((challengeId: NullableString) => {
      if (challengeId) {
        const challengeListItem: Nullable<ChallengeListItem> = getChallengeListItemFromChallengeId(challengeId);
        return getChallengeForImport(target, challengeListItem, challengeId, t);
      }
    });
  };

  const toggleChallengeSetInfo = () => {
    if (isEvent ? newEventImportSources.challengeSet : newCampaignImportSources.challengeSet) {
      if (!toolPanelOpen) {
        setupToolPanel();
      }
      const challengeSet = isEvent ? newEventImportSources.challengeSet : newCampaignImportSources.challengeSet;
      if (challengeSet) {
        renderToolPanelContent(
          challengeSet.title || '',
          <ChallengeSetList challengeSet={challengeSet} challenges={generateItemsToImport(challengeSet) || []} />
        );
      }
    }
  };

  const toggleEventListInfo = () => {
    if (isEvent ? newEventImportSources.event : newCampaignImportSources.event) {
      if (!toolPanelOpen) {
        setupToolPanel();
      }
      const eventSource = isEvent ? newEventImportSources.event : newCampaignImportSources.event;
      const eventTitle = eventSource?.title || '';
      if (eventSource) {
        renderToolPanelContent(
          eventTitle,
          <EventChallengeList
            eventId={eventSource.id || ''}
            eventTitle={eventTitle}
            challenges={generateItemsToImport() || []}
          />
        );
      }
    }
  };

  const handleSelectedEvent = (eventSelection: OptionDefinition) => {
    if (!_.isEmpty(eventSelection?.value)) {
      setSelectedEventListLoading(true);
      let newChallengeDescriptors: ChallengeDescriptor[] = [];
      const getEvent = async () => {
        await eventsApi.getEvent(eventSelection?.value as string).then((res) => {
          if (isEvent) {
            setNewEventImportSources({ ...newEventImportSources, event: res.event });
          } else {
            setNewCampaignImportSources({ ...newCampaignImportSources, event: res.event });
          }
          newChallengeDescriptors = [
            ...target.challengeDescriptors,
            ...ChallengeDescriptor.filterNonUniqueChallengeDescriptorsFromOriginal(
              target.challengeDescriptors,
              res.event.challengeDescriptors
            ),
          ];
          if (isEvent) {
            handleUpdateEditEvent(EditEventActions.CHALLENGE_DESCRIPTORS, newChallengeDescriptors);
          } else {
            handleUpdateEditCampaign(EditCampaignActions.CHALLENGE_DESCRIPTORS, newChallengeDescriptors);
          }
          setSelectedEventListLoading(false);
          if (showSplitPanel) {
            updateSplitPanelSelection(newChallengeDescriptors, handleSelectChallenges);
          }
        });
      };
      getEvent().catch((err) => preProdLogger(err));
    } else {
      if (isEvent) {
        setNewEventImportSources({ ...newEventImportSources, event: null });
      } else {
        setNewCampaignImportSources({ ...newEventImportSources, event: null });
      }
    }
  };

  const handleSelectChallenges = (challenges: ChallengeDescriptor[]) => {
    if (isEvent) {
      handleUpdateEditEvent(EditEventActions.CHALLENGE_DESCRIPTORS, challenges);
    } else {
      handleUpdateEditCampaign(EditCampaignActions.CHALLENGE_DESCRIPTORS, challenges);
    }
  };

  const toggleBrowseChallenges = () => {
    const challengesToExclude = [];
    if (isEvent && target.backupChallengeConfig?.allBackups) {
      challengesToExclude.push(...target.backupChallengeConfig?.allBackups);
    }
    toggleShowSplitPanel(true);
    toggleHideAfterClose(true);
    toggleSplitPanel();
    updateSplitPanelSelection(target.challengeDescriptors, handleSelectChallenges, challengesToExclude);
    window.scrollTo(0, document.body.scrollHeight);
  };

  const removeSelectedChallenge = (challenge: ChallengeListItem) => {
    const challengeDescriptorIndex = target.challengeDescriptors.findIndex(
      (cd: ChallengeDescriptor) => cd.challengeId === challenge.challengeId
    );
    const newDescriptors: ChallengeDescriptor[] = target.challengeDescriptors;
    newDescriptors.splice(challengeDescriptorIndex, 1);
    if (isEvent && challenge.challengeId) {
      if (
        target.backupChallengeConfig?.perChallengeBackups &&
        target.backupChallengeConfig?.perChallengeBackups[challenge.challengeId]?.length > 0
      ) {
        target.backupChallengeConfig.removePerChallengeBackups(challenge.challengeId);
        handleUpdateEditEvent(EditEventActions.PER_CHALLENGE_BACKUP_CHALLENGES, target.backupChallengeConfig?.perChallengeBackups);
      }
    }
    if (isEvent) {
      handleUpdateEditEvent(EditEventActions.CHALLENGE_DESCRIPTORS, _.cloneDeep(newDescriptors));
    } else {
      handleUpdateEditCampaign(EditCampaignActions.CHALLENGE_DESCRIPTORS, _.cloneDeep(newDescriptors));
    }
    updateSplitPanelSelection(newDescriptors, handleSelectChallenges);
  };

  const updateSplitPanelSelection = (
    challenges: ChallengeDescriptor[],
    handleSelection: (selectedChallenges: ChallengeDescriptor[]) => void,
    excludedChallenges?: ChallengeDescriptor[]
  ) => {
    renderSplitPanelContent(
      t(i18nKeys.newEvent.splitPanel.header),
      <BrowseChallengesInSplitPanel
        handleChallengeAction={handleSelection}
        toggleChallengeInfo={toggleChallengeInfo}
        currentChallengeDescriptors={challenges}
        excludedChallenges={excludedChallenges}
        defaultQueryTokens={[{ propertyKey: "tags", value: "guardian-reviewed", operator: ":"}]}
      />
    );
  };

  const selectedImportRow = (selectedImport: ChallengeSet | Event, handleRemove: () => void) => {
    const challengeCount =
      selectedImport instanceof ChallengeSet
        ? selectedImport.challengeIds.length
        : selectedImport.challengeDescriptors.length;
    const toggleToUse = selectedImport instanceof ChallengeSet ? toggleChallengeSetInfo : toggleEventListInfo;
    const detailsURL =
      selectedImport instanceof ChallengeSet
        ? CHALLENGE_SETS_ROUTES.Summary.resolve(selectedImport.id || '')
        : EVENT_DETAILS_ROUTES.Challenges.resolve(selectedImport.name);
    return (
      <React.Fragment>
        <Grid gridDefinition={[{ colspan: 6 }, { colspan: 3 }, { colspan: 3 }]}>
          <div>
            <div>{selectedImport?.title}</div>
            <div className="secondary-text">{`${challengeCount} challenges`}</div>
          </div>
          <div style={{ lineHeight: '40px' }} onClick={toggleToUse}>
            <Link href={detailsURL} external>
              {t(i18nKeys.newEvent.labels.details)}
            </Link>
          </div>
          <div style={{ lineHeight: '40px' }}>
            <Button variant="link" onClick={() => handleRemove()}>
              <Icon className="mr-5" name="close" />
              {t(i18nKeys.newEvent.buttons.remove)}
            </Button>
          </div>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <ExpandableSection
        disableContentPaddings
        variant="container"
        defaultExpanded={enableDefaultSectionExpansion}
        data-testid="chooseOwnChallengesExpandable"
        header={
          <Header variant="h2" description={t(i18nKeys.newEvent.descriptions.iWantToChooseMyOwnChallenges)}>
            {t(i18nKeys.newEvent.headers.iWantToChooseMyOwnChallenges)}
          </Header>
        }>
        <ExpandableSection
          variant="container"
          defaultExpanded={enableDefaultSectionExpansion}
          data-testid="browseChallengeExpandable"
          header={
            <div className="mt-8">
              <strong>{t(i18nKeys.newEvent.headers.browseChallenges)}</strong>
            </div>
          }>
          <Alert statusIconAriaLabel="info" type="info">
            {t(i18nKeys.challenges.importChallenges.fields.challengeSets.warning)}
          </Alert>
          <Box margin={{ bottom: 'm', top: 'm' }}>
            <Header variant="h3">
              {t(i18nKeys.newEvent.headers.currentlySelectedChallenges, {
                selectedChallengeCount: target.challengeDescriptors.length,
              })}
            </Header>
          </Box>
          <Table
            items={target.challengeDescriptors.map((challenge: ChallengeDescriptor) => {
              if (challenge) {
                return getChallengeListItemFromChallengeId(challenge.challengeId || '');
              }
            })}
            columnDefinitions={[
              {
                id: 'title-and-difficulty',
                header: t(i18nKeys.newEvent.headers.titleAndDifficulty),
                cell: (item: ChallengeListItem) =>
                  item && (
                    <div>
                      <div onClick={() => toggleChallengeInfo(item)}>
                        <Link>{item?.props?.title || item?.challengeId || ''}</Link>
                      </div>
                      <DifficultyIndicator challenge={item} />
                    </div>
                  ),
              },
              {
                id: 'type',
                header: t(i18nKeys.newEvent.headers.type),
                cell: (item: ChallengeListItem) => item?.props?.jamType || 'None',
              },
              {
                id: 'category',
                header: t(i18nKeys.newEvent.headers.category),
                cell: (item: ChallengeListItem) => item?.props?.category || 'None',
              },
              {
                id: 'remove',
                header: undefined,
                cell: (item: ChallengeListItem) => (
                  <Button variant='icon' iconName='close' onClick={() => removeSelectedChallenge(item)} data-testid="removeChallengeButton" />
                ),
              },
            ]}
            footer={
              target.challengeDescriptors.length > 0 &&
              !splitPanelOpen && (
                <Button variant="primary" onClick={() => toggleBrowseChallenges()} data-testid="selectChallengesButton">
                  {t(i18nKeys.newEvent.buttons.selectMoreChallenges)}
                </Button>
              )
            }
            empty={
              <Box textAlign="center" color="inherit">
                <b>{t(i18nKeys.newEvent.messages.noChallengesSelected)}</b>
                <Box padding={{ bottom: 's' }} variant="p" color="inherit">
                  {t(i18nKeys.newEvent.messages.noChallengesToDisplay)}
                </Box>
                {!splitPanelOpen && (
                  <Button variant="primary" onClick={() => toggleBrowseChallenges()} data-testid="selectChallengesButton">
                    {t(i18nKeys.newEvent.buttons.selectChallenges)}
                  </Button>
                )}
              </Box>
            }
          />
        </ExpandableSection>
        {isEvent && (
          <ExpandableSection
            variant="container"
            header={
              <div className="mt-8">
                <strong>{t(i18nKeys.newEvent.headers.backupChallenges)}</strong>
              </div>
            } data-testid="backupChallengesExpandable">
            <SpaceBetween direction="vertical" size="s">
              <GeneralBackupChallenges target={target} />
              {target.challengeDescriptors.length > 0 && (
                <React.Fragment>
                  <Header variant="h3">{t(i18nKeys.challenges.backupChallenges.headers.choosePerChallengeBackups)}</Header>
                  <Table
                    items={target.challengeDescriptors.map((challenge: ChallengeDescriptor) => {
                      if (challenge) {
                        return getChallengeListItemFromChallengeId(challenge.challengeId || '');
                      }
                    })}
                    data-testid="perChallengeBackupTable"
                    columnDefinitions={[
                      {
                        id: 'title-and-difficulty',
                        header: t(i18nKeys.newEvent.headers.titleAndDifficulty),
                        cell: (item: ChallengeListItem) =>
                          item && (
                            <div>
                              <div onClick={() => toggleChallengeInfo(item)}>
                                <Link>{item?.props?.title || item?.challengeId || ''}</Link>
                              </div>
                              <DifficultyIndicator challenge={item} />
                              {item.challengeId && (
                                <ExpandableSection
                                  className="ml-negative-5"
                                  variant="default"
                                  header={t(i18nKeys.newEvent.headers.selectedBackupChallenges)}
                                  data-testid="perChallengeBackupsExpandable">
                                  <PerChallengeBackupChallenges target={target} challengeId={item.challengeId} />
                                </ExpandableSection>
                              )}
                            </div>
                          ),
                      },
                      {
                        id: 'type',
                        header: t(i18nKeys.newEvent.headers.type),
                        cell: (item: ChallengeListItem) => item?.props?.jamType || t(i18nKeys.general.none),
                      },
                      {
                        id: 'category',
                        header: t(i18nKeys.newEvent.headers.category),
                        cell: (item: ChallengeListItem) => item?.props?.category || t(i18nKeys.general.none),
                      },
                    ]}
                    empty={
                      <Box textAlign="center" color="inherit">
                        <b>{t(i18nKeys.newEvent.messages.noChallengesSelected)}</b>
                        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
                          {t(i18nKeys.newEvent.messages.noChallengesToDisplay)}
                        </Box>
                      </Box>
                    }
                  />
                </React.Fragment>
              )}
            </SpaceBetween>
          </ExpandableSection>
        )}
        <ExpandableSection
          variant="container"
          defaultExpanded={!!newEventImportSources.challengeSet}
          data-testid="challengeSetExpandable"
          header={
            <div className="mt-8">
              <strong>{t(i18nKeys.newEvent.headers.challengeSets)}</strong>
            </div>
          }>
          <SpaceBetween direction="vertical" size="s">
            <ChallengeSetSelection
              showTitle={false}
              handleSelectedChallengeSet={handleSelectedChallengeSet}
              clearOnSelection
            />
            {newEventImportSources.challengeSet &&
              selectedImportRow(newEventImportSources.challengeSet, removeChallengeSet)}
            {newCampaignImportSources.challengeSet &&
              selectedImportRow(newCampaignImportSources.challengeSet, removeChallengeSet)}
          </SpaceBetween>
        </ExpandableSection>
        <ExpandableSection
          variant="container"
          defaultExpanded={!!newEventImportSources.event}
          data-testid="previousEventChallengesExpandable"
          header={
            <div className="mt-8">
              <strong>{t(i18nKeys.newEvent.headers.previousEvents)}</strong>
            </div>
          }>
          <TinyEventSelection handleSelectedEvent={handleSelectedEvent} inline clearOnSelection />
          {newEventImportSources.event && selectedEventListLoading && <LoadingBar />}
          {newEventImportSources.event &&
            !selectedEventListLoading &&
            selectedImportRow(newEventImportSources.event, removeSelectedEvent)}
          {newCampaignImportSources.event &&
            !selectedEventListLoading &&
            selectedImportRow(newCampaignImportSources.event, removeSelectedEvent)}
          {newCampaignImportSources.event && selectedEventListLoading && <LoadingBar />}
        </ExpandableSection>
      </ExpandableSection>
    </React.Fragment>
  );
};

export default NewEventChallenges;
